import React from 'react';
import { Form, Input, Select, Button } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import Helpers from '../../utilities/helpers';
import { MOBILE } from '../../constants/regex';
import { mobilePrefix } from '../../constants/keys';

const { Option } = Select;

class AddCustomerForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { details } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values, details.id);
      }
    });
  };

  validateMobleNumer = (rule, value, cb) => {
    if (Helpers.compareRegex(value, MOBILE)) {
      cb();
    } else {
      cb('Phone number that you enter is invalid!');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { details, isEdit } = this.props;

    const prefixSelector = getFieldDecorator('mobile_prefix', {
      initialValue:
        isEdit && get(details, 'mobile_prefix')
          ? get(details, 'mobile_prefix')
          : '91'
    })(
      <Select style={{ width: 70 }}>
        {map(mobilePrefix, (val) => {
          return <Option value={val}>+{val}</Option>;
        })}
      </Select>
    );
    return (
      <Form
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 6 },
          xl: { span: 6 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 18 },
          xl: { span: 18 }
        }}
      >
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: isEdit ? get(details, 'name') : ''
          })(<Input placeholder="Customer" size="large" />)}
        </Form.Item>

        <Form.Item
          label="Phone Number"
          extra="This will be where you will get notification"
        >
          {getFieldDecorator('phone_number', {
            initialValue: isEdit ? get(details, 'phone_number') : '',
            rules: [
              {
                required: true,
                message: 'Please input your phone number!'
              },
              { validator: this.validateMobleNumer }
            ]
          })(
            <Input
              size="large"
              addonBefore={prefixSelector}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item>

        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: isEdit ? get(details, 'email') : '',
            rules: [
              { required: true, message: 'Please input your email!' },
              {
                type: 'email',
                message: 'The input is not a valid E-mail!'
              }
            ]
          })(<Input placeholder="Email" size="large" />)}
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'customer_Form' })(AddCustomerForm);
