import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';

import AddServiceableLocationForm from '../../components/Business/AddServiceableLocationForm';
import { UPDATE_SERVICEABLE_LOCATION } from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import {
  getLocation,
  updateServiceableLocation
} from '../../redux/actions/locationAction';

class ServiceableLocationScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      serviceableLocations: []
    };
  }

  UNSAFE_componentWillMount() {
    this.getLocationServiceableLocations();
  }

  getLocationServiceableLocations = () => {
    const { location } = this.props;
    this.loader(true);
    this.props
      .getLocation(get(location, 'id'))
      .then((result) => {
        this.setState({
          serviceableLocations: get(result, 'serviceable_zip_codes')
        });
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  loader = (loading) => this.setState({ loading });

  updatedata = (payload) => {
    this.loader(true);
    const { location } = this.props;

    this.props
      .updateServiceableLocation(get(location, 'id'), payload)
      .then((result) => {
        this.loader(false);
        this.setState({
          serviceableLocations: get(result, 'serviceable_zip_codes')
        });
        message.success(UPDATE_SERVICEABLE_LOCATION);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  render() {
    const { loading, serviceableLocations } = this.state;

    return (
      <Spin spinning={loading}>
        <div className="main-container">
          <PageHeader
            className="site-page-header-responsive title title-md mb--20"
            title={<h4 className="text-uppercase">Serviceable Locations</h4>}
          >
            <AddServiceableLocationForm
              serviceableLocations={serviceableLocations}
              updatedata={this.updatedata}
            />
          </PageHeader>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { getLocation, updateServiceableLocation })(
    ServiceableLocationScreen
  )
);
