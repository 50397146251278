import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getServiceHours = (location) => (dispatch, getState, { api }) => {
  const url = Helpers.getUrl(APIS.SERVICE_HOURS, {
    location
  });

  return api
    .get(url)
    .then((result) => {
      return Helpers.getTimeAndTypeSortedArray(result);
    })
    .catch((err) => {
      throw err;
    });
};

export const createServiceHour = (hourObj) => (dispatch, getState, { api }) => {
  return api
    .post(APIS.SERVICE_HOURS, hourObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteServiceHour = (hourId) => (dispatch, getState, { api }) => {
  const url = APIS.DELETE_SERVICE_HOURS.replace('{id}', hourId);

  return api
    .delete(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
