import React from 'react';
import { Form, Input, Radio, Row, Button } from 'antd';
import get from 'lodash/get';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import ChargesOnWeight from './DynamicForm/ChargesOnWeight';
import ChargeOnOrderAmount from './DynamicForm/ChargeOnOrderAmount';

class AddServiceCharges extends React.Component {
  constructor() {
    super();
    this.state = {
      chargeType: 'None',
      orderAmountCharges: [],
      weightCharges: [],
      flatCharges: null
    };
  }

  getPayload = (values) => {
    const { type, charges } = values;
    const { service } = this.props;

    switch (type) {
      case 'Flat':
        return {
          type,
          service,
          charges: { price: charges }
        };

      case 'Weight':
        return {
          type,
          service,
          charges: remove(charges, (o) => {
            return !isEmpty(o);
          })
        };

      case 'Amount':
        return {
          type,
          service,
          charges: remove(charges, (o) => {
            return !isEmpty(o);
          })
        };

      default:
        return { type, service, charges };
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const payload = this.getPayload(values);
        this.props.updateServiceCharge(payload);
        // this.setState({ initalUpdate: false });
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    const { charges } = nextProps;

    this.setState({
      chargeType: get(charges, 'type'),
      flatCharges:
        get(charges, 'type') === 'Flat' ? get(charges, 'charges') : null,
      weightCharges:
        get(charges, 'type') === 'Weight' ? get(charges, 'charges') : [],
      orderAmountCharges:
        get(charges, 'type') === 'Amount' ? get(charges, 'charges') : []
    });
  }

  onChangeRadio = (e) => {
    const chargeType = e.target.value;
    const { charges } = this.props;
    this.setState({
      chargeType,
      flatCharges: chargeType === 'Flat' ? get(charges, 'charges') : null,
      weightCharges:
        get(charges, 'type') === 'Weight' ? get(charges, 'charges') : [],
      orderAmountCharges:
        get(charges, 'type') === 'Amount' ? get(charges, 'charges') : []
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      chargeType,
      flatCharges,
      weightCharges,
      orderAmountCharges
    } = this.state;
    const { form } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row type="flex" justify="space-between">
          <Form.Item>
            {getFieldDecorator('type', {
              initialValue: chargeType
            })(
              <Radio.Group
                className="radio-group"
                value={chargeType}
                onChange={this.onChangeRadio}
              >
                <Radio className="radio-style" value="None">
                  Do not apply any charges
                </Radio>
                <Radio className="radio-style" value="Flat">
                  Apply flat charge
                </Radio>
                {form.getFieldValue('type') === 'Flat' && (
                  <Form.Item label="Flat Charge">
                    {getFieldDecorator('charges', {
                      initialValue: flatCharges ? get(flatCharges, 'price') : null,
                      rules: [
                        {
                          required: true,
                          message: 'Please input the Flat charge!'
                        }
                      ]
                    })(<Input type="number" placeholder="100" />)}
                  </Form.Item>
                )}
                <Radio className="radio-style" value="Weight">
                  Apply based on Weight
                </Radio>
                {form.getFieldValue('type') === 'Weight' && (
                  <ChargesOnWeight
                    charges={weightCharges}
                    form={this.props.form}
                    sign={this.props.sign}
                  />
                )}
                <Radio className="radio-style" value="Amount">
                  Apply based on order amount before tax
                </Radio>
                {form.getFieldValue('type') === 'Amount' && (
                  <ChargeOnOrderAmount
                    charges={orderAmountCharges}
                    form={this.props.form}
                    sign={this.props.sign}
                  />
                )}
              </Radio.Group>
            )}
          </Form.Item>
        </Row>
        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'service_charges_form' })(AddServiceCharges);
