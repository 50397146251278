import React from 'react';
import { Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import CategoryScreen from '../screens/Category/CategoryScreen';
import ItemScreen from '../screens/Item/ItemScreen';
import AddOnScreen from '../screens/Item/AddOnScreen';

export default () => {
  return (
    <>
      <Route path={ROUTES.CATEGORY} exact component={CategoryScreen} />
      <Route path={ROUTES.ITEMS} exact component={ItemScreen} />
      <Route path={ROUTES.ADD_ON} exact component={AddOnScreen} />
    </>
  );
};
