import React from 'react';
import {
  Form,
  DatePicker,
  TimePicker,
  Input,
  Button,
  Col,
  Row,
  Select
} from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import assign from 'lodash/assign';
import map from 'lodash/map';
import Helpers from '../../utilities/helpers';
import { MOBILE } from '../../constants/regex';
import { mobilePrefix } from '../../constants/keys';

const format = 'HH:mm';
const dateFormat = 'DD-MM-YYYY';

class AddReservationForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(
          assign({}, values, {
            time: moment(values.time).format('HH:mm:00'),
            status: 'Confirmed'
          })
        );
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { reservationDetails } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(
          assign({}, values, {
            time: moment(values.time).format('HH:mm:00')
          }),
          reservationDetails.id
        );
      }
    });
  };

  validateMobleNumer = (rule, value, cb) => {
    if (Helpers.compareRegex(value, MOBILE)) {
      cb();
    } else {
      cb('Phone number that you enter is invalid!');
    }
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { reservationDetails, isEdit, businessDetails } = this.props;

    const prefixSelector = getFieldDecorator('mobile_prefix', {
      initialValue:
        isEdit && get(businessDetails, 'mobile_prefix')
          ? get(businessDetails, 'mobile_prefix')
          : '91'
    })(
      <Select style={{ width: 70 }}>
        {map(mobilePrefix, (val, i) => {
          return (
            <Select.Option key={`mobile_prefix_${i}`} value={val}>
              +{val}
            </Select.Option>
          );
        })}
      </Select>
    );

    return (
      <Form
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        layout="vertical  "
      >
        <Row>
          <Col md={12} sm={24}>
            <Form.Item label="Date">
              {getFieldDecorator('date', {
                rules: [
                  { required: true, message: 'Please input reservation date!' }
                ],
                initialValue: isEdit
                  ? moment(get(reservationDetails, 'date'), 'YYYY-MM-DD')
                  : null
              })(
                <DatePicker
                  disabledDate={this.disabledDate}
                  format={dateFormat}
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col md={12} sm={24} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Time ">
              {getFieldDecorator('time', {
                initialValue: isEdit
                  ? moment(get(reservationDetails, 'time'), 'HH:mm:ss')
                  : null,
                rules: [
                  { required: true, message: 'Please input reservation time!' }
                ]
              })(
                <TimePicker
                  format={format}
                  size="large"
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Guest Name">
          {getFieldDecorator('customer_name', {
            rules: [{ required: true, message: 'Please input guest name' }],
            initialValue: isEdit ? get(reservationDetails, 'customer_name') : ''
          })(<Input placeholder="Guest Name" size="large" />)}
        </Form.Item>

        <Form.Item label="Party Size">
          {getFieldDecorator('people', {
            rules: [{ required: true, message: 'Please input party size.' }],
            initialValue: isEdit ? get(reservationDetails, 'people') : ''
          })(<Input type="number" placeholder="3" size="large" />)}
        </Form.Item>

        <Form.Item label="Guest Contact">
          {getFieldDecorator('customer_contact', {
            rules: [
              {
                required: true,
                message: 'Please input guest contact number!'
              },
              { validator: this.validateMobleNumer }
            ],
            initialValue: isEdit
              ? get(reservationDetails, 'customer_contact')
              : ''
          })(
            <Input
              addonBefore={prefixSelector}
              placeholder="phone number"
              size="large"
            />
          )}
        </Form.Item>

        <Form.Item label="Guest Email">
          {getFieldDecorator('customer_email', {
            rules: [
              { required: false, message: 'Please input customer email!' },
              {
                type: 'email',
                message: 'The input is not a valid E-mail!'
              }
            ],
            initialValue: isEdit
              ? get(reservationDetails, 'customer_email')
              : ''
          })(<Input placeholder="email address" size="large" />)}
        </Form.Item>

        <Form.Item label="Guest Instructions">
          {getFieldDecorator('note', {
            initialValue: isEdit ? get(reservationDetails, 'note') : ''
          })(<Input.TextArea placeholder="Guest instructions" size="large" />)}
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'add_reservation_form' })(
  AddReservationForm
);
