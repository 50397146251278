import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';

import { get } from 'lodash';
import { SERVICE_CHARGE_UPDATE_SUCCESS } from '../../constants/messages';
import AddServiceCharges from '../../components/ServiceCharges/AddServiceCharges';
import requireAuth from '../../hocs/requireAuth';
import {
  getServiceCharge,
  createUpdateServiceCharge
} from '../../redux/actions/serviceChargeAction';

class ChargesScreen extends Component {
  constructor() {
    super();
    this.state = {
      charges: null
    };
  }

  componentWillMount() {
    this.getServiceCharge();
  }

  loader = (loading) => this.setState({ loading });

  updateServiceCharge = (payload) => {
    const { location } = this.props;
    payload = { ...payload, location: get(location, 'id') };

    this.props
      .createUpdateServiceCharge(payload)
      .then(() => {
        message.success(SERVICE_CHARGE_UPDATE_SUCCESS);
        this.getServiceCharge();
      })
      .catch((error) => {});
  };

  getServiceCharge = () => {
    this.loader(true);
    const { location } = this.props;
    this.props
      .getServiceCharge(get(location, 'id'))
      .then((result) => {
        if (!result) {
          this.setState({ charges: null });
        } else {
          this.setState({ charges: result });
        }
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  render() {
    const { loading, charges } = this.state;
    const { sign } = this.props.location.currency;

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive"
              title={<h4 className="text-uppercase">Delivery Charges</h4>}
            >
              <AddServiceCharges
                sign={sign}
                charges={charges}
                service="Delivery"
                getServiceCharge={this.getServiceCharge}
                updateServiceCharge={this.updateServiceCharge}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getServiceCharge,
    createUpdateServiceCharge
  })(ChargesScreen)
);
