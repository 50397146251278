import React from 'react';
import {
  Spin,
  Form,
  Select,
  Input,
  Icon,
  Button,
  message,
  Modal,
  Checkbox
} from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import { getMediaUrl } from '../../redux/actions/mediaAction';
// import Helpers from '../../utilities/helpers';

const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AddCategoryForm extends React.Component {
  constructor() {
    super();
    this.state = {
      previewVisible: false,
      previewImage: '',
      loading: false,
      parent: null,
      suffixIcon: false,
      image: null
    };
  }

  loader = (loading) => this.setState({ loading });

  componentDidMount() {
    const { isEdit, details } = this.props;

    if (isEdit && get(details, 'parent.id')) {
      this.setState({ suffixIcon: true });
    }
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    return true;
  };

  getMediaUrl = (info) => {
    this.loader(true);

    this.props
      .getMediaUrl(info)
      .then((result) => {
        this.loader(false);

        // this.setState({
        //   imageUrl: get(result, 'imageUrl'),
        //   image: get(result, 'key')
        // });
        // return false;
      })
      .catch(() => {
        this.loader(false);
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.loader(true);
        const { image } = this.state;
        if (image) {
          values.image = image;
        }

        this.props.updatedata(values);
        this.loader(false);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { details } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.loader(true);
        const { image } = this.state;
        if (image) {
          values.image = image;
        }

        if (isUndefined(get(values, 'parent'))) {
          values.parent = null;
        }

        this.props.updatedata(values, details.id);
        this.loader(false);
      }
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  render() {
    const clearIcon = (
      <Icon type="close-circle" theme="twoTone" twoToneColor="red" />
    );

    const { getFieldDecorator } = this.props.form;

    const { details, isEdit, parentCategories } = this.props;
    const { previewVisible, previewImage, loading, suffixIcon } = this.state;

    return (
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          labelCol={{
            xs: { span: 24 },
            xl: { span: 24 },
            sm: { span: 24 }
          }}
          wrapperCol={{
            xs: { span: 24 },
            xl: { span: 24 },
            sm: { span: 24 }
          }}
          onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        >
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              initialValue: isEdit ? get(details, 'name') : '',
              rules: [
                {
                  required: true,
                  message: 'Please input Category name field!'
                }
              ]
            })(<Input placeholder="Category" size="large" />)}
          </Form.Item>
          {/* <Form.Item label="Image" extra="Recomended to upload 800X800 image">
            {getFieldDecorator('image', {
              initialValue: isEdit ? get(details, 'image') : ''
            })(
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={this.beforeUpload}
                showUploadList={false}
                handlePreview={this.handlePreview}
                customRequest={this.getMediaUrl}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            )}
                </Form.Item> */}
          {get(details, 'children', []).length > 0 ? null : (
            <Form.Item label="Parent">
              {getFieldDecorator('parent', {
                initialValue: isEdit ? get(details, 'parent.id') : false
              })(
                <Select
                  size="large"
                  placeholder="Please select"
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    if (!value) {
                      this.setState({ suffixIcon: false });
                    } else {
                      this.setState({ suffixIcon: true });
                    }
                  }}
                  clearIcon={suffixIcon ? clearIcon : null}
                  suffixIcon={suffixIcon ? clearIcon : null}
                  allowClear
                >
                  {map(parentCategories, (category) => {
                    return isEdit &&
                      get(category, 'id') === get(details, 'id') ? null : (
                      <Option key={get(category, 'id')} value={get(category, 'id')}>
                        {get(category, 'name')}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          )}
          <Form.Item>
            {getFieldDecorator('status', {
              initialValue: isEdit ? get(details, 'status') : false
            })(
              <Checkbox defaultChecked={isEdit ? get(details, 'status') : false}>
                This category is available for ordering{' '}
              </Checkbox>
            )}
          </Form.Item>

          <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit" className="btn-secondary">
              Save Category
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

const wrappedForm = Form.create({ name: 'category_Form' })(AddCategoryForm);

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default connect(mapStateToProps, { getMediaUrl })(wrappedForm);
