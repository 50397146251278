import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';

import * as ROUTES from '../constants/routes';

export default (ChildComponnent) => {
  class RequireAuth extends Component {
    render() {
      const { isLoggedIn } = this.props;

      if (!isLoggedIn) {
        return <Redirect to={ROUTES.LOGIN} />;
      }
      return <ChildComponnent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    isLoggedIn: get(state, 'auth.isLoggedIn')
  });

  return connect(mapStateToProps, {})(RequireAuth);
};
