import React from 'react';
import { Form, Button, Input, Switch } from 'antd';
import get from 'lodash/get';

class AddReservationSettingsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      showAcceptTableReservation: false
    };
  }

  componentDidMount() {
    const { serviceDetails } = this.props;

    this.setState({
      showAcceptTableReservation: get(serviceDetails, 'table_reservation_allowed')
    });
  }

  validateMorethanZero = (rule, value, cb) => {
    if (value > 0) {
      cb();
    } else {
      cb('Value should be greater than 0!');
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { serviceDetails } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!values.early_order_allowed) {
          values.early_order_max_days = 0;
          values.early_order_min_minutes = 0;
        }

        if (!values.table_reservation_allowed) {
          values.min_guests = 0;
          values.max_guests = 0;
          values.advanced_reservation_days = 0;
        }

        this.props.updatedata(values, serviceDetails.id);
      }
    });
  };

  toggleForm = (value, type) => {
    switch (type) {
      case 'early_order':
        this.setState({ showOrderPlacementEarly: value });
        break;
      case 'table_reservation':
        this.setState({ showAcceptTableReservation: value });
        break;
      default:
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { serviceDetails, isEdit } = this.props;

    const { showAcceptTableReservation } = this.state;

    return (
      <Form
        layout="vertical"
        labelCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
      >
        <Form.Item label="Do you want to accept Table Reservation requests?">
          {getFieldDecorator('table_reservation_allowed', {
            initialValue: isEdit
              ? get(serviceDetails, 'table_reservation_allowed')
              : false
          })(
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={
                isEdit ? get(serviceDetails, 'table_reservation_allowed') : false
              }
              onChange={(value) => {
                this.toggleForm(value, 'table_reservation');
              }}
            />
          )}
        </Form.Item>

        {showAcceptTableReservation && (
          <>
            <Form.Item label="Advanced Reservations days">
              {getFieldDecorator('advanced_reservation_days', {
                initialValue: isEdit
                  ? get(serviceDetails, 'advanced_reservation_days')
                  : false,
                rules: [
                  {
                    required: true,
                    message: 'Please input the field'
                  },
                  { validator: this.validateMorethanZero }
                ]
              })(
                <Input
                  addonAfter="days"
                  className="small-input"
                  type="number"
                  placeholder="0"
                />
              )}
            </Form.Item>

            <Form.Item label="Minimum Guests">
              {getFieldDecorator('min_guests', {
                initialValue: isEdit ? get(serviceDetails, 'min_guests') : false,
                rules: [
                  {
                    required: true,
                    message: 'Please input the minimum guest allowed'
                  },
                  { validator: this.validateMorethanZero }
                ]
              })(<Input className="small-input" type="number" placeholder="0" />)}
            </Form.Item>

            <Form.Item label="Maximum Guests">
              {getFieldDecorator('max_guests', {
                initialValue: isEdit ? get(serviceDetails, 'max_guests') : false,
                rules: [
                  {
                    required: true,
                    message: 'Please input the maximum guest allowed'
                  },
                  { validator: this.validateMorethanZero }
                ]
              })(<Input className="small-input" type="number" placeholder="0" />)}
            </Form.Item>
          </>
        )}

        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'service_type_Form' })(
  AddReservationSettingsForm
);
