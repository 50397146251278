import React, { Component } from 'react';
import { Menu } from 'antd';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import MenuItems from '../../constants/menu';

const { SubMenu } = Menu;

class SideBarMenu extends Component {
  constructor() {
    super();
    window.addEventListener('resize', this.setSideBarCollapesed);
    this.state = {
      menuItems: MenuItems,
      collapsed: window.innerWidth <= 500
    };
  }

  setSideBarCollapesed = () => {
    this.setState({ collapsed: window.innerWidth <= 500 });
  };

  toggleCollapsed = () => {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed
    });
  };

  getMenuTab = (menu, key) => {
    const hasSubMenu = get(menu, 'hasSubMenu');
    const subMenus = get(menu, 'subMenu');
    const name = get(menu, 'name');

    if (hasSubMenu) {
      return (
        <SubMenu
          key={`sub${key}`}
          title={
            <span>
              {get(menu, 'faIcon')}
              <span>{name}</span>
            </span>
          }
        >
          {subMenus.map((menuItem, j) => {
            return (
              <Menu.Item key={`${name + j}`}>
                <Link to={get(menuItem, 'route')}>
                  {get(menuItem, 'faIcon')}
                  <span>{get(menuItem, 'name')}</span>{' '}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={get(menu, 'route')}>
          {get(menu, 'faIcon')}
          <span>{get(menu, 'name')}</span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { collapsed, menuItems } = this.state;

    return (
      <Menu
        // defaultSelectedKeys={['1']}
        // defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        className="sidebar-menu secondary-shadow"
        inlineCollapsed={collapsed}
      >
        {menuItems.map((menuItem, i) => {
          return this.getMenuTab(menuItem, i);
        })}
      </Menu>
    );
  }
}

export default SideBarMenu;
