import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getAddons = (location, searchText) => (
  dispatch,
  getState,
  { api }
) => {
  const params = {
    location,
    _sort: 'index:ASC'
  };
  if (searchText) {
    params.name_contains = searchText;
  }
  const url = Helpers.getUrl(APIS.ADDON, params);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSingleAddon = (id) => (dispatch, getState, { api }) => {
  const url = APIS.SINGLE_ADDON.replace('{id}', id);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createAddon = (addonObj) => (dispatch, getState, { api }) => {
  return api
    .post(APIS.ADDON, addonObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateAddon = (id, addonObj) => (dispatch, getState, { api }) => {
  const url = APIS.UPDATE_ADDON.replace('{id}', id);

  return api
    .put(url, addonObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateAddonItem = (id, addOnItemObj) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.UPDATE_ADDON_ITEM.replace('{id}', id);

  return api
    .put(url, addOnItemObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const sortAddon = (addOnObj) => (dispatch, getState, { api }) => {
  return api
    .put(APIS.SORT_ADDON, addOnObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
