import React, { Component } from 'react';
import {
  Row,
  Modal,
  Spin,
  Col,
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Drawer,
  Icon,
  message,
  Select,
  Popover
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faUser,
  faEye,
  faFileExcel,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import copy from 'copy-to-clipboard';

import OrderDetails from '../../components/Order/OrderDetails';
import OrderLogs from '../../components/Order/OrderLogs';
import {
  SEND_FEEDBACK_SUCCESS,
  ORDER_BULK_UPDATE_SUCCESS
} from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { updateOrderStatusKeys } from '../../constants/keys';
import {
  getOrders,
  updateOrderStatus,
  getOrdersCount,
  sendOrderFeedback,
  orderBulkUpdate
} from '../../redux/actions/orderAction';

import {
  OrderStatus,
  DeliveryType,
  PaymentMode,
  PaymentStatus,
  IncompletePaymentMode
} from '../../components/Tags/AllTags';
import ExportOrder from '../../components/Order/ExportOrder';
import { FRONT_STORE_URL } from '../../constants/apiEndpoints';

const { Option } = Select;

class OrderScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isNoteDrawerVisible: false,
      note: null,
      searchText: null,
      searchStatus: [],
      paymentStatus: [],
      isModalVisible: false,
      loading: false,
      orderDetails: null,
      limit: 50,
      total: 0,
      current: 1,
      data: [],
      selectedRowKeys: [],
      updateStatus: null,
      exportModal: false
    };

    this.throttleHandleChange = debounce(this.throttleHandleChange.bind(this), 500);
  }

  componentDidMount() {
    this.getInitials();
  }

  getInitials = () => {
    this.getOrders();
    // this.getOrdersCount();
  };

  onSearch = (searchText) => {
    const { searchStatus, paymentStatus } = this.state;
    this.throttleHandleChange(searchText, searchStatus, paymentStatus);
    this.setState({ searchText });
  };

  throttleHandleChange(query = null, searchStatus, paymentStatus) {
    this.getOrders(1, query, searchStatus, paymentStatus);
  }

  getOrdersCount = () => {
    const { location } = this.props;
    this.props
      .getOrdersCount(get(location, 'id'))
      .then((total) => {
        this.setState({ total });
      })
      .catch(() => {});
  };

  loader = (loading) => this.setState({ loading });

  getOrders = (current = 1, query = null, searchStatus = [], paymentStatus = []) => {
    const { location } = this.props;
    const { limit } = this.state;
    this.loader(true);
    this.props
      .getOrders(
        get(location, 'id'),
        current,
        limit,
        query,
        searchStatus,
        paymentStatus
      )
      .then((result) => {
        this.setState({
          data: result.orders,
          total: result.count,
          current,
          searchText: query,
          isModalVisible: false,
          searchStatus,
          paymentStatus,
          selectedRowKeys: [],
          updateStatus: null
        });
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  updateOrderStatus = (orderId, orderStatus) => {
    const { current } = this.state;
    this.loader(true);

    this.props
      .updateOrderStatus(orderId, orderStatus)
      .then(() => {
        this.getOrders(current);
        this.setState({ isNoteDrawerVisible: false });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  sendOrderFeedback = (orderId) => {
    this.loader(true);
    this.props
      .sendOrderFeedback(orderId)
      .then(() => {
        message.success(SEND_FEEDBACK_SUCCESS);
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };
  toggleExportModal = () => {
    const { exportModal } = this.state;
    this.setState({
      exportModal: !exportModal
    });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      orderDetails: null
    });
  };

  viewOrderDetails = (orderDetails) => {
    this.setState({ isModalVisible: true, orderDetails });
  };

  viewOrderLogs = (orderDetails) => {
    this.setState({ isDrawerVisible: true, orderDetails });
  };

  showChangeOrderButton = (text, paidStatus) => {
    switch (text) {
      case 'Pending':
        return {
          isDeclineButton: true,
          isAcceptButton: true,
          isDeliveredButton: false,
          isReadyButton: false,
          isPaidButton: paidStatus === 'Unpaid'
        };
      case 'Preparing':
        return {
          isDeclineButton: true,
          isAcceptButton: false,
          isDeliveredButton: false,
          isReadyButton: true,
          isPaidButton: paidStatus === 'Unpaid'
        };
      case 'Ready':
        return {
          isDeclineButton: true,
          isAcceptButton: false,
          isDeliveredButton: true,
          isReadyButton: false,
          isPaidButton: paidStatus === 'Unpaid'
        };
      case 'Canceled':
        return {
          isDeclineButton: false,
          isAcceptButton: false,
          isDeliveredButton: false,
          isReadyButton: false,
          isPaidButton: paidStatus === 'Unpaid'
        };
      case 'Delivered':
        return {
          isDeclineButton: false,
          isAcceptButton: false,
          isDeliveredButton: false,
          isReadyButton: false,
          isPaidButton: paidStatus === 'Unpaid'
        };
      default:
        return {
          isDeclineButton: false,
          isAcceptButton: false,
          isDeliveredButton: false,
          isReadyButton: false,
          isPaidButton: false
        };
    }
  };

  onSelectRow = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  orderBulkUpdate = () => {
    this.loader(true);
    let { selectedRowKeys, updateStatus } = this.state;

    console.log({
      id: selectedRowKeys,
      status: updateStatus
    });
    this.props
      .orderBulkUpdate({
        id: selectedRowKeys,
        status: updateStatus
      })
      .then(() => {
        message.success(ORDER_BULK_UPDATE_SUCCESS);
        this.getOrders();
      })
      .catch(() => {
        this.loader(false);
      });
  };

  render() {
    const {
      isModalVisible,
      orderDetails,
      data,
      isDrawerVisible,
      limit,
      total,
      loading,
      searchText,
      searchStatus,
      paymentStatus,
      current,
      note,
      isNoteDrawerVisible,
      exportModal,
      selectedRowKeys,
      updateStatus
    } = this.state;

    const rowSelection = {
      onChange: this.onSelectRow,
      selectedRowKeys: selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled:
          get(record, 'status') === 'Incomplete' ||
          get(record, 'status') === 'Delivered'
            ? true
            : false
      })
    };

    const BusinessColumn = [
      {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        align: 'left',
        key: 'order_status',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (text) => <OrderStatus orderDetails={text} />
      },
      {
        title: 'Order',
        dataIndex: 'order_id',
        width: 100,
        key: 'order_id',
        sorter: (a, b) => a.order_id - b.order_id,
        render: (text, record) => {
          return `${text} - ${get(record, 'customer.name')}`;
        }
      },
      {
        title: 'Order Received',
        dataIndex: 'created_at',
        align: 'center',
        width: 150,
        key: 'created_at',
        sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
        render: (text) => {
          return (
            <Tooltip placement="top" title={moment(text).format('DD MMM hh:mm a')}>
              {moment(text).fromNow()}
            </Tooltip>
          );
        }
      },
      {
        title: 'Service Date',
        dataIndex: 'service_date',
        align: 'center',
        width: 150,
        key: 'service_date',
        sorter: (a, b) =>
          moment(a.service_date).unix() - moment(b.service_date).unix(),
        render: (text) => {
          return (
            <Tooltip placement="top" title={moment(text).format('DD MMM hh:mm a')}>
              {moment(text).fromNow()}
            </Tooltip>
          );
        }
      },
      {
        title: 'Service',
        dataIndex: 'service_type',
        width: 100,
        align: 'center',
        key: 'service_type',
        sorter: (a, b) => a.service_type.localeCompare(b.service_type),
        render: (text, orderDetails) => (
          <DeliveryType serviceType={text} orderDetails={orderDetails} />
        )
      },
      {
        title: 'Total',
        dataIndex: 'total_amount',
        align: 'center',
        key: 'order_total',
        sorter: (a, b) => a.total_amount - b.total_amount
      },

      {
        title: 'Weight (Kg)',

        align: 'center',
        key: 'order_weight',
        render: (record) => {
          return get(record, 'total_weight') ? get(record, 'total_weight') : 0;
        }
      },
      {
        title: 'Payment Mode',
        align: 'center',

        key: 'payment_mode',
        render: (record) => {
          if (
            get(record, 'status') === 'Incomplete' ||
            get(record, 'paid_status') !== 'Paid'
          ) {
            return (
              <IncompletePaymentMode
                isOnlinePayment={get(record, 'gateway_order_id')}
                status={get(record, 'status')}
              />
            );
          }
          return <PaymentMode isOnlinePayment={get(record, 'gateway_order_id')} />;
        }
      },
      {
        title: 'Payment Status',
        dataIndex: 'paid_status',
        align: 'center',
        key: 'paid_status',
        sorter: (a, b) => a.paid_status.localeCompare(b.paid_status),
        render: (text) => <PaymentStatus orderDetails={text} />
      },

      {
        title: '',
        width: 150,
        align: 'right',
        key: 'order_logs',
        render: (record) => {
          return (
            <>
              <Tooltip placement="top" title="Order Details">
                <FontAwesomeIcon
                  className="table-action-icon"
                  icon={faEye}
                  onClick={() => {
                    this.viewOrderDetails(record);
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Order Logs">
                <FontAwesomeIcon
                  className="table-action-icon"
                  icon={faUser}
                  onClick={() => {
                    this.viewOrderLogs(record);
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Order Notes">
                <FontAwesomeIcon
                  className="table-action-icon"
                  icon={faPen}
                  onClick={() => {
                    this.setState({
                      isNoteDrawerVisible: true,
                      orderDetails: record,
                      note: get(record, 'note')
                    });
                  }}
                />
              </Tooltip>

              {/*get(record, 'status') === 'Delivered' &&
                get(record, 'paid_status') === 'Paid' && (
                  <Popconfirm
                    title="Are you sure, you want to send feedback link"
                    onConfirm={() => {
                      this.sendOrderFeedback(get(record, 'order_id'));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip placement="top" title="Send feedback link">
                      <FontAwesomeIcon
                        className="table-action-icon"
                        icon={faComments}
                      />
                    </Tooltip>
                  </Popconfirm>
                  )*/}
            </>
          );
        }
      }
    ];

    return (
      <Spin spinning={loading}>
        <div className="main-container">
          <h4 className="text-uppercase">Orders</h4>
          <Row gutter={[8, 8]}>
            <Col span={3}>
              <Select
                disabled={!(selectedRowKeys.length > 0)}
                // value={updateStatus ? updateStatus : null}
                size="small"
                style={{ width: '100%' }}
                placeholder="Select Status"
                onChange={(updateStatus) => {
                  this.setState({ updateStatus });
                }}
              >
                {updateOrderStatusKeys.map((obj) => {
                  return (
                    <Option
                      value={get(obj, 'value')}
                      label={get(obj, 'label')}
                      key={get(obj, 'value')}
                    >
                      {get(obj, 'label')}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={4}>
              <Button
                style={{ width: '9rem', marginRight: 10 }}
                type="primary"
                disabled={updateStatus && selectedRowKeys.length > 0 ? false : true}
                onClick={() => {
                  this.orderBulkUpdate();
                }}
                size="small"
                className="btn-secondary btn-margin"
              >
                Change status
              </Button>
              <Popover
                title="All orders go through this cycle..."
                placement="bottomRight"
                content={
                  <ol>
                    <li>Pending</li>
                    <li>Accepted</li>
                    <li>Ready</li>
                    <li>Delivered</li>
                  </ol>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} color="#aaa" />
              </Popover>
            </Col>
            <Col span={4}>
              <Select
                mode="multiple"
                size="small"
                style={{ width: '100%' }}
                value={paymentStatus}
                onChange={(val) => {
                  this.getOrders(1, searchText, searchStatus, val);
                }}
                placeholder="Payment status"
              >
                {['Paid', 'Unpaid', 'Refunded'].map((status) => {
                  return (
                    <Option value={status} label={status} key={status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col xl={3}>
              <Select
                size="small"
                mode="multiple"
                value={searchStatus}
                style={{ width: '100%' }}
                onChange={(val) => {
                  this.getOrders(1, searchText, val, paymentStatus);
                }}
                placeholder="Order Status"
              >
                {[
                  'Pending',
                  'Preparing',
                  'Ready',
                  'Delivered',
                  'Canceled',
                  'Incomplete'
                ].map((status) => {
                  return (
                    <Option value={status} label={status} key={status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Col>

            <Col
              span={5}
              style={{
                textAlign: 'right',
                paddingRight: 10
              }}
            >
              <Input
                type="text"
                size="small"
                placeholder="Search by Order ID or Customer Name"
                value={searchText}
                style={{ width: '100%' }}
                suffix={
                  searchText ? (
                    <Icon
                      onClick={() => {
                        this.onSearch('');
                      }}
                      type="close-circle"
                      style={{ fontSize: 15 }}
                    />
                  ) : (
                    <Icon
                      onClick={() => {
                        this.onSearch('');
                      }}
                      type="search"
                      style={{ fontSize: 15 }}
                    />
                  )
                }
                onChange={(e) => {
                  this.onSearch(e.target.value);
                }}
              />
            </Col>
            <Col span={3}>
              <Button
                size="small"
                onClick={() => {
                  this.getOrders();
                }}
                className="btn-secondary btn-margin"
                style={{
                  width: '100%',
                  color: '#40a9ff',
                  borderColor: '#40a9ff'
                }}
              >
                <Icon
                  className="btn-icon"
                  type="redo"
                  style={{
                    color: '#40a9ff'
                  }}
                />{' '}
                Refresh
              </Button>
            </Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  this.toggleExportModal();
                }}
                size="small"
                className="btn-secondary btn-margin"
                style={{
                  width: '100%',
                  color: '#40a9ff',
                  borderColor: '#40a9ff'
                }}
              >
                <FontAwesomeIcon
                  className="btn-icon"
                  icon={faFileExcel}
                  style={{
                    color: '#40a9ff',
                    marginRight: '0.5rem'
                  }}
                />{' '}
                Export
              </Button>
            </Col>
          </Row>
          <Table
            scroll={{ x: 800 }}
            rowKey={(record) => {
              return get(record, 'id');
            }}
            rowSelection={rowSelection}
            size="small"
            columns={BusinessColumn}
            pagination={{
              defaultPageSize: limit,
              current,
              total,
              onChange: (val) => {
                this.getOrders(val, searchText, searchStatus, paymentStatus);
              }
            }}
            dataSource={data}
          />
        </div>

        <Drawer
          title={`Order Id : ${get(orderDetails, 'order_id')}`}
          width={window.innerWidth < 500 ? window.innerWidth * 0.7 : 300}
          destroyOnClose
          onClose={this.toggleDrawer}
          visible={isDrawerVisible}
        >
          <OrderLogs orderDetails={orderDetails} />
        </Drawer>

        <Drawer
          title={`Order Id : ${get(orderDetails, 'order_id')}`}
          width={window.innerWidth < 500 ? window.innerWidth * 0.7 : 300}
          destroyOnClose
          onClose={() => {
            this.setState({
              isNoteDrawerVisible: false,
              orderDetails: null
            });
          }}
          visible={isNoteDrawerVisible}
        >
          <Input.TextArea
            placeholder="Note"
            value={note}
            onChange={(e) => {
              this.setState({ note: e.target.value });
            }}
            style={{ minHeight: 200 }}
          />
          <Button
            style={{ marginTop: 20 }}
            type="primary"
            block
            onClick={() => {
              this.updateOrderStatus(get(orderDetails, 'id'), { note });
            }}
            className="btn-secondary"
          >
            Update note
          </Button>
        </Drawer>

        <Modal
          title={'Export Orders'}
          destroyOnClose
          onCancel={this.toggleExportModal}
          visible={exportModal}
          footer={<></>}
        >
          <ExportOrder toggleExportModal={this.toggleExportModal} />
        </Modal>

        <Modal
          title={this.renderModalFooter(orderDetails, true)}
          width={window.innerWidth * 0.6}
          destroyOnClose
          onCancel={() => {
            this.setState({ isModalVisible: false, orderDetails: null });
          }}
          visible={isModalVisible}
          footer={this.renderModalFooter(orderDetails)}
        >
          <OrderDetails
            showChangeOrderButton={this.showChangeOrderButton}
            updateOrderStatus={this.updateOrderStatus}
            orderDetails={orderDetails}
          />
        </Modal>
      </Spin>
    );
  }

  renderModalFooter = (orderDetails, label = false) => {
    const {
      isDeclineButton,
      isAcceptButton,
      isDeliveredButton,
      isReadyButton,
      isPaidButton
    } = this.showChangeOrderButton(
      get(orderDetails, 'status'),
      get(orderDetails, 'paid_status')
    );
    const { location } = this.props;
    const paidStatus = get(orderDetails, 'paid_status');
    const trackLink = `${FRONT_STORE_URL}/${get(
      location,
      'slug'
    )}/order-success/${get(orderDetails, 'uuid')}`;

    return (
      <Row type="flex" justify="start" align="center">
        <Col xs={24} xl={4}>
          {label && 'Order Details'}
        </Col>
        <Col xs={24} xl={6}>
          {label && get(orderDetails, 'status') !== 'Incomplete' && (
            <>
              <Button
                onClick={() => {
                  copy(trackLink);
                  message.success('Link Copied');
                }}
              >
                Copy Tracking link
              </Button>
            </>
          )}
        </Col>
        <Col xs={24} xl={12}>
          <Row type="flex" justify="end">
            {isAcceptButton && (
              <Popconfirm
                title="Are you sure, you want to accept this order"
                onConfirm={() => {
                  this.updateOrderStatus(get(orderDetails, 'id'), {
                    status: 'Preparing'
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    marginLeft: 5
                  }}
                  type="primary"
                >
                  Accept Order
                </Button>
              </Popconfirm>
            )}
            {isReadyButton && (
              <Popconfirm
                title="Are you sure, you want to change status to Ready"
                onConfirm={() => {
                  this.updateOrderStatus(get(orderDetails, 'id'), {
                    status: 'Ready'
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    marginLeft: 5
                  }}
                  type="primary"
                >
                  Mark as Ready
                </Button>
              </Popconfirm>
            )}

            {isDeliveredButton && (
              <Popconfirm
                title="Are you sure, you want to change status to Delivered"
                onConfirm={() => {
                  this.updateOrderStatus(get(orderDetails, 'id'), {
                    status: 'Delivered'
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    marginLeft: 5
                  }}
                  type="primary"
                >
                  Mark as Delivered
                </Button>
              </Popconfirm>
            )}

            {isPaidButton && (
              <Popconfirm
                title="Are you sure, you want to mark order as paid"
                onConfirm={() => {
                  this.updateOrderStatus(get(orderDetails, 'id'), {
                    paid_status: 'Paid'
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    marginLeft: 5
                  }}
                  type="default"
                  className="btn-success"
                >
                  Mark as Paid
                </Button>
              </Popconfirm>
            )}

            {isDeclineButton && (
              <Popconfirm
                title="Are you sure, you want to DECLINE this order"
                onConfirm={() => {
                  paidStatus === 'Paid'
                    ? this.updateOrderStatus(get(orderDetails, 'id'), {
                        status: 'Canceled',
                        paid_status: 'Refunded'
                      })
                    : this.updateOrderStatus(get(orderDetails, 'id'), {
                        status: 'Canceled'
                      });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    marginLeft: 5
                  }}
                  className="btn-danger"
                >
                  {paidStatus === 'Paid' ? 'Cancel Order & Refund' : 'Cancel Order'}
                </Button>
              </Popconfirm>
            )}
          </Row>
        </Col>
        <Col xs={24} xl={2} />
      </Row>
    );
  };
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getOrders,
    updateOrderStatus,
    getOrdersCount,
    sendOrderFeedback,
    orderBulkUpdate
  })(OrderScreen)
);
