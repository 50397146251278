import { faCreditCard, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag, Tooltip } from 'antd';
import { find, round } from 'lodash';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import moment from 'moment';
import React from 'react';
import { BUCKET_URL } from '../constants/apiEndpoints';
import { days } from '../constants/keys';

export default class Helpers {
  static compareRegex(string, pattern) {
    return pattern.test(string);
  }

  static getImageUrl = (key) => {
    if (!key) {
      return `https://breakthrough.org/wp-content/uploads/2018/10/default-placeholder-image.png`;
    }
    return `${BUCKET_URL}${key}`;
  };

  static getUrl(apiPath, params = null) {
    let paramString = '';
    let firstParam = true;
    if (params) {
      paramString += '?';
      map(params, (value, key) => {
        if (firstParam) {
          paramString += `${key}=${value}`;
          firstParam = false;
        } else {
          paramString += `&${key}=${value}`;
        }
      });
    }
    return `${apiPath}${paramString}`;
  }

  static getCustomerTotalOrderAmount = (orders = []) => {
    let total = 0;

    map(orders, (order) => {
      if (
        get(order, 'paid_status') === 'Paid' &&
        get(order, 'status') === 'Delivered'
      ) {
        total += parseFloat(get(order, 'total_amount'));
      }
    });
    return total;
  };

  static getOrderStatusColor(status) {
    switch (status) {
      case 'Incomplete':
        return 'magenta';
      case 'Pending':
        return 'orange';
      case 'Preparing':
        return 'purple';
      case 'Ready':
        return 'green';
      case 'Canceled':
        return 'red';
      case 'Delivered':
        return 'geekblue';
      default:
        return 'magenta';
    }
  }

  static getOrderStatusTag(text) {
    return <Tag color={this.getOrderStatusColor(text)}>{text}</Tag>;
  }

  static getServiceTypeColor(serviceType) {
    switch (serviceType) {
      case 'Pickup':
        return 'cyan';
      case 'Delivery':
        return 'geekblue';
      case 'Dine':
        return 'orange';
      default:
        return 'lime';
    }
  }

  static getServiceTypeTag(serviceType, orderDetails) {
    return (
      <Tag color={this.getServiceTypeColor(serviceType)}>
        {serviceType}{' '}
        {serviceType === 'Dine' ? ` - Table: ${get(orderDetails, 'table_id')}` : ''}
      </Tag>
    );
  }

  static getMultiSelectionTag(allowMultiple) {
    if (allowMultiple) {
      return <Tag color="lime">MULTIPLE</Tag>;
    }
    return <Tag color="gold">SINGLE</Tag>;
  }

  static getPaymentModeIcon(isOnlinePayment) {
    if (isOnlinePayment) {
      return (
        <Tooltip placement="top" title="Online Payment">
          <FontAwesomeIcon
            icon={faCreditCard}
            style={{ fontSize: 20 }}
            className="text-info"
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip placement="top" title="Cash">
        <FontAwesomeIcon
          icon={faMoneyBillAlt}
          style={{ fontSize: 20 }}
          className="text-success"
        />
      </Tooltip>
    );
  }

  static getEnabledForOrderStatus = (status) => {
    return status ? <Tag color="green">Yes</Tag> : <Tag color="orange">No</Tag>;
  };

  static getCustomerFeedbackTags = (text) => {
    switch (text) {
      case 'Great':
        return <Tag color="green">Great</Tag>;
      case 'BelowExpectations':
        return <Tag color="red">Below expectations</Tag>;
      default:
        return '--';
    }
  };

  static getFeedBackStatus = (text) => {
    switch (text) {
      case 'Pending':
        return <Tag color="orange">Pending</Tag>;
      case 'Opened':
        return <Tag color="magenta">Opened</Tag>;
      case 'Completed':
        return <Tag color="geekblue">Completed</Tag>;
      default:
        return '--';
    }
  };

  static getItemType = (isVeg) => {
    if (isNull(isVeg)) {
      return '--';
    }
    return isVeg ? (
      <Tag color="#008000">Veg</Tag>
    ) : (
      <Tag color="#d11002">Non-veg</Tag>
    );
  };

  static getOrderPaidStatus = (text) => {
    switch (text) {
      case 'Paid':
        return <Tag color="green">Paid</Tag>;
      case 'Unpaid':
        return <Tag color="orange">Unpaid</Tag>;
      case 'Refunded':
        return <Tag color="red">Refunded</Tag>;
      default:
        return '--';
    }
  };

  static getReservationStatusTag(status) {
    switch (status) {
      case 'Confirmed':
        return <Tag color="green">Confirmed</Tag>;
      case 'Canceled':
        return <Tag color="red">Canceled</Tag>;
      case 'Pending':
        return <Tag color="orange">Pending</Tag>;
      default:
        return '';
    }
  }

  static getTimeAndTypeSortedArray(data) {
    const timingData = {};
    timingData.dine_timings = [];
    timingData.delivery_timings = [];

    map(days, (day, index) => {
      timingData.dine_timings.push({
        key: index,
        label: day,
        type: 'Dine',
        timings: []
      });
      timingData.delivery_timings.push({
        key: index,
        label: day,
        type: 'Delivery',
        timings: []
      });
    });

    map(data, (timeObj) => {
      const index = indexOf(days, get(timeObj, 'day'));
      if (get(timeObj, 'service_type') === 'Dine') {
        timingData.dine_timings[index].timings.push(timeObj);
      } else {
        timingData.delivery_timings[index].timings.push(timeObj);
      }
    });

    return timingData;
  }

  static getFormatedGraphData = (orderStats) => {
    const _days = [];
    const revenue = [];
    let date = moment()
      .subtract(6, 'days')
      .format('YYYY-MM-DD');

    map([...Array(7)], () => {
      const stats = find(orderStats, (o) => {
        return moment(o.date_of_service).format('YYYY-MM-DD') === date;
      });

      _days.push(moment(date).format('dddd'));
      revenue.push(round(get(stats, 'total_revenue', 0), 2));
      date = moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    });

    return { days: _days, revenue };
  };

  static getOrderWeight = (orderItems) => {
    let weight = 0;

    map(orderItems, (item) => {
      weight += get(item, 'item_price.weight');
    });
    return weight;
  };

  static getCumilativeTaxAmount = (orderItems) => {
    const taxName = [];
    const taxId = [];
    const taxAmount = [];
    let subtotal = 0;

    map(orderItems, (item) => {
      subtotal += get(item, 'item_amount');
      map(get(item, 'order_tax', []), (obj) => {
        let index = taxId.indexOf(get(obj, 'tax.id'));
        if (index === -1) {
          index = taxId.length;
          taxName.push(get(obj, 'tax.name'));
          taxId.push(get(obj, 'tax.id'));
          taxAmount.push(0);
        }

        taxAmount[index] += get(obj, 'amount');
      });

      map(get(item, 'order_addons', []), (obj) => {
        subtotal += get(obj, 'price');
        map(get(obj, 'addon_taxes'), (addOnTaxObj) => {
          let index = taxId.indexOf(get(addOnTaxObj, 'tax.id'));
          if (index === -1) {
            index = taxId.length;
            taxName.push(get(addOnTaxObj, 'tax.name'));
            taxId.push(get(addOnTaxObj, 'tax.id'));
            taxAmount.push(0);
          }

          taxAmount[index] += get(addOnTaxObj, 'amount');
        });
      });
    });

    return { subtotal, taxName, taxAmount };
  };
}
