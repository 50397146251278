import React, { Component } from 'react';
import { Spin, PageHeader } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import requireAuth from '../../hocs/requireAuth';
import { updateLocation } from '../../redux/actions/locationAction';
import MarketingForm from '../../components/Business/MarketingForm';

class MarketingScreen extends Component {

  constructor() {
    super();
    this.state = {
      data:""
    }
  }
  render() {
    const { data } = this.state;
    return (
      <>
        <Spin spinning={false}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">MARKETING</h4>}
            >
              <MarketingForm
                data={data}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { updateLocation })(MarketingScreen)
);
