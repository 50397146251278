import React from 'react';
import { Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import CustomerScreen from '../screens/Customer/CustomerScreen';
import FeedbackScreen from '../screens/Feedback/FeedbackScreen';
import OrderScreen from '../screens/Orders/OrderScreen';

export default () => {
  return (
    <>
      <Route path={ROUTES.CUSTOMER} exact component={CustomerScreen} />
      <Route path={ROUTES.ORDERS} exact component={OrderScreen} />
      <Route path={ROUTES.CUSTOMER_FEEDBACK} exact component={FeedbackScreen} />
    </>
  );
};
