export const ADD_BUSINESS_SUCCESS = 'Business created successfully';
export const UPDATE_BUSINESS_SUCCESS = 'Business updated successfully';

export const ADD_BUSINESS_ADDRESS_SUCCESS = 'Business address created successfully';
export const UPDATE_BUSINESS_ADDRESS_SUCCESS =
  'Business address updated successfully';
export const CHANGE_PASSWORD_SUCCESS = 'password updated successfully';

export const ADD_SERVICE_HOURS_SUCCESS = 'Service Hours created successfully';
export const UPDATE_SERVICE_HOURS_SUCCESS = 'Service Hours updated successfully';

export const ADD_ONLINE_PRESENCE_SUCCESS = 'Online Presence created successfully';
export const UPDATE_ONLINE_PRESENCE_SUCCESS = 'Online Presence updated successfully';

export const ADD_SERVICE_TYPE_SUCCESS = 'Services created successfully';
export const UPDATE_SERVICE_TYPE_SUCCESS = 'Services updated successfully';

export const ADD_CHARGES_AND_TAXES_SUCCESS = ' Details created successfully';
export const UPDATE_CHARGES_AND_TAXES_SUCCESS = 'Details updated successfully';

export const ADD_PAYMENTS_SUCCESS = ' Payments created successfully';
export const UPDATE_PAYMENTS_SUCCESS = 'Payments updated successfully';

export const ADD_RESERVATION_SUCCESS = 'Reservation added successfully';
export const UPDATE_RESERVATION_SUCCESS = 'Reservation update  successfully';

export const ADD_CUSTOMER_SUCCESS = 'Customer added successfully';
export const UPDATE_CUSTOMER_SUCCESS = 'Customer update  successfully';

export const ADD_TIME_SLOT_SUCCESS = 'Time Slot added successfully';
export const DELETE_TIME_SLOT_SUCCESS = 'Time slot removed  successfully';

export const ADD_CATEGORY_SUCCESS = 'Category added successfully';
export const UPDATE_CATEGORY_SUCCESS = 'Category updated successfully';
export const CATEGORY_SORT_SUCCESS = 'Category sorted successfully';

export const ADD_ITEM_SUCCESS = 'Item added successfully';
export const UPDATE_ITEM_SUCCESS = 'Item updated successfully';
export const ITEM_SORT_SUCCESS = 'Item sorted successfully';

export const ADD_ADDON_SUCCESS = 'Addon added successfully';
export const UPDATE_ADDON_SUCCESS = 'Addon updated successfully';
export const ADDON_SORT_SUCCESS = 'Addon sorted successfully';

export const INVALID_USER_TYPE = 'Please enter valid user type credentials';

export const UPDATE_SERVICEABLE_LOCATION =
  'Serviceable location updated successfully';

export const SERVICE_CHARGE_UPDATE_SUCCESS = 'Service Charge updated successfully';
export const SEND_FEEDBACK_SUCCESS = 'Feedback link send successfully';

export const BUSINESS_INFO_UPDATE = 'Business Information updated successfully';
export const OPERATION_HOURS_UPDATE = 'Operation Hours updated successfully';

export const MARKETING_MESSAGE = 'Message saved succesfully';

export const UPDATE_STORE_MESSAGE_SUCCESS = 'Store messages updated successfully';

export const ORDER_BULK_UPDATE_SUCCESS = 'Orders updated successfully';
