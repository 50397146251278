import get from 'lodash/get';
import { notification } from 'antd';
/**
 * Constants
 */
import * as TYPES from '../../constants/actionTypes';
import * as APIS from '../../constants/apiEndpoints';
import { ROLE } from '../../constants/keys';
import { INVALID_USER_TYPE } from '../../constants/messages';

export const login = (credentials) => (dispatch, getState, { api }) => {
  return api.post(APIS.LOGIN, credentials).then((result) => {
    if (get(result, 'user.role.name') !== ROLE) {
      notification.error({
        message: INVALID_USER_TYPE
      });
      throw INVALID_USER_TYPE;
    }

    api.defaults.headers.common.authorization = `Bearer ${result.jwt}`;

    dispatch({
      type: TYPES.LOGIN,
      data: result
    });

    dispatch({
      type: TYPES.UPDATE_LOCATION,
      data: get(result, 'user.locations', [])
    });
    return get(result, 'user.locations', []);
  });
};

export const logout = () => (dispatch, getState, { api }) => {
  dispatch({
    type: TYPES.LOGOUT
  });

  dispatch({
    type: TYPES.FLUSH_LOCATION
  });
  api.defaults.headers.common = {};
  return true;
};

export const changePassword = (userId, payload) => (
  dispatch,
  getState,
  { api }
) => {
  return api
    .put(APIS.USER.replace('{user_id}', userId), payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFcm = (payload) => (dispatch, getState, { api }) => {
  return api
    .post(APIS.CREATE_FCM, payload)
    .then((result) => {
      dispatch({
        type: TYPES.UPDATE_FCM,
        data: result
      });
    })
    .catch((error) => {
      throw error;
    });
};
