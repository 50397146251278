import React, { Component } from 'react';
import {
  Popconfirm,
  Row,
  Spin,
  Icon,
  Drawer,
  Button,
  Typography,
  Tooltip,
  Col,
  Table,
  message
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import Helpers from '../../utilities/helpers';
import AddReservationForm from '../../components/Reservation/AddReservationForm';
import {
  ADD_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_SUCCESS
} from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import {
  getReservations,
  getReservationsCount,
  createReservation,
  updateReservation
} from '../../redux/actions/reservationAction';

const { Text } = Typography;

class ReservationScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isEdit: false,
      total: null,
      current: 1,
      limit: 10,
      loading: false,
      reservationDetails: null,
      data: [],
      businessDetails: []
    };
  }

  componentDidMount() {
    this.getInitial();
  }

  loader = (loading) => this.setState({ loading });

  getInitial = () => {
    this.getReservations();
    this.getReservationsCount();
  };

  getReservations = (current = 1) => {
    const { location } = this.props;
    const { limit } = this.state;

    this.loader(true);

    this.props
      .getReservations(get(location, 'id'), current, limit)
      .then((result) => {
        this.loader(false);
        this.setState({ data: result, current });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getReservationsCount = () => {
    const { location } = this.props;
    this.loader(true);

    this.props
      .getReservationsCount(get(location, 'id'))
      .then((total) => {
        this.loader(false);
        this.setState({ total });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  updatedata = (reservationObj, id = null) => {
    this.loader(true);

    if (isNull(id)) {
      const { location } = this.props;

      reservationObj = { ...reservationObj, location: get(location, 'id') };

      this.props
        .createReservation(reservationObj)
        .then(() => {
          message.success(ADD_RESERVATION_SUCCESS);
          this.setState({ isDrawerVisible: false });
          this.getInitial();
        })
        .catch(() => {
          this.loader(false);
        });
    } else {
      const { location } = this.props;
      reservationObj = { ...reservationObj, location: get(location, 'id') };

      this.props
        .updateReservation(id, reservationObj)
        .then(() => {
          message.success(UPDATE_RESERVATION_SUCCESS);
          this.setState({ isDrawerVisible: false, reservationDetails: null });
          this.getInitial();
        })
        .catch(() => {
          this.loader(false);
        });
    }
  };

  changeReservationStatus = (status, id) => {
    const dataObj = { status };
    this.updatedata(dataObj, id);
    return true;
  };

  toggleDrawer = () => {
    const { location } = this.props;
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      reservationDetails: null,
      businessDetails: location
    });
  };

  editService = (record) => {
    const { location } = this.props;
    this.setState({
      isEdit: true,
      reservationDetails: record,
      isDrawerVisible: true,
      businessDetails: location
    });
  };

  getExpandableDetails = (record) => {
    return (
      <>
        <Row style={{ margin: 10 }}>
          <Col xs={24} xl={12}>
            {' '}
            <Text strong> Customer Name : </Text>{' '}
            {get(record, 'customer_name', '--')}
          </Col>
        </Row>
        <Row style={{ margin: 10 }}>
          <Col xs={24} xl={12}>
            {' '}
            <Text strong> Customer Contact Number : </Text>{' '}
            {get(record, 'customer_contact', '--')}
          </Col>
          <Col xs={24} xl={12}>
            <Text strong> Customer Email : </Text>{' '}
            {get(record, 'customer_email', '--')}
          </Col>
        </Row>
        <Row style={{ margin: 10 }}>
          <Col xs={24} xl={12}>
            <Text strong>Note :</Text>{' '}
            {get(record, 'note') ? get(record, 'note') : '--'}
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const {
      current,
      isDrawerVisible,
      data,
      isEdit,
      reservationDetails,
      businessDetails,
      loading,
      total,
      limit
    } = this.state;

    const BusinessColumn = [
      {
        title: 'Reservation for',
        dataIndex: 'date',
        align: 'center',
        key: 'date',
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text, record) => {
          return `${moment(text).format('DD MMM')} ${moment(
            get(record, 'time'),
            'HH:mm:ss'
          ).format('h:mm a')}`;
        }
        // sorter: (a, b) => a.date.length - b.date.length,
      },

      {
        title: 'Number of people',
        dataIndex: 'people',
        align: 'center',
        key: 'people',
        sorter: (a, b) => a.people - b.people
      },
      {
        title: 'Reservation Status',
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        render: (text) => {
          return Helpers.getReservationStatusTag(text);
        }
      },
      {
        title: 'Reservation made on',
        dataIndex: 'created_at',
        align: 'center',
        key: 'date',
        // sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text) => {
          return moment(text).format('DD MMM h:mm a');
        }
        // sorter: (a, b) => a.date.length - b.date.length,
      },
      // {
      //   title: '',
      //   key: 'action',
      //   children: [
      {
        title: 'Edit',
        key: 'edit',
        align: 'center',
        render: (record) => {
          return (
            <Tooltip placement="top" title="Edit reservation">
              <FontAwesomeIcon
                className="table-action-icon"
                icon={faPen}
                onClick={() => {
                  this.editService(record);
                }}
              />

              {/* <Icon
                    onClick={() => {
                      this.editService(record);
                    }}
                    type="edit"
                    style={{ fontSize: 20 }}
                    theme="twoTone"
                  /> */}
            </Tooltip>
          );
        }
      },
      {
        title: 'Change Status',
        key: 'change_status',
        render: (record) => {
          const status = get(record, 'status');
          let cancelButton = false;
          let confirmButton = false;
          if (status === 'Pending') {
            cancelButton = true;
            confirmButton = true;
          } else if (status === 'Canceled') {
            confirmButton = true;
          } else {
            cancelButton = true;
          }

          return (
            <>
              {confirmButton && (
                <Popconfirm
                  title="Are you sure you want to change status to Confirmed"
                  onConfirm={() => {
                    this.changeReservationStatus(
                      'Confirmed',
                      get(record, 'id')
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      backgroundColor: '#35cc33',
                      borderColor: '#35cc33',
                      minWidth: 80
                    }}
                    type="primary"
                    className="btn-secondary  btn-margin"
                  >
                    Confirm
                  </Button>
                </Popconfirm>
              )}

              {cancelButton && (
                <Popconfirm
                  title="Are you sure you want to change status to Canceled"
                  onConfirm={() => {
                    this.changeReservationStatus('Canceled', get(record, 'id'));
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#f50',
                      borderColor: '#f50',
                      minWidth: 80
                    }}
                    className="btn-margin"
                  >
                    Cancel
                  </Button>
                </Popconfirm>
              )}
            </>
          );
        }
      }
      //   ]
      // }
    ];

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <Row>
              <Col span={12}>
                <h4 className="text-uppercase">Reservations</h4>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  type="primary"
                  onClick={this.toggleDrawer}
                  className="btn-secondary btn-margin"
                >
                  <Icon className="btn-icon" type="plus" /> Add Reservations
                </Button>
              </Col>
            </Row>

            <Table
              scroll={{ x: 800 }}
              size="small"
              columns={BusinessColumn}
              expandedRowRender={(record) => {
                return this.getExpandableDetails(record);
              }}
              dataSource={data}
              pagination={{
                defaultPageSize: limit,
                current,
                total,
                onChange: (val) => {
                  this.getReservations(val);
                }
              }}
            />
          </div>
          <Drawer
            title={isEdit ? 'Update Reservation' : 'Add Reservation'}
            width={window.innerWidth < 500 ? window.innerWidth * 0.7 : 450}
            destroyOnClose
            onClose={this.toggleDrawer}
            visible={isDrawerVisible}
          >
            <AddReservationForm
              businessDetails={businessDetails}
              reservationDetails={reservationDetails}
              isEdit={isEdit}
              updatedata={this.updatedata}
            />
          </Drawer>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getReservations,
    getReservationsCount,
    createReservation,
    updateReservation
  })(ReservationScreen)
);
