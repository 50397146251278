import React from 'react';
import { Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

import ContactDetailsScreen from '../screens/Business/ContactDetailsScreen';
import BusinessAddressScreen from '../screens/Business/BusinessAddressScreen';
import ServiceHoursScreen from '../screens/Business/ServiceHoursScreen';
import OnlinePresenceScreen from '../screens/Business/OnlinePresenceScreen';
import ServiceTypeScreen from '../screens/Business/ServiceTypeScreen';
import ReservationsScreen from '../screens/Business/ReservationsScreen';
import ChargesAndTaxesScreen from '../screens/Business/ChargesAndTaxesScreen';
import PaymentScreen from '../screens/Business/PaymentScreen';
import ServiceableLocationScreen from '../screens/Business/ServiceableLocationScreen';
import ImportSeedScreen from '../screens/Business/ImportSeedScreen';
import ChargesScreen from '../screens/Business/ChargesScreen';
import BusinessInfoScreen from '../screens/Business/BusinessInfoScreen';
import MarketingScreen from '../screens/Business/MarketingScreen';
import StoreMessagesScreen from '../screens/Business/StoreMessagesScreen';

export default () => {
  return (
    <>
      <Route path={ROUTES.CONTACT_DETAILS} exact component={ContactDetailsScreen} />
      <Route path={ROUTES.BUSINESSINFO} exact component={BusinessInfoScreen} />
      <Route path={ROUTES.MARKETING} exact component={MarketingScreen} />
      <Route path={ROUTES.STORE_MESSGAES} exact component={StoreMessagesScreen} />
      <Route
        path={ROUTES.BUSINESS_ADDRESS}
        exact
        component={BusinessAddressScreen}
      />
      <Route path={ROUTES.SERVICE_HOURS} exact component={ServiceHoursScreen} />
      <Route path={ROUTES.ONLINE_PRESENCE} exact component={OnlinePresenceScreen} />
      <Route path={ROUTES.SERVICE_TYPE} exact component={ServiceTypeScreen} />
      <Route
        path={ROUTES.RESERVATION_SETTINGS}
        exact
        component={ReservationsScreen}
      />
      <Route path={ROUTES.IMPORT} exact component={ImportSeedScreen} />
      <Route path={ROUTES.CHARGES} exact component={ChargesScreen} />
      <Route
        path={ROUTES.CHARGES_AND_TAXES}
        exact
        component={ChargesAndTaxesScreen}
      />
      <Route path={ROUTES.PAYMENT} exact component={PaymentScreen} />
      <Route
        path={ROUTES.SERVICEABLE_LOCATION}
        exact
        component={ServiceableLocationScreen}
      />
    </>
  );
};
