import React, { Component } from 'react';
import { Table, Icon } from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => (
  <Icon type="menu" style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export default class SortableTable extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { sortData } = this.props;

    if (oldIndex !== newIndex) {
      sortData(oldIndex, newIndex);
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.props;

    const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    const { columns, dataSource } = this.props;

    const columnsWithDrag = [
      {
        title: '',
        dataIndex: 'sort',
        className: 'drag-visible',
        render: () => <DragHandle />
      },
      ...columns
    ];

    const DraggableContainer = (props) => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );

    return (
      <Table
        size="small"
        pagination={false}
        dataSource={dataSource}
        columns={columnsWithDrag}
        expandIconColumnIndex={-1}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: this.DraggableBodyRow
          }
        }}
      />
    );
  }
}
