import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faLayerGroup,
  faCogs,
  faListAlt,
  faList,
  faSlidersH,
  faUtensils,
  faUsers,
  faShoppingCart,
  faAddressBook,
  faMapMarkedAlt,
  faConciergeBell,
  faClock,
  faMoneyBillAlt,
  faPercentage,
  faMapMarkerAlt,
  faTruck,
  faInfoCircle,
  faPollH
} from '@fortawesome/free-solid-svg-icons';
import * as ROUTES from './routes';

const iconStyle = { marginRight: 10 };

export default [
  {
    name: 'Dashboard',
    hasSubMenu: false,
    faIcon: <FontAwesomeIcon icon={faTachometerAlt} style={iconStyle} />,
    route: ROUTES.DASHBOARD
  },

  {
    name: 'Business Setup',
    hasSubMenu: true,
    faIcon: <FontAwesomeIcon icon={faCogs} style={iconStyle} />,
    subMenu: [
      {
        name: 'Contact Details',
        route: ROUTES.CONTACT_DETAILS,
        faIcon: <FontAwesomeIcon icon={faAddressBook} style={iconStyle} />
      },
      {
        name: 'Business Information',
        route: ROUTES.BUSINESSINFO,
        faIcon: <FontAwesomeIcon icon={faInfoCircle} style={iconStyle} />
      },

      // {
      //   name: 'Marketing',
      //   route: ROUTES.MARKETING,
      //   faIcon: <FontAwesomeIcon icon={faPollH} style={iconStyle} />
      // },
      {
        name: 'Address',
        route: ROUTES.BUSINESS_ADDRESS,
        faIcon: <FontAwesomeIcon icon={faMapMarkedAlt} style={iconStyle} />
      },
      {
        name: 'Marketing',
        route: ROUTES.STORE_MESSGAES,
        faIcon: <FontAwesomeIcon icon={faPollH} style={iconStyle} />
      },
      {
        name: 'Service Types',
        route: ROUTES.SERVICE_TYPE,
        faIcon: <FontAwesomeIcon icon={faConciergeBell} style={iconStyle} />
      },
      {
        name: 'Reservation Settings',
        route: ROUTES.RESERVATION_SETTINGS,
        faIcon: <FontAwesomeIcon icon={faUtensils} style={iconStyle} />
      },
      {
        name: 'Service Hours',
        route: ROUTES.SERVICE_HOURS,
        faIcon: <FontAwesomeIcon icon={faClock} style={iconStyle} />
      },
      {
        name: 'Payment',
        route: ROUTES.PAYMENT,
        faIcon: <FontAwesomeIcon icon={faMoneyBillAlt} style={iconStyle} />
      },
      {
        name: 'Taxes',
        route: ROUTES.CHARGES_AND_TAXES,
        faIcon: <FontAwesomeIcon icon={faPercentage} style={iconStyle} />
      },

      {
        name: 'Serviceable Locations',
        route: ROUTES.SERVICEABLE_LOCATION,
        faIcon: <FontAwesomeIcon icon={faMapMarkerAlt} style={iconStyle} />
      },

      {
        name: 'Delivery Charges',
        route: ROUTES.CHARGES,
        faIcon: <FontAwesomeIcon icon={faTruck} style={iconStyle} />
      }
    ]
  },
  {
    name: 'Item Categories',
    hasSubMenu: false,
    route: ROUTES.CATEGORY,
    faIcon: <FontAwesomeIcon icon={faLayerGroup} style={iconStyle} />
  },

  {
    name: 'Items',
    hasSubMenu: true,
    faIcon: <FontAwesomeIcon icon={faListAlt} style={iconStyle} />,
    subMenu: [
      {
        name: 'Items List',
        faIcon: <FontAwesomeIcon icon={faList} style={iconStyle} />,
        route: ROUTES.ITEMS
      },
      {
        name: 'Addon',
        faIcon: <FontAwesomeIcon icon={faSlidersH} style={iconStyle} />,
        route: ROUTES.ADD_ON
      }
    ]
  },

  {
    name: 'Orders',
    hasSubMenu: false,
    route: ROUTES.ORDERS,
    faIcon: <FontAwesomeIcon icon={faShoppingCart} style={iconStyle} />
  },

  {
    name: 'Customers',
    hasSubMenu: false,
    route: ROUTES.CUSTOMER,
    faIcon: <FontAwesomeIcon icon={faUsers} style={iconStyle} />
  },

  // {
  //   name: 'Feedbacks',
  //   hasSubMenu: false,
  //   route: ROUTES.CUSTOMER_FEEDBACK,
  //   faIcon: <FontAwesomeIcon icon={faComments} style={iconStyle} />
  // },

  {
    name: 'Reservations',
    hasSubMenu: false,
    route: ROUTES.RESERVATION,
    faIcon: <FontAwesomeIcon icon={faUtensils} style={iconStyle} />
  }
];
