/**
 * Constants
 */
import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getFeedbacks = (
  location,
  page = 1,
  limit = 10,
  status = [],
  query = null
) => (dispatch, getState, { api }) => {
  const start = (page - 1) * limit;
  let paramObj = {
    _limit: limit,
    _start: start,
    location,
    _sort: 'created_at:DESC'
  };

  if (query) {
    paramObj.query = query;
  }
  if (status.length > 0) {
    paramObj.status = status;
  }

  const url = Helpers.getUrl(APIS.CUSTOMER_FEEDBACKS, paramObj);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
