import React, { Component } from 'react';
import { Spin, PageHeader } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import requireAuth from '../hocs/requireAuth';
import ChangePasswordForm from '../components/ChangePasswordForm';

class ChangePasswordScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  loader = (loading) => this.setState({ loading });

  componentDidMount() {}

  render() {
    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        <div className="main-container">
          <PageHeader
            className="site-page-header-responsive title title-md mb--20"
            title={<h4 className="text-uppercase">Change Password</h4>}
            // subTitle="Add a new bussiness"
          >
            <ChangePasswordForm loader={this.loader} />
          </PageHeader>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(connect(mapStateToProps, {})(ChangePasswordScreen));
