import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';
import fileDownload from 'js-file-download';

export const getOrders = (
  location,
  page = 1,
  limit = 10,
  query = null,
  searchStatus = [],
  paymentStatus = []
) => (dispatch, getState, { api }) => {
  const start = (page - 1) * limit;
  const paramObj = {
    _limit: limit,
    _start: start,
    location
  };

  if (query) {
    paramObj.query = query;
  }

  if (searchStatus.length > 0) {
    paramObj.status = searchStatus.join(',');
  }

  if (paymentStatus.length > 0) {
    paramObj.paymeny_status = paymentStatus.join(',');
  }
  const url = Helpers.getUrl(APIS.ORDERS, paramObj);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getOrdersCount = (location) => (dispatch, getState, { api }) => {
  const url = APIS.ORDER_COUNT.replace('{location_id}', location);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateOrderStatus = (orderID, payload) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.UPDATE_ORDERS.replace('{order_id}', orderID);

  return api
    .put(url, payload)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const orderBulkUpdate = (payload) => (dispatch, getState, { api }) => {
  return api
    .put(APIS.ORDERS_BULK_UPDATE, payload)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const sendOrderFeedback = (orderID) => (dispatch, getState, { api }) => {
  const url = APIS.SEND_ORDER_FEEDBACK.replace('{order_id}', orderID);

  return api
    .post(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const exportOrders = (params) => (dispatch, getState, { api }) => {
  const url = Helpers.getUrl(APIS.EXPORT_ORDERS, params);

  return api
    .get(url)
    .then((result) => {
      fileDownload(result, 'order.csv');
      return true;
    })
    .catch((err) => {
      throw err;
    });
};
