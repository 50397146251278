import isNull from 'lodash/isNull';
import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getCategories = (location, parent = null) => (
  dispatch,
  getState,
  { api }
) => {
  const params = {
    location,
    _sort: 'index:ASC'
  };

  if (!isNull(parent)) {
    params.parent_null = parent;
  }
  const url = Helpers.getUrl(APIS.CATEGORY, params);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCategoriesCount = (location) => (
  dispatch,
  getState,
  { api }
) => {
  const url = Helpers.getUrl(APIS.CATEGORY_COUNT, {
    location
  });

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createCategory = (categoryObj) => (
  dispatch,
  getState,
  { api }
) => {
  return api
    .post(APIS.CATEGORY, categoryObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateCategory = (id, categoryObj) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.UPDATE_CATEGORY.replace('{id}', id);

  return api
    .put(url, categoryObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const sortCategory = (categoryObj) => (dispatch, getState, { api }) => {
  return api
    .put(APIS.SORT_CATEGORY, categoryObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCategoryItems = (categoryId) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.CATEGORY_ITEMS.replace('{category_id}', categoryId);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
