import React, { Component } from 'react';
import './utilities/fontAwesome';
import './App.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from './AppRouter';
import { createFCMToken } from './firebase';
import perisistStore from './redux/store/store';
import NotificationSound from './components/notificationSound';
const { store, persistor } = perisistStore;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    createFCMToken();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
          <NotificationSound />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
