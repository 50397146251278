import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { get } from 'lodash';
import { connect } from 'react-redux';

import ContactDetailsForm from '../../components/Business/ContactDetailsForm';
import { UPDATE_BUSINESS_SUCCESS } from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { updateLocation } from '../../redux/actions/locationAction';

class ContactDetailsScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isEdit: true,
      loading: false,

      businessDetail: null
    };
  }

  componentWillMount() {
    this.setState({ businessDetail: get(this.props, 'location') });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ businessDetail: get(nextProps, 'location') });
  }

  loader = (loading) => this.setState({ loading });

  updatedata = (businessObj, index = null) => {
    const { businessDetail } = this.state;
    this.loader(true);
    this.props
      .updateLocation(businessObj, businessDetail)
      .then(() => {
        this.loader(false);
        message.success(UPDATE_BUSINESS_SUCCESS);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      businessDetail: null
    });
  };

  editBusiness = (record) => {
    this.setState({
      isEdit: true,
      businessDetail: record,
      isDrawerVisible: true
    });
  };

  render() {
    const { isEdit, businessDetail, loading } = this.state;

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">Contact Details</h4>}
            >
              <ContactDetailsForm
                loader={this.loader}
                businessDetail={businessDetail}
                isEdit={isEdit}
                updatedata={this.updatedata}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: get(state, 'auth.isLoggedIn'),
  user: get(state, 'auth.user'),
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    updateLocation
  })(ContactDetailsScreen)
);
