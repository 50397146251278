import React from 'react';
import { Form, Input, message, Button } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { CHANGE_PASSWORD_SUCCESS } from '../constants/messages';
import { changePassword } from '../redux/actions/authActions';

class ChangePasswordForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.updatePassword({
          password: get(values, 'password')
        });
      }
    });
  };

  updatePassword = (payload) => {
    const { user } = this.props;
    this.props.loader(true);
    this.props
      .changePassword(get(user, 'id'), payload)
      .then(() => {
        this.props.loader(false);
        message.success(CHANGE_PASSWORD_SUCCESS);
      })
      .catch(() => {
        this.props.loader(false);
      });
  };

  validateConfirmPassword = (rule, value, cb) => {
    const { getFieldValue } = this.props.form;
    const password = getFieldValue('password');
    if (password === value) {
      cb();
      return;
    }
    cb('Confirm password should match with password field');
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        onSubmit={this.handleSubmit}
        labelCol={{
          xs: { span: 24 },
          xl: { span: 4 },
          sm: { span: 4 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          xl: { span: 12 },
          sm: { span: 12 }
        }}
      >
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please input password field!'
              }
            ]
          })(<Input.Password placeholder="Password" size="large" />)}
        </Form.Item>

        <Form.Item label="Confirm password">
          {getFieldDecorator('confirm_password', {
            rules: [
              {
                required: true,
                message: 'Please input confirm password field!'
              },
              { validator: this.validateConfirmPassword }
            ]
          })(<Input.Password placeholder="Confirm Password" size="large" />)}
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Change Password
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  user: get(state, 'auth.user')
});

export default Form.create({ name: 'change_password' })(
  connect(mapStateToProps, { changePassword })(ChangePasswordForm)
);
