import React from 'react';
import { Form, Button, Switch, Input } from 'antd';
import get from 'lodash/get';

class AddServiceTypeForm extends React.Component {
  constructor() {
    super();
    this.state = {
      showOrderPlacementEarly: false
    };
  }

  componentDidMount() {
    const { serviceDetails } = this.props;
    this.setState({
      showOrderPlacementEarly: get(serviceDetails, 'early_order_allowed')
    });
  }

  validateMorethanZero = (rule, value, cb) => {
    if (value > 0) {
      cb();
    } else {
      cb('Value should be greater than 0!');
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { serviceDetails } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!values.early_order_allowed) {
          values.early_order_max_days = 0;
          values.early_order_min_minutes = 0;
        }

        if (!values.table_reservation_allowed) {
          values.min_guests = 0;
          values.max_guests = 0;
          values.advanced_reservation_days = 0;
        }

        this.props.updatedata(values, serviceDetails.id);
      }
    });
  };
  toggleForm = (value, type) => {
    switch (type) {
      case 'early_order':
        this.setState({ showOrderPlacementEarly: value });
        break;
      case 'table_reservation':
        this.setState({ showAcceptTableReservation: value });
        break;
      default:
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { serviceDetails, isEdit } = this.props;

    const { showOrderPlacementEarly } = this.state;

    return (
      <Form
        layout="vertical"
        labelCol={{
          xs: { span: 24 },
          md: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          md: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
      >
        <Form.Item
          label="
        Do you accept Dine-In orders?
        "
        >
          {getFieldDecorator('dine_in', {
            initialValue: isEdit ? get(serviceDetails, 'dine_in') : false
          })(
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={isEdit ? get(serviceDetails, 'dine_in') : false}
            />
          )}
        </Form.Item>
        <Form.Item
          label="
        Do you accept Pick-up orders?
        "
        >
          {getFieldDecorator('pick_up', {
            initialValue: isEdit ? get(serviceDetails, 'pick_up') : false
          })(
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={isEdit ? get(serviceDetails, 'pick_up') : false}
            />
          )}
        </Form.Item>
        <Form.Item
          label="
        Do you accept Delivery orders?
        "
        >
          {getFieldDecorator('delivery', {
            initialValue: isEdit ? get(serviceDetails, 'delivery') : false
          })(
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={isEdit ? get(serviceDetails, 'delivery') : false}
            />
          )}
        </Form.Item>

        <Form.Item label="Do you want to accept orders for future dates?">
          {getFieldDecorator('early_order_allowed', {
            initialValue: isEdit ? get(serviceDetails, 'early_order_allowed') : false
          })(
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={
                isEdit ? get(serviceDetails, 'early_order_allowed') : false
              }
              onChange={(value) => {
                this.toggleForm(value, 'early_order');
              }}
            />
          )}
        </Form.Item>

        {showOrderPlacementEarly && (
          <>
            <Form.Item
              className="big-label"
              help="If you set it as 30 minutes, the earliest service time customers can select will be 30 minutes from his order time."
              label="What should be the minimum buffer between the order placement time and service time?"
            >
              {getFieldDecorator('early_order_min_minutes', {
                initialValue: isEdit
                  ? get(serviceDetails, 'early_order_min_minutes')
                  : false,
                rules: [
                  {
                    required: true,
                    message: 'Please input the minimum minutes'
                  },
                  { validator: this.validateMorethanZero }
                ]
              })(
                <Input
                  addonAfter="mins"
                  className="small-input"
                  type="number"
                  placeholder="0"
                />
              )}
            </Form.Item>
            <Form.Item
              className="big-label"
              help="If you set the value as 7 days, a customer placing an order today and set the service date between today and 7 days from now"
              label="How many days in advance can you take the order?"
            >
              {getFieldDecorator('early_order_max_days', {
                initialValue: isEdit
                  ? get(serviceDetails, 'early_order_max_days')
                  : false,
                rules: [
                  {
                    required: true,
                    message: 'Please input the maximum days'
                  },
                  { validator: this.validateMorethanZero }
                ]
              })(
                <Input
                  addonAfter="days"
                  className="small-input"
                  type="number"
                  placeholder="0"
                />
              )}
            </Form.Item>
          </>
        )}

        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'service_type_Form' })(AddServiceTypeForm);
