import React from 'react';
import { Row, Col, Tooltip, Typography, Tag, Table, Icon, Button } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../utilities/helpers';

const { Text, Title } = Typography;

export default class ItemDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { itemDetail } = this.props;

    const columns = [
      {
        title: 'Variant type',
        dataIndex: 'type',
        key: 'type'
      },

      {
        title: 'Variant price',
        dataIndex: 'price',
        key: 'price'
      },

      {
        title: 'Available for dine',
        dataIndex: 'dine',
        key: 'is_dine',
        render: (text) => {
          return text ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
          ) : (
            // <Icon
            //   type="check-circle"
            //   theme="twoTone"
            //   style={{ fontSize: 20 }}
            //   twoToneColor="#52c41a"
            // />
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
            // <Icon
            //   type="close-circle"
            //   theme="twoTone"
            //   style={{ fontSize: 20 }}
            //   twoToneColor="red"
            // />
          );
        }
      },

      {
        title: 'Available for delivery',
        dataIndex: 'delivery',
        key: 'is_delivery',
        render: (text) => {
          return text ? (
            <Icon
              type="check-circle"
              theme="twoTone"
              style={{ fontSize: 20 }}
              twoToneColor="#52c41a"
            />
          ) : (
            <Icon
              type="close-circle"
              theme="twoTone"
              style={{ fontSize: 20 }}
              twoToneColor="red"
            />
          );
        }
      }
    ];

    // console.log("data=======>",data,"columns======>",columns);

    return (
      <Row style={{ padding: 20 }}>
        <Row style={{ marginBottom: 20 }}>
          <Col xs={12} xl={12}>
            <Title level={4}> Item Details</Title>
          </Col>
          <Col xs={12} xl={12} className="div-right">
            <Button
              onClick={() => {
                this.props.editItem(itemDetail);
              }}
              type="primary"
              className="btn-secondary div-right"
            >
              <Icon type="edit" color="white" /> Edit Item
            </Button>
          </Col>
        </Row>

        <Row type="flex" style={{ marginBottom: 20 }}>
          <Col xs={12} xl={6}>
            <Text strong>Item name : </Text>
          </Col>
          <Col xs={12} xl={18}>
            {get(itemDetail, 'name')}
          </Col>
        </Row>

        <Row type="flex" style={{ marginBottom: 20 }}>
          <Col xs={12} xl={6}>
            <Text strong>Category : </Text>
          </Col>
          <Col xs={12} xl={18}>
            {map(get(itemDetail, 'menu_categories', []), (obj, index) => (
              <Tag color="blue" key={`category_tag$${index}`}>
                {get(obj, 'name')}
              </Tag>
            ))}
          </Col>
        </Row>

        <Row type="flex" style={{ marginBottom: 20 }}>
          <Col xs={12} xl={6}>
            <Text strong>Veg / Non-Veg : </Text>
          </Col>
          <Col xs={12} xl={18}>
            {Helpers.getItemType(get(itemDetail, 'is_item_veg'))}
          </Col>
        </Row>

        <Row type="flex" style={{ marginBottom: 20 }}>
          <Col xs={12} xl={6}>
            <Text strong>Charges and taxes : </Text>
          </Col>
          <Col xs={12} xl={18}>
            {map(get(itemDetail, 'charges_and_taxes', []), (obj, index) => {
              return (
                <Tooltip
                  placement="top"
                  key={`Tooltip${index}`}
                  title={`Amount: ${get(obj, 'amount')}`}
                >
                  <Tag color="orange" key={`charges_and_taxes_tag${index}`}>
                    {get(obj, 'name')}
                  </Tag>
                </Tooltip>
              );
            })}
          </Col>
        </Row>

        <Row type="flex" style={{ marginBottom: 20 }}>
          <Col xs={12} xl={6}>
            <Text strong>Descriptions : </Text>
          </Col>
          <Col xs={12} xl={18}>
            {get(itemDetail, 'description')}
          </Col>
        </Row>

        <Row type="flex" style={{ marginBottom: 20 }}>
          <Col xs={12} xl={6}>
            <Text strong>Addon : </Text>
          </Col>
          <Col xs={12} xl={18}>
            {map(get(itemDetail, 'addons', []), (val, index) => {
              return (
                <Tag color="purple" key={`addons$${index}`}>
                  {get(val, 'name')}
                </Tag>
              );
            })}
          </Col>
        </Row>

        {!get(itemDetail, 'has_variable_price') && (
          <Row type="flex" style={{ marginBottom: 20 }}>
            <Col xs={12} xl={6}>
              <Text strong>Price : </Text>
            </Col>
            <Col xs={12} xl={18}>
              {get(head(itemDetail.item_prices), 'price')}
            </Col>
          </Row>
        )}

        {get(itemDetail, 'has_variable_price') && (
          <>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={24} xl={12}>
                <Title level={4}> Variant Details</Title>
              </Col>
            </Row>

            <Row type="flex" style={{ marginBottom: 20 }}>
              <Col xs={12} xl={6}>
                <Text strong>Variant Label : </Text>
              </Col>
              <Col xs={12} xl={18}>
                {get(itemDetail, 'variant_label')}
              </Col>
            </Row>

            <Row style={{ marginBottom: 20 }}>
              <Col xs={24} xl={24}>
                <Table
                  pagination={false}
                  scroll={{ x: 500 }}
                  columns={columns}
                  dataSource={get(itemDetail, 'item_prices')}
                />
              </Col>
            </Row>
          </>
        )}

        {!get(itemDetail, 'has_variable_price') && (
          <Row type="flex" style={{ marginBottom: 20 }}>
            <Col xs={12} xl={6}>
              <Text strong>Available for dine : </Text>
            </Col>
            <Col xs={12} xl={6}>
              {get(head(itemDetail.prices), 'dine') ? (
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  style={{ fontSize: 20 }}
                  twoToneColor="#52c41a"
                />
              ) : (
                <Icon
                  type="close-circle"
                  theme="twoTone"
                  style={{ fontSize: 20 }}
                  twoToneColor="red"
                />
              )}
            </Col>

            <Col xs={12} xl={6}>
              <Text strong>Available for delivery : </Text>
            </Col>
            <Col xs={12} xl={6}>
              {get(head(itemDetail.prices), 'delivery') ? (
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  style={{ fontSize: 20 }}
                  twoToneColor="#52c41a"
                />
              ) : (
                <Icon
                  type="close-circle"
                  theme="twoTone"
                  style={{ fontSize: 20 }}
                  twoToneColor="red"
                />
              )}
            </Col>
          </Row>
        )}
      </Row>
    );
  }
}
