import React, { Component } from 'react';
import {
  Row,
  Spin,
  Switch,
  Modal,
  Drawer,
  Icon,
  Button,
  Tooltip,
  Col,
  Table,
  Input,
  message
} from 'antd';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import Helpers from '../../utilities/helpers';

import SortableTable from '../../components/SortableTable';
import AddAddonForm from '../../components/Menu/AddAddonForm';
import {
  ADD_ADDON_SUCCESS,
  UPDATE_ADDON_SUCCESS,
  ADDON_SORT_SUCCESS
} from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { getItems } from '../../redux/actions/itemAction';
import {
  getAddons,
  createAddon,
  updateAddon,
  getSingleAddon,
  updateAddonItem,
  sortAddon
} from '../../redux/actions/addOnAction';

class AddOnScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isItemModalVisible: false,
      isAddOnModalVisible: false,
      searchText: null,
      isEdit: false,
      details: null,
      selectedItems: [],
      selectedAddOnItems: [],
      itemData: [],
      filterData: [],
      data: []
    };
  }

  componentDidMount() {
    this.getInitials();
  }

  getInitials = () => {
    this.getAddons();
    this.getItems();
  };

  loader = (loading) => this.setState({ loading });

  getItems = () => {
    const { location } = this.props;
    this.loader(true);
    this.props
      .getItems(get(location, 'id'))
      .then((itemData) => {
        this.setState({ itemData });
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getSingleAddon = (
    addonId,
    isDrawerVisible = false,
    isItemModalVisible = false,
    isAddOnModalVisible = false
  ) => {
    this.loader(true);
    this.props
      .getSingleAddon(addonId)
      .then((result) => {
        this.loader(false);
        this.setState({
          details: result,
          isEdit: true,
          isDrawerVisible,
          selectedAddOnItems: get(result, 'add_on_items'),
          selectedItems: get(result, 'ITEMS'),
          isItemModalVisible,
          isAddOnModalVisible
        });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getAddons = (searchText = null) => {
    const { location } = this.props;

    this.loader(true);
    this.props
      .getAddons(get(location, 'id'), searchText)
      .then((result) => {
        result = map(result, (obj, i) => {
          return { ...obj, key: `${i + 1}` };
        });

        this.loader(false);
        this.setState({ data: result, filterData: result, searchText });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  updatedata = (addOnObj, id = null) => {
    const { location } = this.props;

    if (isNull(id)) {
      addOnObj = { ...addOnObj, location: get(location, 'id') };
      this.loader(true);
      this.props
        .createAddon(addOnObj)
        .then((result) => {
          message.success(ADD_ADDON_SUCCESS);
          this.setState({ isDrawerVisible: false });
          this.getAddons();
        })
        .catch(() => {
          this.loader(false);
        });
    } else {
      addOnObj = { ...addOnObj, location: get(location, 'id') };
      this.loader(true);
      this.props
        .updateAddon(id, addOnObj)
        .then(() => {
          message.success(UPDATE_ADDON_SUCCESS);
          this.setState({
            isDrawerVisible: false,
            isEdit: false,
            details: null
          });
          this.getAddons();
        })
        .catch(() => {
          this.loader(false);
        });
    }
  };

  updateAddOnStatus = (status, id, addOnId) => {
    this.loader(true);
    this.props
      .updateAddonItem(id, { status })
      .then(() => {
        this.getAddons();
      })
      .catch(() => {
        this.loader(false);
      });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      details: null,
      selectedAddOnItems: [],
      selectedItems: []
    });
  };

  updateDataSource = (data) => {
    this.setState({ data });
  };

  sortAddon = (oldIndex, newIndex) => {
    const { filterData } = this.state;

    const { location } = this.props;
    const addon = filterData[oldIndex];
    const updateObj = {
      oldIndex: get(filterData[oldIndex], 'index'),
      newIndex: get(filterData[newIndex], 'index'),
      addon: get(addon, 'id'),
      location: get(location, 'id')
    };

    this.loader(true);

    this.props
      .sortAddon(updateObj)
      .then(() => {
        this.loader(false);
        message.success(ADDON_SORT_SUCCESS);
        this.getAddons();
      })
      .catch(() => {
        this.loader(false);
      });
  };

  onSearch = (searchText) => {
    const { data } = this.state;
    const filterData = filter(data, (o) => {
      return includes(o.name.toLowerCase(), searchText.toLowerCase());
    });
    this.setState({ filterData, searchText });
  };

  render() {
    const {
      isAddOnModalVisible,
      isDrawerVisible,
      isItemModalVisible,
      selectedItems,
      searchText,
      isEdit,

      details,
      itemData,
      filterData,
      loading
    } = this.state;

    const columns = [
      {
        title: '',
        key: 'expand',
        className: 'drag-visible'
      },
      {
        title: 'Add-on Type',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        className: 'drag-visible'
      },
      {
        title: 'Selection Type',
        dataIndex: 'multiple_selection',
        align: 'center',
        key: 'multiple_selection',
        render: (text) => {
          return Helpers.getMultiSelectionTag(text);
        }
      },
      {
        title: 'Add-on Items',
        align: 'center',
        key: 'items',
        render: (record, index) => {
          return (
            <Tooltip placement="top" title="Add-on Items">
              <span
                role="button"
                tabIndex={index}
                style={{ color: '#1890ff', cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    selectedAddOnItems: get(record, 'addon_items'),
                    isAddOnModalVisible: true
                  });
                }}
              >
                Add-on Items ({get(record, 'addon_items', []).length})
              </span>
            </Tooltip>
          );
        }
      },
      {
        title: 'Items',
        align: 'center',
        key: 'items',
        render: (record, index) => {
          return (
            <Tooltip placement="top" title="Items">
              <span
                role="button"
                tabIndex={index}
                style={{ color: '#1890ff', cursor: 'pointer' }}
                onClick={() => {
                  this.setState({
                    isItemModalVisible: true,
                    selectedItems: get(record, 'menu_items')
                  });
                }}
              >
                Items ({get(record, 'menu_items', []).length})
              </span>
            </Tooltip>
          );
        }
      },
      {
        title: 'Active',
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        render: (text, record) => {
          return text ? (
            <FontAwesomeIcon
              className="table-action-icon text-success"
              icon={faCheckCircle}
            />
          ) : (
            <FontAwesomeIcon
              className="table-action-icon text-danger"
              icon={faTimesCircle}
            />
          );
        }
      },
      {
        title: '',
        key: 'edit',
        render: (record) => {
          return (
            <Tooltip placement="top" title="Edit addon">
              <FontAwesomeIcon
                className="table-action-icon"
                icon={faPen}
                onClick={() => {
                  this.setState({
                    details: record,
                    isEdit: true,
                    isDrawerVisible: true
                  });
                }}
              />
            </Tooltip>
          );
        }
      }
    ];

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <Row>
              <Col span={12}>
                <h4 className="text-uppercase">Add-ons</h4>
              </Col>
              <Col xl={2} />
              <Col
                span={6}
                style={{
                  textAlign: 'right'
                }}
              >
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  style={{ minWidth: 20 }}
                  suffix={
                    searchText ? (
                      <Icon
                        onClick={() => {
                          this.onSearch('');
                        }}
                        type="close-circle"
                        style={{ fontSize: 15 }}
                      />
                    ) : (
                      <Icon
                        onClick={() => {
                          this.onSearch('');
                        }}
                        type="search"
                        style={{ fontSize: 15 }}
                      />
                    )
                  }
                  onChange={(e) => {
                    this.onSearch(e.target.value);
                  }}
                />
              </Col>
              <Col
                span={4}
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  type="primary"
                  onClick={this.toggleDrawer}
                  className="btn-secondary btn-margin"
                >
                  <Icon className="btn-icon" type="plus" /> Add Add-on
                </Button>
              </Col>
            </Row>

            <SortableTable
              updateDataSource={this.updateDataSource}
              columns={columns}
              dataSource={filterData}
              sortData={this.sortAddon}
            />
          </div>

          <Drawer
            title={isEdit ? 'Update Addon ' : 'Add Addon'}
            destroyOnClose
            onClose={this.toggleDrawer}
            visible={isDrawerVisible}
            width={window.innerWidth < 500 ? window.innerWidth * 0.8 : 700}
          >
            <AddAddonForm
              itemData={itemData}
              details={details}
              isEdit={isEdit}
              updatedata={this.updatedata}
            />
          </Drawer>

          <Modal
            title="Items"
            destroyOnClose
            onCancel={() => {
              this.setState({
                isItemModalVisible: false,
                selectedAddOnItems: [],
                selectedItems: []
              });
            }}
            visible={isItemModalVisible}
            footer={false}
          >
            <Table
              pagination={false}
              key="1"
              showHeader={false}
              columns={[
                {
                  title: 'Item',
                  key: 'name',
                  dataIndex: 'name'
                  // render: (record) => {
                  //   return get(record, 'name');
                  // }
                }
              ]}
              dataSource={selectedItems}
            />
          </Modal>

          <Modal
            title="Addon Items"
            destroyOnClose
            onCancel={() => {
              this.setState({
                isAddOnModalVisible: false,
                selectedAddOnItems: [],
                selectedItems: []
              });
            }}
            visible={isAddOnModalVisible}
            footer={false}
          >
            <Spin spinning={loading}>
              <Table
                key="2"
                pagination={false}
                columns={[
                  {
                    title: 'Items',
                    key: 'name',
                    dataIndex: 'name'
                  },
                  {
                    title: 'Price',
                    key: 'price',
                    dataIndex: 'price'
                  },
                  {
                    title: 'Status',
                    key: 'status',
                    render: (record, i, index) => {
                      return (
                        <Switch
                          onChange={(status) => {
                            this.updateAddOnStatus(
                              status,
                              record.id,
                              get(record, 'addon.id')
                            );
                          }}
                          defaultChecked={record.status}
                        />
                      );
                    }
                  }
                ]}
                dataSource={this.state.selectedAddOnItems}
              />
            </Spin>
          </Modal>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getAddons,
    createAddon,
    updateAddon,
    getSingleAddon,
    updateAddonItem,
    getItems,
    sortAddon
  })(AddOnScreen)
);
