import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import BusinessInfoForm from '../../components/Business/BusinessInfoForm'
import { BUSINESS_INFO_UPDATE } from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { updateLocation } from '../../redux/actions/locationAction';

class BusinessInfoScreen extends Component {
  constructor() {
    super();
    this.state = {
      isEdit: true,
      loading: false,
      credentials: null
    };
  }

  componentWillMount() {
    this.setState({ credentials: get(this.props, 'location') });
  }

  loader = (loading) => this.setState({ loading });

  updatedata = (serviceObj, index = null) => {
    const { credentials } = this.state;
    this.loader(true);
    this.props
      .updateLocation(serviceObj, credentials)
      .then(() => {
        this.loader(false);
        message.success(BUSINESS_INFO_UPDATE);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ credentials: get(nextProps, 'location') });
  }

  render() {
    const { loading, isEdit, credentials } = this.state;

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">Business Information</h4>}
            >
              <BusinessInfoForm
                credentials={credentials}
                isEdit={isEdit}
                updatedata={this.updatedata}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { updateLocation })(BusinessInfoScreen)
);
