import get from 'lodash/get';
import * as TYPES from '../../constants/actionTypes';

import { axiosInstance } from '../../utilities/configureAxios';

export default (
  state = {
    user: null,
    token: null,
    isLoggedIn: false,
    fcm: null
  },
  action
) => {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        token: action.data.jwt,
        user: action.data.user,
        isLoggedIn: true
      };

    case TYPES.UPDATE_FCM:
      return { ...state, fcm: get(action, 'data.fcm_token') };

    case TYPES.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isLoggedIn: false,
        fcm: null
      };

    case 'persist/REHYDRATE':
      if (get(action.payload, 'auth.token', null)) {
        axiosInstance.defaults.headers.common.authorization = `Bearer ${get(
          action.payload,
          'auth.token',
          null
        )}`;
      }
      return state;

    default:
      return state;
  }
};
