import React, { Component } from 'react';
import { Spin, Row, Select, Icon, Col, Table, Input } from 'antd';

import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { connect } from 'react-redux';
import Helpers from '../../utilities/helpers';

import requireAuth from '../../hocs/requireAuth';
import { getFeedbacks } from '../../redux/actions/feedbackAction';
const { Option } = Select;

class FeedbackScreen extends Component {
  constructor() {
    super();

    this.state = {
      isDrawerVisible: false,
      isEdit: false,
      details: null,
      total: null,
      current: 1,
      loading: false,
      limit: 20,
      data: [],
      status: [],
      isOrderDrawerVisible: false
    };

    this.throttleHandleChange = debounce(this.throttleHandleChange.bind(this), 500);
  }

  loader = (loading) => this.setState({ loading });

  componentDidMount() {
    this.getFeedbacks();
  }

  getFeedbacks = (current = 1, status = [], query = null) => {
    const { location } = this.props;
    const { limit } = this.state;

    this.loader(true);

    this.props
      .getFeedbacks(get(location, 'id'), current, limit, status, query)
      .then((result) => {
        const { feedbacks, count } = result;

        this.loader(false);
        this.setState({ data: feedbacks, status, current, total: count });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  onSearch = (searchText) => {
    this.throttleHandleChange(searchText);
    this.setState({ searchText });
  };

  throttleHandleChange(query = null) {
    let { status } = this.state;

    this.getFeedbacks(1, status, query);
  }

  render() {
    const { loading, data, limit, current, total, status, searchText } = this.state;

    const BusinessColumn = [
      {
        title: 'Name',
        align: 'left',
        dataIndex: 'customer.name',
        key: 'customer_name',
        sorter: (a, b) => a.customer.name.localeCompare(b.customer.name)
      },
      {
        title: 'contact number',
        dataIndex: 'customer.phone_number',
        align: 'left',
        key: 'customer.phone_number'
      },
      {
        title: 'Email',
        dataIndex: 'customer.email',
        align: 'left',
        key: 'email'
      },

      {
        title: 'Order',
        dataIndex: 'order.order_id',
        align: 'left',
        key: 'order'
      },

      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left',
        key: 'status',
        render: (text) => {
          return Helpers.getFeedBackStatus(text);
        }
      },

      {
        title: 'Feedback',

        align: 'left',
        dataIndex: 'feedback',
        key: 'feedback',
        render: (text) => {
          return Helpers.getCustomerFeedbackTags(text);
        }
      },
      {
        title: 'Descripiton',
        width: 350,
        align: 'center',
        dataIndex: 'description',
        key: 'description'
      }
    ];

    return (
      <Spin spinning={loading}>
        <div className="main-container">
          <Row>
            <Col span={13}>
              <h4 className="text-uppercase">Customer Feedbacks</h4>
            </Col>
            <Col
              span={5}
              style={{
                textAlign: 'right',
                paddingRight: 10
              }}
            >
              <Input
                type="text"
                placeholder="Search"
                value={searchText}
                style={{ minWidth: 20 }}
                suffix={
                  searchText ? (
                    <Icon
                      onClick={() => {
                        this.onSearch('');
                      }}
                      type="close-circle"
                      style={{ fontSize: 15 }}
                    />
                  ) : (
                    <Icon
                      onClick={() => {
                        this.onSearch('');
                      }}
                      type="search"
                      style={{ fontSize: 15 }}
                    />
                  )
                }
                onChange={(e) => {
                  this.onSearch(e.target.value);
                }}
              />
            </Col>

            <Col
              span={6}
              style={{
                textAlign: 'right'
              }}
            >
              <Select
                mode="multiple"
                value={status}
                style={{ width: '100%' }}
                onChange={(val) => {
                  this.getFeedbacks(1, val, searchText);
                }}
                placeholder="Feedback status"
              >
                {['Pending', 'Opened', 'Completed'].map((status) => {
                  return (
                    <Option value={status} label={status} key={status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          <Table
            scroll={{ x: 800 }}
            columns={BusinessColumn}
            size="small"
            dataSource={data}
            pagination={{
              defaultPageSize: limit,
              current,
              total,
              onChange: (val) => {
                this.getFeedbacks(val, status, searchText);
              }
            }}
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { getFeedbacks })(FeedbackScreen)
);
