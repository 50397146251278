import React from 'react';
import { Tag, Tooltip } from 'antd';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';

const getOrderStatusColor = (status) => {
  switch (status) {
    case 'Incomplete':
      return 'magenta';
    case 'Pending':
      return 'orange';
    case 'Preparing':
      return 'purple';
    case 'Ready':
      return 'green';
    case 'Canceled':
      return 'red';
    case 'Delivered':
      return 'geekblue';
    default:
      return 'magenta';
  }
};
const getServiceTypeColor = (serviceType) => {
  switch (serviceType) {
    case 'Pickup':
      return 'cyan';
    case 'Delivery':
      return 'geekblue';
    case 'Dine':
      return 'orange';
    default:
      return 'lime';
  }
};

const OrderStatus = ({ orderDetails }) => {
  return <Tag color={getOrderStatusColor(orderDetails)}>{orderDetails}</Tag>;
};

const DeliveryType = ({ serviceType, orderDetails }) => {
  return (
    <Tag color={getServiceTypeColor(serviceType)}>
      {serviceType}{' '}
      {serviceType === 'Dine' ? ` - Table: ${get(orderDetails, 'table_id')}` : ''}
    </Tag>
  );
};

const IncompletePaymentMode = ({ isOnlinePayment, status }) => {
  if (isOnlinePayment && status === 'Incomplete') {
    return (
      <Tooltip placement="top" title="Online Payment">
        <FontAwesomeIcon
          icon={faCreditCard}
          style={{ fontSize: 20 }}
          className="text-info"
        />
      </Tooltip>
    );
  }
  return '';
};

const PaymentMode = ({ isOnlinePayment }) => {
  if (isOnlinePayment) {
    return (
      <Tooltip placement="top" title="Online Payment">
        <FontAwesomeIcon
          icon={faCreditCard}
          style={{ fontSize: 20 }}
          className="text-info"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="top" title="Cash">
      <FontAwesomeIcon
        icon={faMoneyBillAlt}
        style={{ fontSize: 20 }}
        className="text-success"
      />
    </Tooltip>
  );
};

const PaymentStatus = ({ orderDetails }) => {
  switch (orderDetails) {
    case 'Paid':
      return <Tag color="green">Paid</Tag>;
    case 'Unpaid':
      return <Tag color="orange">Unpaid</Tag>;
    case 'Refunded':
      return <Tag color="red">Refunded</Tag>;
    default:
      return '--';
  }
};

export {
  OrderStatus,
  DeliveryType,
  PaymentMode,
  PaymentStatus,
  IncompletePaymentMode
};
