import React, { Component } from 'react';
import {
  Spin,
  PageHeader,
  Upload,
  Row,
  Button,
  Icon,
  message,
  Radio,
  Col,
  Table
} from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { post } from 'axios';
import requireAuth from '../../hocs/requireAuth';
import { IMPORT_SEED_FILE } from '../../constants/apiEndpoints';

class ImportSeedScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fileList: [],
      uploading: false,
      radioValue: 'category'
    };
  }

  getparams = () => {
    const { radioValue } = this.state;
    if (radioValue === 'category') {
      return `?category=true`;
    }
    if (radioValue === 'category&items') {
      return `?category=true&&items=true`;
    }
    if (radioValue === 'addon') {
      return `?addon=true`;
    }
    if (radioValue === 'zipCode') {
      return `?zipCode=true`;
    }
    return '';
  };

  handleUpload = async () => {
    const { fileList } = this.state;
    const formData = new FormData();
    const { token, location } = this.props;
    formData.append('files.import', fileList[0]);

    formData.append('data', JSON.stringify({}));

    if (!get(location, 'id')) {
      message.error('Invalid location Parameter please logout and login again');
      return;
    }
    let url = IMPORT_SEED_FILE.replace('{location}', get(location, 'id'));
    url = `${url}${this.getparams()}`;

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };

    this.setState({
      uploading: true
    });

    return post(url, formData, config)
      .then(() => {
        message.success('File uploaded sucessfully');
        this.setState({
          uploading: false,
          fileList: []
        });
      })
      .catch((error) => {
        this.setState({
          uploading: false
        });
        message.error(
          get(error, 'response.data.message')
            ? get(error, 'response.data.message')
            : 'Something went wrong please try again'
        );
      });
  };

  componentDidMount() {}

  loader = (loading) => this.setState({ loading });

  onChange = (e) => {
    this.setState({ radioValue: e.target.value });
  };

  render() {
    const { loading } = this.state;
    const { uploading, fileList, radioValue } = this.state;
    const columns = [
      {
        title: 'Seed Type',
        dataIndex: 'label',
        key: 'seedType'
      },
      {
        title: 'Seed Sheet',
        dataIndex: 'sheetName',
        key: 'seedSheet',
        render: (sheetName) => {
          return (
            <a href={require(`../../assets/seedSheets/${sheetName}`)}>
              Download
            </a>
          );
        }
      }
    ];

    const data = [
      {
        label: 'Pincode seed Sheet',
        sheetName: 'grubly_demo_seed_zipcodes.xlsx'
      },
      {
        label: 'Catgeory seed Sheet',
        sheetName: 'grubly_demo_seed_Category.xlsx'
      },
      {
        label: 'Addon seed Sheet',
        sheetName: 'grubly_demo_seed_Addon.xlsx'
      },
      {
        label: 'Catgeory and item seed Sheet',
        sheetName: 'grubly_demo_seed_Category_and_items.xlsx'
      }
    ];
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          return {
            fileList: []
          };
        });
      },

      beforeUpload: (file) => {
        const fileName = file.name.split('.');
        const isFileXlsx = !!(
          fileName[fileName.length - 1] &&
          fileName[fileName.length - 1] === 'xlsx'
        );

        if (!isFileXlsx) {
          message.error('Invalid file format, only xlsx file is allowed');
          return false;
        }
        this.setState((state) => ({
          fileList: [file]
        }));
        return false;
      },
      fileList
    };

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">Import Seed file</h4>}
            >
              <Row>
                <Col xl={24} xs={24}>
                  <Upload {...props}>
                    <Button>
                      <Icon type="upload" /> Select File
                    </Button>
                  </Upload>
                </Col>
              </Row>
              <Row>
                <Col xl={24} xs={24} style={{ marginTop: 10 }}>
                  <Radio.Group onChange={this.onChange} value={radioValue}>
                    <Radio value="zipCode">Pincodes</Radio>
                    <Radio value="category">Category</Radio>
                    <Radio value="addon">Addon</Radio>
                    <Radio value="category&items">Catgegory and items</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Button
                type="primary"
                onClick={this.handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{ marginTop: 16 }}
              >
                {uploading ? 'Uploading' : 'Start Upload'}
              </Button>

              <Row style={{ marginTop: 50 }}>
                <Col xl={24} xs={24}>
                  <Table columns={columns} dataSource={data} />
                </Col>
              </Row>
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location'),
  token: get(state, 'auth.token')
});

export default requireAuth(connect(mapStateToProps, {})(ImportSeedScreen));
