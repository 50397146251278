import React, { Component } from 'react';

export default class NotificationSound extends Component {
  componentDidMount() {}

  playAudio = () => {
    const audioEl = document.getElementsByClassName('audio-element')[0];
    audioEl.play();
  };

  render() {
    return (
      <div>
        <audio className="audio-element">
          <source src="https://grubly-assets.s3.amazonaws.com/soft-bells.mp3"></source>
        </audio>
      </div>
    );
  }
}
