import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getTaxAndCharges = (
  location,
  page = 1,
  limit = 10,
  considerParams = false
) => (dispatch, getState, { api }) => {
  const start = (page - 1) * limit;
  let url = '';
  if (considerParams) {
    url = Helpers.getUrl(APIS.CHARGES_AND_TAXES, {
      _limit: limit,
      _start: start,
      location
      // _sort: 'updated_at:DESC'
    });
  } else {
    url = Helpers.getUrl(APIS.CHARGES_AND_TAXES, {
      location,
      type_eq: 'Tax'
    });
  }
  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTaxAndChargesCount = (location) => (
  dispatch,
  getState,
  { api }
) => {
  const url = Helpers.getUrl(APIS.CHARGES_AND_TAXES_COUNT, {
    location
  });
  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createTaxAndCharges = (chargeAndTaxObj) => (
  dispatch,
  getState,
  { api }
) => {
  return api
    .post(APIS.CHARGES_AND_TAXES, chargeAndTaxObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateTaxAndCharges = (id, chargeAndTaxObj) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.GET_UPDATE_CHARGES_AND_TAXES.replace('{id}', id);

  return api
    .put(url, chargeAndTaxObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
