import React from 'react';
import { Form, Select, Button, Checkbox, Col } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';

const { Option } = Select;

class AddPaymentForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { details } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values, details.id);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { details, isEdit, currency, onlineSwitch } = this.props;

    return (
      <Col>
        <Form
          onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
          layout="vertical"
          labelCol={{
            xs: { span: 24 },
            xl: { span: 24 },
            sm: { span: 24 }
          }}
          wrapperCol={{
            xs: { span: 24 },
            xl: { span: 24 },
            sm: { span: 24 }
          }}
        >
          <Form.Item style={{ width: '200px' }} label="Currency Accepted">
            {getFieldDecorator('currency', {
              initialValue: isEdit ? get(details, 'currency.id') : null,
              rules: [{ required: true, message: 'Please select a currency' }]
            })(
              <Select size="large" placeholder="Select a currency">
                {map(currency, (currencyObj, i) => {
                  return (
                    <Option key={i} value={get(currencyObj, 'id')}>{`${get(
                      currencyObj,
                      'name'
                    )} (${get(currencyObj, 'sign')})`}</Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('tax_included', {
              initialValue: isEdit ? get(details, 'tax_included') : false
            })(
              <Checkbox defaultChecked={get(details, 'tax_included')}>
                The item prices are including taxes?
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('cash_payment_facility', {
              initialValue: isEdit ? get(details, 'cash_payment_facility') : false
            })(
              <Checkbox
                defaultChecked={
                  isEdit ? get(details, 'cash_payment_facility') : false
                }
              >
                Do you accept cash ?
              </Checkbox>
            )}
          </Form.Item>
          {onlineSwitch && (
            <Form.Item>
              {getFieldDecorator('online_payment_facility', {
                initialValue: isEdit
                  ? get(details, 'online_payment_facility')
                  : false
              })(
                <Checkbox
                  defaultChecked={
                    isEdit ? get(details, 'online_payment_facility') : false
                  }
                >
                  Do you accept payments via Credit Card, Debit Card, UPI, Wallets,
                  etc?
                </Checkbox>
              )}
            </Form.Item>
          )}
          <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit" className="btn-secondary">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Col>
    );
  }
}

export default Form.create({ name: 'payment_Form' })(AddPaymentForm);
