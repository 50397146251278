import React, { Component } from 'react';
import { Row, Col, Spin } from 'antd';
import get from 'lodash/get';
import head from 'lodash/head';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import * as ROUTES from '../constants/routes';
import { login } from '../redux/actions/authActions';

class LoginScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  loader = (loading) => this.setState({ loading });

  onLoginSubmit = (credentials) => {
    const { props } = this.props;
    this.loader(true);

    this.props
      .login(credentials)
      .then((res) => {
        this.props.refreshLocationData(get(head(res), 'id'));
        this.loader(false);
        props.history.push(ROUTES.DASHBOARD);
      })
      .catch((res) => {
        this.loader(false);
      });
  };

  render() {
    const { loading } = this.state;
    const { isLoggedIn } = this.props;

    if (isLoggedIn) return <Redirect to={ROUTES.DASHBOARD} />;

    return (
      <div className="login-section bg-illustration">
        <div className="container">
          <Row type="flex" justify="end" align="middle" className="row-height">
            <Col xs={24} sm={16} lg={8}>
              <div className="card card-login primary-center-shadow">
                <h4 className="title title-md mb--20">Login to your account</h4>

                <div className="fx">
                  <Spin spinning={loading}>
                    <LoginForm onSubmit={this.onLoginSubmit} />
                  </Spin>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps, {
  login
})(LoginScreen);
