import React from 'react';
import {
  Form,
  Input,
  Select,
  Switch,
  Icon,
  Button,
  Upload,
  message,
  Col,
  Row
} from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import Helpers from '../../utilities/helpers';
import { MOBILE, URL, EMAIL } from '../../constants/regex';
import { mobilePrefix } from '../../constants/keys';
import { getMediaUrl } from '../../redux/actions/mediaAction';

const { Option } = Select;

class ContactDetailsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      // previewVisible: false,
      logoImage: null,
      logoImageUrl: null,
      bannerImage: null,
      bannerImageUrl: null
    };
  }

  componentDidMount() {
    this.setImageUrl(this.props);
  }

  setImageUrl = (props) => {
    const { businessDetail } = props;

    const stateObj = {};
    if (get(businessDetail, 'banner_image')) {
      stateObj.bannerImageUrl = Helpers.getImageUrl(
        get(businessDetail, 'banner_image')
      );
    }

    if (get(businessDetail, 'logo_image')) {
      stateObj.logoImageUrl = Helpers.getImageUrl(
        get(businessDetail, 'logo_image')
      );
    }

    this.setState(stateObj);
  };

  componentWillReceiveProps(nextProps) {
    this.setImageUrl(nextProps);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.updatedata(values);
      }
    });
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    return true;
  };

  getMediaUrl = (info, type = null) => {
    this.props.loader(true);

    this.props
      .getMediaUrl(info)
      .then((result) => {
        this.props.loader(false);
        if (type === 'banner') {
          this.setState({
            bannerImageUrl: get(result, 'imageUrl'),
            bannerImage: get(result, 'key')
          });
        } else {
          this.setState({
            logoImageUrl: get(result, 'imageUrl'),
            logoImage: get(result, 'key')
          });
        }
        // return false;
      })
      .catch(() => {
        this.props.loader(false);
      });
  };

  validateUrl = (rule, value, cb) => {
    if (!value) {
      cb();
    } else if (Helpers.compareRegex(value, URL)) {
      cb();
    } else {
      cb('Url that you enter is invalid!');
    }
  };

  validateAdditionalEmails = (rule, value, cb) => {
    if (!value) {
      cb();
      return;
    }
    const emailArr = value.split(',');
    let status = false;
    const errorEmail = [];

    map(emailArr, (email) => {
      if (!Helpers.compareRegex(email, EMAIL)) {
        status = true;
        errorEmail.push(email);
      }
    });
    if (status) {
      cb(`${errorEmail.join(',')} not in valid email format`);
    } else {
      cb();
    }
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { businessDetail } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { logoImage, bannerImage } = this.state;

        if (bannerImage) {
          values.banner_image = bannerImage;
        }

        if (logoImage) {
          values.logo_image = logoImage;
        }

        this.props.updatedata(values, businessDetail.id);
      }
    });
  };

  validateMobleNumer = (rule, value, cb) => {
    if (!value) {
      cb();
      return;
    }
    if (Helpers.compareRegex(value, MOBILE)) {
      cb();
    } else {
      cb('Phone number that you enter is invalid!');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const { businessDetail, isEdit } = this.props;

    const { logoImageUrl, bannerImageUrl } = this.state;

    const prefixSelector = getFieldDecorator('mobile_prefix', {
      initialValue:
        isEdit && get(businessDetail, 'mobile_prefix')
          ? get(businessDetail, 'mobile_prefix')
          : '91'
    })(
      <Select style={{ width: 70 }}>
        {map(mobilePrefix, (val, i) => {
          return (
            <Option key={`mobile_prefix_${i}`} value={val}>
              +{val}
            </Option>
          );
        })}
      </Select>
    );

    const whatsAppprefixSelector = getFieldDecorator('whatsapp_mobile_prefix', {
      initialValue:
        isEdit && get(businessDetail, 'whatsapp_mobile_prefix')
          ? get(businessDetail, 'whatsapp_mobile_prefix')
          : '91'
    })(
      <Select style={{ width: 70 }}>
        {map(mobilePrefix, (val, i) => {
          return (
            <Option key={`whatsapp_mobile_prefix_${i}`} value={val}>
              +{val}
            </Option>
          );
        })}
      </Select>
    );

    return (
      <Form
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        layout="vertical"
        labelCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
      >
        <Row>
          <Col md={12}>
            <Form.Item
              label="Logo Image"
              extra="Recommended to upload 400px X 400px image"
            >
              {getFieldDecorator('logo_image', {
                initialValue: isEdit ? get(businessDetail, 'logo_image') : ''
              })(
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={this.beforeUpload}
                  showUploadList={false}
                  // handlePreview={this.handlePreview}
                  customRequest={(info) => {
                    this.getMediaUrl(info, 'logo');
                  }}
                >
                  {logoImageUrl ? (
                    <img
                      src={logoImageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Banner Image"
              extra="Recommended to upload 1110px X 340px image"
            >
              {getFieldDecorator('banner_image', {
                initialValue: isEdit ? get(businessDetail, 'banner_image') : ''
              })(
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={this.beforeUpload}
                  showUploadList={false}
                  // handlePreview={this.handlePreview}
                  customRequest={(info) => {
                    this.getMediaUrl(info, 'banner');
                  }}
                >
                  {bannerImageUrl ? (
                    <img
                      src={bannerImageUrl}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Item label="Business Name">
              {getFieldDecorator('name', {
                initialValue: isEdit ? get(businessDetail, 'name') : ''
              })(<Input placeholder="Name" size="large" disabled />)}
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: '10px' }} md={18}>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                initialValue: isEdit ? get(businessDetail, 'description') : '',
                rules: [
                  {
                    required: true,
                    message: 'Please input your business description!'
                  }
                ]
              })(
                <Input placeholder="Describe your business here" size="large" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Item
              label="Phone Number"
              extra="Order and Reservation related notifications will be received on this
          number"
            >
              {getFieldDecorator('phone_number', {
                initialValue: isEdit ? get(businessDetail, 'phone_number') : '',
                rules: [
                  {
                    required: true,
                    message: 'Please input your phone number!'
                  },
                  { validator: this.validateMobleNumer }
                ]
              })(
                <Input
                  placeholder="9876543210"
                  size="large"
                  addonBefore={prefixSelector}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={6} style={{ paddingLeft: '10px' }}>
            <Form.Item
              label="WhatsApp Number"
              extra="Order and Reservation related notifications will be received on this
          number"
            >
              {getFieldDecorator('whatsapp_number', {
                initialValue: isEdit
                  ? get(businessDetail, 'whatsapp_number')
                  : '',
                rules: [{ validator: this.validateMobleNumer }]
              })(
                <Input
                  placeholder="9876543210"
                  size="large"
                  addonBefore={whatsAppprefixSelector}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={6} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Theme Color">
              {getFieldDecorator('theme_color', {
                initialValue: isEdit ? get(businessDetail, 'theme_color') : ''
              })(
                <Input
                  placeholder="Your brand color in HEX format. Eg. #5ab000"
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col md={6} style={{ paddingLeft: '50px' }}>
            <Form.Item label="Product Layout">
              {getFieldDecorator('card_layout', {
                initialValue: isEdit ? get(businessDetail, 'card_layout') : ''
              })(
                <Switch
                  checkedChildren="Card View"
                  unCheckedChildren="List View"
                  defaultChecked={
                    isEdit ? get(businessDetail, 'card_layout') : false
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                initialValue: isEdit ? get(businessDetail, 'email') : '',
                rules: [
                  { required: true, message: 'Please input your email!' },
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail!'
                  }
                ]
              })(<Input placeholder="example@example.com" size="large" />)}
            </Form.Item>
          </Col>
          <Col md={12} style={{ paddingLeft: '10px' }}>
            <Form.Item
              label="Additional Email"
              extra="For multiple emails use comma. E.g. : janedoe@gmail.com,johndoe@gmail.com"
            >
              {getFieldDecorator('additional_email', {
                initialValue: isEdit
                  ? get(businessDetail, 'additional_email')
                  : '',
                validateTrigger: 'onBlur',
                rules: [{ validator: this.validateAdditionalEmails }]
              })(
                <Input
                  placeholder="example@example.com,example2@example.com"
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Form.Item label="Website">
              {getFieldDecorator('website_url', {
                initialValue: isEdit ? get(businessDetail, 'website_url') : '',
                rules: [{ validator: this.validateUrl }]
              })(<Input placeholder="https://yourwebsite.com" size="large" />)}
            </Form.Item>
          </Col>
          <Col md={8} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Instagram">
              {getFieldDecorator('instagram_url', {
                initialValue: isEdit
                  ? get(businessDetail, 'instagram_url')
                  : '',
                rules: [{ validator: this.validateUrl }]
              })(
                <Input
                  placeholder="https://instagram.com/your-instagram-id"
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col md={8} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Facebook Page URL">
              {getFieldDecorator('facebook_url', {
                initialValue: isEdit ? get(businessDetail, 'facebook_url') : '',
                rules: [{ validator: this.validateUrl }]
              })(
                <Input
                  placeholder="https://facebook.com/your-page-id"
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Item label="Google Business">
              {getFieldDecorator('google_review_url', {
                initialValue: isEdit
                  ? get(businessDetail, 'google_review_url')
                  : '',
                rules: [{ validator: this.validateUrl }]
              })(<Input placeholder="" size="large" />)}
            </Form.Item>
          </Col>

          <Col md={12} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Facebook Review">
              {getFieldDecorator('facebook_review_url', {
                initialValue: isEdit
                  ? get(businessDetail, 'facebook_review_url')
                  : '',
                rules: [{ validator: this.validateUrl }]
              })(<Input placeholder="" size="large" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Item label="Swiggy">
              {getFieldDecorator('swiggy_url', {
                initialValue: isEdit ? get(businessDetail, 'swiggy_url') : '',
                rules: [{ validator: this.validateUrl }]
              })(
                <Input
                  placeholder="https://swiggy.com/your-swiggy-id"
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col md={12} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Zomato">
              {getFieldDecorator('zomato_url', {
                initialValue: isEdit ? get(businessDetail, 'zomato_url') : '',
                rules: [{ validator: this.validateUrl }]
              })(
                <Input
                  placeholder="https://zomato.com/your-zomato-id"
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const wrappedForm = Form.create({ name: 'business_Form' })(ContactDetailsForm);

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default connect(mapStateToProps, { getMediaUrl })(wrappedForm);
