import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';

import AddBusinessAddressForm from '../../components/Business/AddBusinessAddressForm';
import { UPDATE_BUSINESS_ADDRESS_SUCCESS } from '../../constants/messages';
import {
  getCountry,
  getStates,
  getTimeZone,
  updateLocation
} from '../../redux/actions/locationAction';
import requireAuth from '../../hocs/requireAuth';

class BusinessAddressScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isEdit: true,
      loading: false,
      countries: [],
      states: [],
      timeZone: [],
      businessAddressDetail: null
    };
  }

  componentDidMount() {
    const businessAddressDetail = get(this.props, 'location');
    this.setState({ businessAddressDetail });
    this.getCountry();
    this.getTimeZone();

    if (get(businessAddressDetail, 'state')) {
      this.getStates(get(businessAddressDetail, 'state.country'));
    }
  }

  loader = (loading) => this.setState({ loading });

  getCountry = () => {
    this.loader(true);
    this.props
      .getCountry()
      .then((result) => {
        this.loader(false);
        this.setState({ countries: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getTimeZone = () => {
    this.loader(true);
    this.props
      .getTimeZone()
      .then((result) => {
        this.loader(false);
        this.setState({ timeZone: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getStates = (country) => {
    this.loader(true);
    this.props
      .getStates(country)
      .then((result) => {
        this.loader(false);
        this.setState({ states: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  updatedata = (businessObj, index = null) => {
    const { businessAddressDetail } = this.state;
    this.loader(true);
    this.props
      .updateLocation(businessObj, businessAddressDetail)
      .then(() => {
        this.loader(false);
        message.success(UPDATE_BUSINESS_ADDRESS_SUCCESS);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      businessAddressDetail: null
    });
  };

  editBusiness = (record) => {
    this.setState({
      isEdit: true,
      businessAddressDetail: record,
      isDrawerVisible: true
    });
  };

  render() {
    const {
      isEdit,
      countries,
      states,
      timeZone,
      businessAddressDetail,
      loading
    } = this.state;

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            {' '}
            <PageHeader
              className="site-page-header-responsive"
              title={<h4 className="text-uppercase">Address</h4>}
            >
              <AddBusinessAddressForm
                countries={countries}
                states={states}
                timeZone={timeZone}
                getState={this.getStates}
                businessAddressDetail={businessAddressDetail}
                isEdit={isEdit}
                updatedata={this.updatedata}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getCountry,
    getStates,
    getTimeZone,
    updateLocation
  })(BusinessAddressScreen)
);
