import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';

import AddOnlinePresenceForm from '../../components/Business/AddOnlinePresenceForm';
import { UPDATE_ONLINE_PRESENCE_SUCCESS } from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { updateLocation } from '../../redux/actions/locationAction';

class OnlinePresenceScreen extends Component {
  constructor() {
    super();
    this.state = {
      isEdit: true,
      loading: false,
      socialMediaDetails: get(this.props, 'location')
    };
  }

  componentDidMount() {
    this.setState({ socialMediaDetails: get(this.props, 'location') });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ socialMediaDetails: get(nextProps, 'location') });
  }

  loader = (loading) => this.setState({ loading });

  updatedata = (socialMediaObj, index = null) => {
    const { socialMediaDetails } = this.state;
    this.loader(true);
    this.props
      .updateLocation(socialMediaObj, socialMediaDetails)
      .then(() => {
        this.loader(false);
        message.success(UPDATE_ONLINE_PRESENCE_SUCCESS);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  render() {
    const { loading, isEdit, socialMediaDetails } = this.state;

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">Online Presence</h4>}
            >
              <AddOnlinePresenceForm
                socialMediaDetails={socialMediaDetails}
                isEdit={isEdit}
                updatedata={this.updatedata}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { updateLocation })(OnlinePresenceScreen)
);
