import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faEye, faPen, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Drawer,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddItemsForm from '../../components/Menu/AddItemForm';
import ItemDetails from '../../components/Menu/ItemDetails';
import SortableTable from '../../components/SortableTable';
import { colours, visibleStatus } from '../../constants/keys';
import {
  ADD_ITEM_SUCCESS,
  ITEM_SORT_SUCCESS,
  UPDATE_ITEM_SUCCESS
} from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { getAddons } from '../../redux/actions/addOnAction';
import { getCategories } from '../../redux/actions/categoryAction';
import {
  createItem,
  getItems,
  getSingleItem,
  sortItem,
  updateItem,
  updateStatus
} from '../../redux/actions/itemAction';
import Helpers from '../../utilities/helpers';

const { Text } = Typography;
const coloursLength = colours.length;
const { Option } = Select;

class ItemScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isItemModalVisible: false,
      isCategoryModalVisible: false,
      isAddonModalVisible: false,
      selectedCategory: [],
      selectedAddons: [],
      isEdit: false,
      details: null,
      loading: false,
      searchText: '',
      data: [],
      addons: [],
      filterData: [],
      categories: [],
      searchCategory: [],
      searchStatus: [get(head(visibleStatus), 'value')]
    };
  }

  componentDidMount() {
    this.getItems();
    this.getAddons();
    this.getCategories();
  }

  getCategories = (current = 1) => {
    const { location } = this.props;

    this.loader(true);

    this.props
      .getCategories(get(location, 'id'))
      .then((result) => {
        this.loader(false);
        this.setState({ categories: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getAddons = () => {
    const { location } = this.props;

    this.loader(true);
    this.props
      .getAddons(get(location, 'id'))
      .then((result) => {
        this.loader(false);
        this.setState({ addons: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getSingleItem = (itemId, isDrawerVisible = false, isItemModalVisible = false) => {
    this.loader(true);
    this.props
      .getSingleItem(itemId)
      .then((result) => {
        this.loader(false);
        this.setState({
          details: result,
          isEdit: true,
          isDrawerVisible,
          isItemModalVisible
        });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getItems = () => {
    const { location } = this.props;
    const { searchCategory, searchText, searchStatus } = this.state;

    this.loader(true);
    this.props
      .getItems(get(location, 'id'))
      .then((result) => {
        this.loader(false);
        this.setState({ data: result });
        this.onSearch(searchText, searchCategory, searchStatus);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  loader = (loading) => this.setState({ loading });

  updatedata = (itemObj, id = null) => {
    this.loader(true);

    if (isNull(id)) {
      const { location } = this.props;
      itemObj = { ...itemObj, location: get(location, 'id') };

      this.props
        .createItem(itemObj)
        .then(() => {
          message.success(ADD_ITEM_SUCCESS);
          this.setState({ isDrawerVisible: false });
          this.getItems();
        })
        .catch(() => {
          this.loader(false);
        });
    } else {
      const { location } = this.props;
      itemObj = { ...itemObj, location: get(location, 'id') };
      this.loader(true);

      this.props
        .updateItem(id, itemObj)
        .then(() => {
          message.success(UPDATE_ITEM_SUCCESS);
          this.setState({ isDrawerVisible: false });
          this.getItems();
        })
        .catch(() => {
          this.loader(false);
        });
    }
  };

  updateItemStatus = (payload, id) => {
    this.loader(true);

    this.props
      .updateStatus(payload, id)
      .then(() => {
        this.loader(false);
        this.getItems();
      })
      .catch(() => {
        this.loader(false);
      });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      details: null
    });
  };

  // editItem = (record) => {

  //     this.setState({ isEdit: true, details: record, isDrawerVisible: true, isItemModalVisible: false, itemDetail: null });
  // }

  updateDataSource = (data) => {
    this.setState({ data });
  };

  sortItem = (oldIndex, newIndex) => {
    const { filterData } = this.state;
    const { location } = this.props;
    const item = filterData[oldIndex];
    const updateObj = {
      oldIndex: get(filterData[oldIndex], 'index'),
      newIndex: get(filterData[newIndex], 'index'),
      item: get(item, 'id'),
      location: get(location, 'id')
    };

    this.loader(true);

    this.props
      .sortItem(updateObj)
      .then(() => {
        this.loader(false);
        message.success(ITEM_SORT_SUCCESS);
        this.getItems();
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getExpandableDetails = (record) => {
    return (
      <>
        <Row style={{ margin: 10 }}>
          <Col xs={4} xl={4}>
            {' '}
            <Text strong> Category : </Text>{' '}
          </Col>
          <Col xs={20} xl={20}>
            {' '}
            {map(get(record, 'category', []), (val) => (
              <Tag color="blue">{val}</Tag>
            ))}{' '}
          </Col>
        </Row>
        <Row style={{ margin: 10 }}>
          <Col xs={4} xl={4}>
            {' '}
            <Text strong> Description : </Text>{' '}
          </Col>
          <Col xs={20} xl={20}>
            {' '}
            {get(record, 'description', '--')}
          </Col>
        </Row>
      </>
    );
  };

  onSearch = (searchText = '', searchCategory = [], searchStatus = []) => {
    const { data } = this.state;

    const filterData = filter(data, (o) => {
      let result1 = true;
      result1 = includes(o.name.toLowerCase(), searchText.toLowerCase());

      let result2 = true;

      if (searchCategory.length) {
        let c = null;
        c = find(get(o, 'menu_categories'), (j) => {
          return searchCategory.includes(get(j, 'name'));
        });

        if (c) {
          result2 = true;
        } else {
          result2 = false;
        }
      }
      let result3 = true;
      if (searchStatus.length) {
        result3 = searchStatus.includes(get(o, 'status'));
      }
      return result1 && result2 && result3;
    });
    this.setState({ filterData, searchText, searchCategory, searchStatus });
  };

  render() {
    const {
      isDrawerVisible,
      loading,
      isItemModalVisible,
      categories,
      isEdit,
      details,
      filterData,
      addons,
      isCategoryModalVisible,
      selectedCategory,
      selectedAddons,
      isAddonModalVisible,
      searchText,
      searchCategory,
      searchStatus
    } = this.state;

    const { location } = this.props;

    const currency = get(this.props, 'location.currency.sign');

    const columns = [
      {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: 100,
        render: (image, record) => {
          return (
            <img
              alt={record.name}
              src={Helpers.getImageUrl(image)}
              style={{ width: 60, height: 60, objectFit: 'cover' }}
            />
          );
        }
      },
      {
        title: '',
        dataIndex: 'featured',
        align: 'center',
        key: 'featured',

        render: (text, record) => {
          return text ? (
            <FontAwesomeIcon size="70px" icon={faStar} style={{ color: 'gold' }} />
          ) : (
            <FontAwesomeIcon
              size="70px"
              icon={faStarRegular}
              style={{ color: 'gold' }}
            />
          );
        }
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',

        className: 'drag-visible'
      },
      {
        title: `Price (${currency})`,
        dataIndex: 'item_prices',
        width: 150,
        key: 'item_prices',
        render: (priceArr, record) => {
          if (get(record, 'has_variable_price')) {
            const min = minBy(priceArr, 'price');
            const max = maxBy(priceArr, 'price');
            return `${get(min, 'price')} -${get(max, 'price')}`;
          }
          return `${get(head(priceArr), 'price')}`;
        }
      },

      {
        title: `Sale (${currency})`,
        key: 'total_sale',
        render: (record) => {
          return `${sumBy(get(record, 'sales'), 'sale_amount')}`;
        }
      },
      {
        title: 'Categories',
        dataIndex: 'menu_categories',

        key: 'menu_categories',
        render: (categoryArr, record, index) => {
          return (
            <>
              {categoryArr.map((c, i) => (
                <Tag
                  style={{ margin: 5 }}
                  color={colours[get(c, 'id') % coloursLength]}
                >
                  {c.name}
                </Tag>
              ))}
            </>
          );
        }
      },
      {
        title: 'Add-ons',
        dataIndex: 'addons',
        key: 'addons',
        render: (addonArr, record, index) => {
          return (
            <>
              {addonArr.map((a) => (
                <Tag
                  style={{ margin: 5 }}
                  color={colours[get(a, 'id') % coloursLength]}
                >
                  {a.name}
                </Tag>
              ))}
            </>
          );
        }
      },

      {
        title: 'Taxes',
        dataIndex: 'charges_and_taxes',
        key: 'charges_and_taxes',
        render: (taxArr, record, index) => {
          return (
            <>
              {taxArr.map((a) => (
                <Tag
                  style={{ margin: 5 }}
                  color={colours[get(a, 'id') % coloursLength]}
                >
                  {a.name}
                </Tag>
              ))}
            </>
          );
        }
      },

      {
        title: 'Enabled for order',
        dataIndex: 'status',
        key: 'status',
        render: (status, record, index) => {
          return Helpers.getEnabledForOrderStatus(status);
        }
      },

      {
        title: '',
        dataIndex: 'is_item_veg',
        key: 'is_item_veg',
        render: (isVeg) => {
          return Helpers.getItemType(isVeg);
        }
      },

      {
        title: '',
        key: 'details',
        width: 150,
        render: (record) => {
          return (
            <div>
              <Tooltip placement="top" title="View item details">
                <FontAwesomeIcon
                  className="table-action-icon"
                  icon={faEye}
                  onClick={() => {
                    this.setState({
                      details: record,
                      isEdit: true,
                      isItemModalVisible: true
                    });
                  }}
                />

                {/* <Icon
                  onClick={() => {
                    this.setState({
                      details: record,
                      isEdit: true,
                      isItemModalVisible: true
                    });
                  }}
                  type="eye"
                  theme="twoTone"
                  style={{ fontSize: 20, margin: 10 }}
                /> */}
              </Tooltip>
              <Tooltip placement="top" title="Edit item">
                <FontAwesomeIcon
                  icon={faPen}
                  className="table-action-icon"
                  onClick={() => {
                    this.setState({
                      details: record,
                      isDrawerVisible: true,
                      isEdit: true
                    });
                  }}
                />

                {/* <Icon
                  onClick={() => {
                    this.setState({
                      details: record,
                      isDrawerVisible: true,
                      isEdit: true
                    });
                  }}
                  type="edit"
                  style={{ fontSize: 20, margin: 10 }}
                  theme="twoTone"
                /> */}
              </Tooltip>
            </div>
          );
        }
      }
    ];

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <Row>
              <Col xl={3}>
                <h4 className="text-uppercase">Items</h4>
              </Col>
              <Col
                xl={6}
                style={{
                  textAlign: 'right',
                  marginRight: 10
                }}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  value={searchCategory}
                  onChange={(val) => {
                    this.onSearch(searchText, val, searchStatus);
                  }}
                  placeholder="Category"
                >
                  {categories.map((category) => {
                    return (
                      <Option
                        value={get(category, 'name')}
                        label={get(category, 'name')}
                        key={get(category, 'name')}
                      >
                        {get(category, 'name')}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col xl={6}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  value={searchStatus}
                  onChange={(val) => {
                    this.onSearch(searchText, searchCategory, val);
                  }}
                  placeholder="Enabled for order"
                >
                  {map(visibleStatus, (obj) => {
                    return (
                      <Option
                        value={get(obj, 'value')}
                        label={get(obj, 'label')}
                        key={get(obj, 'value')}
                      >
                        {get(obj, 'label')}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col
                xl={4}
                style={{
                  textAlign: 'right',
                  paddingLeft: 10
                }}
              >
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  style={{ minWidth: 20 }}
                  suffix={
                    searchText ? (
                      <Icon
                        onClick={() => {
                          this.onSearch('', searchCategory, searchStatus);
                        }}
                        type="close-circle"
                        style={{ fontSize: 15 }}
                      />
                    ) : (
                      <Icon
                        onClick={() => {
                          this.onSearch('', searchCategory, searchStatus);
                        }}
                        type="search"
                        style={{ fontSize: 15 }}
                      />
                    )
                  }
                  onChange={(e) => {
                    this.onSearch(e.target.value, searchCategory, searchStatus);
                  }}
                />
              </Col>
              <Col
                xl={4}
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  type="primary"
                  onClick={this.toggleDrawer}
                  className="btn-secondary btn-margin"
                >
                  <Icon className="btn-icon" type="plus" /> Add Item
                </Button>
              </Col>
            </Row>

            <SortableTable
              small
              updateDataSource={this.updateDataSource}
              columns={columns}
              dataSource={filterData}
              getExpandableDetails={this.getExpandableDetails}
              sortData={this.sortItem}
            />
          </div>

          <Drawer
            title={isEdit ? 'Update Item ' : 'Add Item'}
            destroyOnClose
            location={location}
            width={window.innerWidth < 500 ? window.innerWidth * 0.75 : 700}
            onClose={this.toggleDrawer}
            visible={isDrawerVisible}
            footer={<></>}
          >
            <AddItemsForm
              details={details}
              isEdit={isEdit}
              addons={addons}
              updatedata={this.updatedata}
            />
          </Drawer>

          <Modal
            title="Item"
            width={window.innerWidth * 0.6}
            destroyOnClose
            onCancel={() => {
              this.setState({
                isItemModalVisible: false,
                details: null,
                isDrawerVisible: false
              });
            }}
            visible={isItemModalVisible}
            footer={<></>}
          >
            <ItemDetails
              editItem={() => {
                this.setState({
                  isItemModalVisible: false,
                  isDrawerVisible: true
                });
              }}
              itemDetail={details}
            />
          </Modal>

          <Modal
            title="Category"
            width={window.innerWidth * 0.3}
            destroyOnClose
            onCancel={() => {
              this.setState({
                isCategoryModalVisible: false,
                selectedCategory: []
              });
            }}
            visible={isCategoryModalVisible}
            footer={false}
          >
            <Table
              showHeader={false}
              pagination={false}
              columns={[
                {
                  title: 'Category',
                  dataIndex: 'name',
                  align: 'center',
                  key: 'category'
                }
              ]}
              dataSource={selectedCategory}
            />
          </Modal>

          <Modal
            title="Addons"
            width={window.innerWidth * 0.3}
            destroyOnClose
            onCancel={() => {
              this.setState({
                isAddonModalVisible: false,
                selectedAddons: []
              });
            }}
            visible={isAddonModalVisible}
            footer={false}
          >
            <Table
              showHeader={false}
              pagination={false}
              columns={[
                {
                  title: 'addon',
                  dataIndex: 'name',
                  align: 'center',
                  key: 'addon'
                }
              ]}
              dataSource={selectedAddons}
            />
          </Modal>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getCategories,
    sortItem,
    getItems,
    createItem,
    updateItem,
    updateStatus,
    getSingleItem,
    getAddons
  })(ItemScreen)
);
