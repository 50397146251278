import * as APIS from '../../constants/apiEndpoints';

export const getServiceCharge = (location) => (dispatch, getState, { api }) => {
  const url = APIS.CHARGES.replace('{id}', location);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const createUpdateServiceCharge = (payload) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.CREATE_CHARGES;

  return api
    .post(url, payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};
