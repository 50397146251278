import get from 'lodash/get';
import head from 'lodash/head';
import * as TYPES from '../../constants/actionTypes';

export default (
  state = {
    current_location: null,
    all_location: []
  },
  action
) => {
  switch (action.type) {
    case TYPES.UPDATE_LOCATION:
      return {
        ...state,
        current_location: head(action.data),
        all_location: action.data
      };

    case TYPES.UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        current_location: get(action, 'data.current'),
        all_location: get(action, 'data.allLocations')
      };

    case TYPES.REFRESH_LOCATION:
      return {
        ...state,
        current_location: get(action, 'data.location')
      };

    case TYPES.FLUSH_LOCATION:
      return {
        ...state,
        current_location: null,
        all_location: []
      };

    default:
      return state;
  }
};
