import React, { Component } from 'react';
import {
  Row,
  Empty,
  Icon,
  Drawer,
  Button,
  Tooltip,
  Col,
  Table,
  message,
  Spin
} from 'antd';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import AddChargesAndTaxesForm from '../../components/Business/AddChargesAndTaxesForm';
import {
  ADD_CHARGES_AND_TAXES_SUCCESS,
  UPDATE_CHARGES_AND_TAXES_SUCCESS
} from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import {
  getTaxAndCharges,
  updateTaxAndCharges,
  createTaxAndCharges,
  getTaxAndChargesCount
} from '../../redux/actions/chargeAndTaxesAction';

class ChargesAndTaxesScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isEdit: false,
      total: null,
      current: 1,
      limit: 10,
      details: null,
      location: null,
      data: []
    };
  }

  componentDidMount() {
    this.setState({ location: get(this.props, 'location') });
    this.getInitial();
  }

  getInitial = () => {
    this.getTaxAndCharges();
    this.getCountTaxAndCharges();
  };

  loader = (loading) => this.setState({ loading });

  updatedata = (chargeAndTaxObj, index = null) => {
    const { location } = this.state;

    this.loader(true);

    if (isNull(index)) {
      chargeAndTaxObj = { ...chargeAndTaxObj, location: get(location, 'id') };
      this.props
        .createTaxAndCharges(chargeAndTaxObj)
        .then(() => {
          message.success(ADD_CHARGES_AND_TAXES_SUCCESS);
          this.setState({ isDrawerVisible: false });
          this.getInitial();
        })
        .catch(() => {
          this.loader(false);
        });
    } else {
      const { details } = this.state;
      chargeAndTaxObj = { ...chargeAndTaxObj, location: get(location, 'id') };

      this.props
        .updateTaxAndCharges(get(details, 'id'), chargeAndTaxObj)
        .then(() => {
          message.success(UPDATE_CHARGES_AND_TAXES_SUCCESS);
          this.setState({ isDrawerVisible: false, details: null });
          this.getInitial();
        })
        .catch(() => {
          this.loader(false);
        });
    }
  };

  getCountTaxAndCharges = () => {
    const { location } = this.props;
    this.loader(true);

    this.props
      .getTaxAndChargesCount(get(location, 'id'))
      .then((total) => {
        this.loader(false);
        this.setState({ total });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getTaxAndCharges = (current = 1) => {
    const { limit } = this.state;
    const { location } = this.props;

    this.loader(true);

    this.props
      .getTaxAndCharges(get(location, 'id'), current, limit, true)
      .then((result) => {
        const newResult = [];
        result.forEach((element) => {
          if (element.unit === 'Percent') element.value = `${element.amount}%`;
          else
            element.value = `${get(this.props, 'location.currency.sign')}${
              element.amount
            }`;
          newResult.push(element);
        });
        this.loader(false);
        this.setState({ data: newResult, current });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      details: null
    });
  };

  editService = (record) => {
    this.setState({ isEdit: true, details: record, isDrawerVisible: true });
  };

  render() {
    const {
      current,
      isDrawerVisible,
      loading,
      data,
      isEdit,
      details,
      total,
      limit
    } = this.state;

    const BusinessColumn = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value'
      },
      // {
      //   title: 'Amount',
      //   dataIndex: 'amount',
      //   key: 'amount'
      // },
      // {
      //   title: 'Unit',
      //   dataIndex: 'unit',
      //   key: 'unit'
      // },
      // {
      //   title: 'Type',
      //   dataIndex: 'type',
      //   key: 'type'
      // },
      {
        title: 'Action',
        render: (text, record) => {
          return (
            <Tooltip placement="top" title="Edit">
              <FontAwesomeIcon
                className="table-action-icon"
                icon={faPen}
                onClick={() => {
                  this.editService(record);
                }}
              />

              {/* <Icon
                onClick={() => {
                  this.editService(record);
                }}
                type="edit"
                style={{ fontSize: 20 }}
                theme="twoTone"
              /> */}
            </Tooltip>
          );
        }
      }
    ];

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <Row>
              <Col span={12}>
                <h4 className="text-uppercase">Taxes</h4>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  type="primary"
                  onClick={this.toggleDrawer}
                  className="btn-secondary btn-margin"
                >
                  <Icon className="btn-icon" type="plus" /> Add Taxes
                </Button>
              </Col>
            </Row>

            <Table
              scroll={{ x: 800 }}
              size="small"
              columns={BusinessColumn}
              dataSource={data}
              pagination={{
                defaultPageSize: limit,
                current,
                total,
                onChange: (val) => {
                  this.getTaxAndCharges(val);
                }
              }}
              locale={{
                emptyText: <Empty description="No charges and taxes exists" />
              }}
            />
          </div>

          <Drawer
            title={isEdit ? 'Update Tax' : 'Add Tax'}
            width={window.innerWidth < 500 ? window.innerWidth * 0.6 : 350}
            destroyOnClose
            onClose={this.toggleDrawer}
            visible={isDrawerVisible}
          >
            <AddChargesAndTaxesForm
              details={details}
              isEdit={isEdit}
              updatedata={this.updatedata}
            />
          </Drawer>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getTaxAndCharges,
    updateTaxAndCharges,
    createTaxAndCharges,
    getTaxAndChargesCount
  })(ChargesAndTaxesScreen)
);
