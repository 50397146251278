import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getItems = (location, page = 1, limit = 10) => (
  dispatch,
  getState,
  { api }
) => {
  const url = Helpers.getUrl(APIS.ITEM, {
    // _limit: limit,
    // _start: start,
    location,
    _sort: 'index:ASC'
  });

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSingleItem = (id) => (dispatch, getState, { api }) => {
  const url = APIS.SINGLE_ITEM.replace('{id}', id);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getItemsCount = (location) => (dispatch, getState, { api }) => {
  const url = Helpers.getUrl(APIS.ITEM_COUNT, {
    location
  });

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createItem = (categoryObj) => (dispatch, getState, { api }) => {
  return api
    .post(APIS.ITEM, categoryObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateItem = (id, categoryObj) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.UPDATE_ITEM.replace('{id}', id);

  return api
    .put(url, categoryObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateStatus = (obj, id) => (dispatch, getState, { api }) => {
  const url = APIS.UPDATE_ITEM.replace('{id}', id);

  return api
    .put(url, obj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const sortItem = (itemObj) => (dispatch, getState, { api }) => {
  return api
    .put(APIS.SORT_ITEM, itemObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
