import React from 'react';
import { Row, Col, Typography, Tooltip, Table, Alert, Divider } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import round from 'lodash/round';

import moment from 'moment';
import Helpers from '../../utilities/helpers';

const { Text } = Typography;

export default class OrderDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      taxName: [],
      taxAmount: [],
      subtotal: 0
    };
  }

  componentDidMount() {
    const { orderDetails } = this.props;
    const { subtotal, taxName, taxAmount } = Helpers.getCumilativeTaxAmount(
      get(orderDetails, 'order_items')
    );
    this.setState({
      taxName,
      taxAmount,
      subtotal
    });
  }

  expandedRow = (record) => {
    const { orderDetails } = this.props;
    const currency = get(orderDetails, 'location.currency.sign');

    const columns = [
      {
        title: 'Name',
        dataIndex: 'addon_item',
        key: 'name',
        render: (text, row, index) => {
          return text;
        }
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        width: 120,
        render: (text, row, index) => {
          return text ? `${currency} ${text.toFixed(2)}` : null;
        }
      }
      // {
      //   title: 'tax',
      //   dataIndex: 'tax_amount',
      //   key: 'tax_amount',
      //   align: 'right',
      //   render: (text, row, index) => {
      //     return text ? text.toFixed(2) : null;
      //   }
      // },
      // {
      //   title: 'total',
      //   dataIndex: 'total',
      //   align: 'right',
      //   key: 'total',
      //   render: (text, data, index) => {
      //     return (get(data, 'price') + get(data, 'tax_amount')).toFixed(2);
      //   }
      // }
    ];

    return get(record, 'order_addons', []).length > 0 ? (
      <Table
        columns={columns}
        showHeader={false}
        size="small"
        className="addonDetails"
        pagination={false}
        dataSource={get(record, 'order_addons', [])}
      />
    ) : null;
  };

  render() {
    const { orderDetails } = this.props;
    const { taxName, taxAmount, subtotal } = this.state;
    const currency = get(orderDetails, 'location.currency.sign');

    const columns = [
      {
        title: 'Item',
        dataIndex: 'menu_item.name',
        key: 'item_name',
        render: (rext, record, index) => {
          return (
            <>
              {get(record, 'menu_item.has_variable_price')
                ? `${get(record, 'menu_item.name')} (${get(
                    record,
                    'item_price.type'
                  )})`
                : `${get(record, 'menu_item.name')}`}

              {get(record, 'instructions', null) && (
                <div>
                  <Text mark>{`${get(record, 'instructions')}`}</Text>
                </div>
              )}
            </>
          );
        }
      },
      {
        title: 'Qty',
        dataIndex: 'menu_item.quantity',
        key: 'quantity',
        align: 'right',
        width: 50,
        render: (rext, record, index) => {
          return get(record, 'quantity');
        }
      },
      {
        title: 'Price',
        key: 'quantity',
        width: 120,
        align: 'right',
        render: (text, record, index) => {
          return `${currency} ${get(record, 'unit_price').toFixed(2)}`;
        }
      },
      // {
      //   title: 'Tax',
      //   dataIndex: 'tax_amount',
      //   key: 'tax_amount',
      //   align: 'right',
      //   width: 120,
      //   render: (text, record, index) => {
      //     return `${currency} ${get(record, 'tax_amount').toFixed(2)}`;
      //   }
      // },
      {
        title: 'Amount',
        dataIndex: 'item_amount',
        align: 'right',
        key: 'item_amount',
        width: 120,
        render: (text, record, index) => {
          return `${currency} ${get(record, 'item_amount').toFixed(2)}`;
        }
      }
    ];

    return (
      <Row style={{ padding: 5 }}>
        <Row>
          <Col xs={24} xl={6}>
            <Text strong>CUSTOMER</Text>
            <div>
              <Text>{get(orderDetails, 'customer.name')}</Text>
            </div>
            <div>
              <Text>{get(orderDetails, 'customer.phone_number')}</Text>
            </div>
            <div>
              <Text>{get(orderDetails, 'customer.email')}</Text>
            </div>
            <div>
              {get(orderDetails, 'service_type') === 'Dine' && (
                <Text>Table ID: {get(orderDetails, 'table_id')}</Text>
              )}
            </div>
          </Col>

          {get(orderDetails, 'service_type') === 'Delivery' && (
            <Col xs={24} xl={6}>
              <Text strong>ADDRESS</Text>

              <div>
                <Text>{get(orderDetails, 'delivery_details.address_1', '--')}</Text>
              </div>
              <div>
                <Text>{get(orderDetails, 'delivery_details.address_2', '--')}</Text>
              </div>
              <div>
                <Text>{get(orderDetails, 'delivery_details.city', '--')}</Text>
              </div>
              <div>
                <Text>{get(orderDetails, 'delivery_details.zip_code', '--')}</Text>
              </div>
            </Col>
          )}

          <Col xs={24} xl={8}>
            <Text strong>ORDER: {get(orderDetails, 'order_id')} </Text>

            <div>
              <Text>
                Placed{' '}
                {
                  <Tooltip
                    placement="top"
                    title={moment(get(orderDetails, 'created_at')).format(
                      'DD MMM hh:mm a'
                    )}
                  >
                    <strong>
                      {moment(get(orderDetails, 'created_at')).fromNow()}
                    </strong>
                  </Tooltip>
                }
              </Text>
            </div>
            <div>
              <Text>
                To be served at{' '}
                <strong>
                  {moment(get(orderDetails, 'service_date')).format('D MMM hh:mm a')}
                </strong>
              </Text>
            </div>
            <div>
              {Helpers.getServiceTypeTag(
                get(orderDetails, 'service_type'),
                orderDetails
              )}
              {Helpers.getOrderStatusTag(get(orderDetails, 'status'))}
            </div>
          </Col>

          <Col xs={24} xl={4}>
            <Text strong>PAYMENT</Text>
            <div>
              Mode:{' '}
              {get(orderDetails, 'paid_status') !== 'Paid'
                ? '--'
                : get(orderDetails, 'gateway_order_id')
                ? 'Online'
                : 'Cash'}
            </div>
            <div>
              Status: {Helpers.getOrderPaidStatus(get(orderDetails, 'paid_status'))}
            </div>
          </Col>
        </Row>

        {get(orderDetails, 'delivery_details.delivery_instruction', null) && (
          <Row type="flex" style={{ marginTop: 20 }}>
            <Col xs={24} xl={24}>
              <Text mark>
                INSTRUCTIONS:{' '}
                {get(orderDetails, 'delivery_details.delivery_instruction', '--')}
              </Text>
            </Col>
          </Row>
        )}

        {get(orderDetails, 'note', '') && (
          <Row type="flex" style={{ marginTop: 20 }}>
            <Text strong>Note :</Text>
            <div>
              <Alert
                message={`${
                  get(orderDetails, 'note') ? get(orderDetails, 'note') : '--'
                }`}
              />
            </div>
          </Row>
        )}

        <Divider style={{ marginTop: 20 }}>ORDER DETAILS</Divider>

        <Table
          pagination={false}
          size="small"
          // scroll={{ x: 500 }}
          defaultExpandAllRows
          columns={columns}
          rowExpandable={(record) => false}
          expandedRowRender={this.expandedRow}
          dataSource={get(orderDetails, 'order_items', [])}
        />

        <Row>
          <Col xl={24}>
            <Row type="flex" justify="end">
              <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                <Text strong>Sub Total:</Text>
              </Col>
              <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                {subtotal.toFixed(2)}
              </Col>
            </Row>

            {map(taxAmount, (amount, index) => {
              return (
                <Row type="flex" justify="end">
                  <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                    <Text strong>{taxName[index]} :</Text>
                  </Col>
                  <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                    {amount.toFixed(2)}
                  </Col>
                </Row>
              );
            })}

            <Row type="flex" justify="end">
              <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                <Text strong>Charges:</Text>
              </Col>
              <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                {get(orderDetails, 'order_charge.amount', 0).toFixed(2)}
              </Col>
            </Row>

            <Row type="flex" justify="end">
              <Col xs={12} xl={8} style={{ textAlign: 'right' }}>
                <Text strong>Round Off:</Text>
              </Col>
              <Col xs={12} xl={8} style={{ textAlign: 'right' }}>
                {get(orderDetails, 'round_of_diffrence')
                  ? get(orderDetails, 'round_of_diffrence').toFixed(2)
                  : (0).toFixed(2)}
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                <Text strong>Total Amount:</Text>
              </Col>
              <Col xs={24} xl={8} style={{ textAlign: 'right' }}>
                {round(get(orderDetails, 'total_amount'), 2).toFixed(2)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    );
  }
}
