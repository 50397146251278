import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getReservations = (location, page = 1, limit = 10) => (
  dispatch,
  getState,
  { api }
) => {
  const start = (page - 1) * limit;

  const url = Helpers.getUrl(APIS.RESERVATION, {
    _limit: limit,
    _start: start,
    location,
    _sort: 'created_at:DESC'
  });

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getReservationsCount = (location) => (
  dispatch,
  getState,
  { api }
) => {
  const url = Helpers.getUrl(APIS.RESERVATION_COUNT, {
    location
  });

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createReservation = (reservationObj) => (
  dispatch,
  getState,
  { api }
) => {
  return api
    .post(APIS.RESERVATION, reservationObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateReservation = (id, reservationObj) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.UPDATE_RESERVATION.replace('{id}', id);

  return api
    .put(url, reservationObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
