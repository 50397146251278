import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import requireAuth from '../../hocs/requireAuth';
import { updateLocation } from '../../redux/actions/locationAction';
import StoreMessagesForm from '../../components/Business/StoreMessagesForm';
import { UPDATE_STORE_MESSAGE_SUCCESS } from '../../constants/messages';
import { getCategories } from '../../redux/actions/categoryAction';

class StoreMessagesScreen extends Component {
  constructor() {
    super();
    this.state = {
      details: null,
      loading: false,
      categories: []
    };
  }

  componentWillMount() {
    const details = get(this.props, 'location');
    this.setState({ details });
  }

  componentDidMount() {
    this.getCategories();
  }
  getCategories = () => {
    let location = get(this.props, 'location');
    this.props
      .getCategories(get(location, 'id'))
      .then((result) => {
        this.loader(false);
        this.setState({ categories: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ details: get(nextProps, 'location') });
  }
  loader = (loading) => this.setState({ loading });

  updatedata = (payload, index = null) => {
    const { details } = this.state;
    this.loader(true);
    this.props
      .updateLocation(payload, details)
      .then(() => {
        this.loader(false);
        message.success(UPDATE_STORE_MESSAGE_SUCCESS);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  render() {
    const { details, loading, categories } = this.state;
    console.log({ categories });
    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">Store Messages</h4>}
            >
              <StoreMessagesForm
                categories={categories}
                details={details}
                updatedata={this.updatedata}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { updateLocation, getCategories })(StoreMessagesScreen)
);
