/**
 * Constants
 */
import axios from 'axios';
import get from 'lodash/get';
import { notification } from 'antd';
import * as APIS from '../../constants/apiEndpoints';

export const getMediaUrl = ({ onSuccess, onError, file, onProgress }) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.GET_MEDIA_PRESIGNED_URL.replace('{content_type}', file.type);

  return api.get(url).then((result) => {
    const putURL = get(result, 'preSignUrl');

    const fileName = get(result, 'key');

    const newFile = new File([file], fileName, { type: file.type });
    const date = new Date();

    return axios
      .put(putURL, newFile, {
        headers: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': file.type,
          Expires: `${date.getFullYear() +
            10}-${date.getMonth()}-${date.getDate()}`
        }
      })
      .then(() => {
        return {
          key: get(result, 'key'),
          imageUrl: get(result, 'getImageUrl')
        };
      })
      .catch((error) => {
        notification.error({
          message: 'Some error occurr please try again'
        });
      });
  });
};
