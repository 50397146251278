import React from 'react';
import { Form, Row, Col, Icon, Button, TimePicker } from 'antd';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import get from 'lodash/get';

const format = 'HH:mm';

class DynamicHoursForm extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0
    };
  }

  remove = (k) => {
    const { form } = this.props;
    const { id } = this.state;

    const keys = form.getFieldValue('keys');

    form.setFieldsValue({
      keys: keys.filter((key) => key !== k)
    });

    this.setState({ id: id - 1 });
  };

  add = () => {
    const { form } = this.props;
    let { id } = this.state;
    const keys = form.getFieldValue('keys');
    id += 1;

    const nextKeys = keys.concat(id);

    form.setFieldsValue({
      keys: nextKeys
    });
    this.setState({ id });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { form, details, type } = this.props;
        const { times } = values;
        const finalTimes = remove(times, (val) => !isEmpty(val));
        this.props.updatedata(finalTimes, get(details, 'label'), type);
        form.setFieldsValue({
          keys: []
        });
        this.setState({ id: 0 });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');

    return (
      <Form onSubmit={this.handleSubmit}>
        {keys.map((k, index) => (
          <Row type="flex" justify="center" key={`row${index + 1}`}>
            <Col xs={8} xl={8}>
              <Form.Item key={`from${index + 1}`}>
                {getFieldDecorator(`times[${k}]['from']`, {
                  rules: [
                    {
                      required: true,
                      message: 'Please input the start time'
                    }
                  ]
                })(
                  <TimePicker
                    format={format}
                    minuteStep={30}
                    defaultOpenValue={moment('09:00', 'HH:mm:ss')}
                  />
                )}
              </Form.Item>
            </Col>

            <Col xs={8} xl={8}>
              <Form.Item key={`to${index + 1}`}>
                {getFieldDecorator(`times[${k}]['to']`, {
                  rules: [
                    {
                      required: true,
                      message: 'Please input the end time'
                    }
                  ]
                })(
                  <TimePicker
                    format={format}
                    minuteStep={30}
                    defaultOpenValue={moment('21:00', 'HH:mm')}
                  />
                )}
              </Form.Item>
            </Col>

            <Col style={{ marginTop: 10 }} xs={2} xl={2}>
              {keys.length ? (
                <Icon
                  className="table-action-icon"
                  // className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => this.remove(k)}
                />
              ) : null}
            </Col>

            <Col style={{ marginTop: 10 }} xs={2} xl={2}>
              {keys.length > 0 && (
                <Button type="primary" htmlType="submit">
                  Save Changes
                </Button>
              )}
            </Col>
          </Row>
        ))}

        {keys.length === 0 && (
          <Row type="flex" justify="center">
            <Col xs={24} xl={12}>
              <Form.Item>
                <Button type="dashed" onClick={this.add}>
                  <Icon type="plus" /> Add time slot
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* <Row type='flex' justify='end'>
                    <Col xs={0} xl={5}>

                    </Col>
                    <Col xs={24} xl={5}>

                        <Form.Item >
                            { ((keys.length) > 0) && <Button type="primary" htmlType="submit">
                                Save
                            </Button> }
                        </Form.Item>
                    </Col>
                            </Row> */}
      </Form>
    );
  }
}
export default Form.create({ name: 'time_form' })(DynamicHoursForm);
