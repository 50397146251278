import React from 'react';
import { Form, Row, Col, Input, Button, Icon, Popconfirm } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import Helpers from '../../utilities/helpers';
import { ZIP_CODE } from '../../constants/regex';

class AddServiceableLocationForm extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0
    };
  }

  componentWillReceiveProps(nextProps) {
    const { serviceableLocations } = nextProps;
    if (
      nextProps.serviceableLocations.length !==
      this.props.serviceableLocations.length
    ) {
      this.setState({ id: serviceableLocations.length - 1 });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const payload = map(values.keys, (k) => {
          let obj = null;
          if (values.id[k]) {
            obj = {
              id: values.id[k],
              zip_code: values.zip_code[k],
              area_name: values.area_name[k]
            };
          } else {
            obj = {
              zip_code: values.zip_code[k],
              area_name: values.area_name[k]
            };
          }
          return obj;
        });

        this.props.updatedata(payload);
      }
    });
  };

  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k)
    });
  };

  add = () => {
    let { id } = this.state;
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    id += 1;
    const nextKeys = keys.concat(id);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });
    this.setState({
      id
    });
  };

  validateZipCode = (rule, value, cb) => {
    if (Helpers.compareRegex(value, ZIP_CODE)) {
      cb();
    } else {
      cb('Pincode that you enter is invalid!');
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const { serviceableLocations } = this.props;

    getFieldDecorator('keys', {
      initialValue:
        serviceableLocations.length > 0
          ? map(serviceableLocations, (val, i) => i)
          : [0]
    });
    const keys = getFieldValue('keys');

    return (
      <Form
        onSubmit={this.handleSubmit}
        layout="vertical"
        labelCol={{
          xs: { span: 22 },
          xl: { span: 22 },
          sm: { span: 22 }
        }}
        wrapperCol={{
          xs: { span: 22 },
          xl: { span: 22 },
          sm: { span: 22 }
        }}
      >
        <Row>
          <Col xs={2} xl={2}>
            Pincode
          </Col>
          <Col xs={10} xl={10}>
            Area Name
          </Col>
        </Row>
        {keys.map((k, index) => {
          return (
            <Row key={`location_${k}`}>
              <Col xs={2} xl={2}>
                <Form.Item>
                  {getFieldDecorator(`zip_code[${k}]`, {
                    initialValue:
                      serviceableLocations.length > 0
                        ? get(serviceableLocations[k], 'zip_code')
                        : null,
                    rules: [
                      {
                        required: true,
                        message: 'Please input Pincode field!'
                      },
                      { validator: this.validateZipCode }
                    ]
                  })(<Input size="default" placeholder="452556" />)}
                </Form.Item>
              </Col>
              <Col xs={10} xl={10}>
                <Form.Item>
                  {getFieldDecorator(`area_name[${k}]`, {
                    initialValue:
                      serviceableLocations.length > 0
                        ? get(serviceableLocations[k], 'area_name')
                        : null,
                    rules: [
                      {
                        required: true,
                        message: 'Please input Area name field!'
                      }
                    ]
                  })(
                    <Input size="default" placeholder="Mahatma Gandhi Road" />
                  )}
                </Form.Item>
              </Col>
              <Col
                xs={1}
                xl={1}
                style={{
                  paddingTop: '5px'
                }}
              >
                {keys.length > 1 && (
                  <Popconfirm
                    title="Are you sure you want to delete this serviceable location"
                    onConfirm={() => {
                      this.remove(k);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <FontAwesomeIcon
                      className="table-action-icon"
                      icon={faTrash}
                    />
                  </Popconfirm>
                )}
              </Col>
              <Col hidden>
                <Form.Item>
                  {getFieldDecorator(`id[${k}]`, {
                    initialValue:
                      serviceableLocations.length > 0
                        ? get(serviceableLocations[k], 'id')
                        : null
                  })(<Input type="hidden" />)}
                </Form.Item>
              </Col>
            </Row>
          );
        })}

        <Row>
          <Col xs={9} xl={9}>
            <Form.Item>
              <Button type="dashed" onClick={this.add}>
                <Icon type="plus" /> Add More
              </Button>
            </Form.Item>
          </Col>
          <Col xs={4} xl={4}>
            <Button type="primary" htmlType="submit" className="btn-secondary">
              Save Changes
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'serviceable_location' })(
  AddServiceableLocationForm
);
