import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Drawer,
  Icon,
  Input,
  message,
  Row,
  Spin,
  Table,
  Timeline,
  Tooltip
} from 'antd';
import { countBy, debounce, get, isNull, last, map, reverse } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  ADD_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS
} from '../../constants/messages';
import {
  getCustomers,
  createCustomer,
  updateCustomer
} from '../../redux/actions/customerAction';
import Helpers from '../../utilities/helpers';
import AddCustomerForm from '../../components/Customer/AddCustomerForm';

const CustomerScreen = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [details, setDetails] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(20);
  const [data, setData] = useState([]);
  const [isOrderDrawerVisible, setIsOrderDrawerVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const location = useSelector((state) =>
    get(state, 'location.current_location')
  );
  const dispatch = useDispatch();
  const getCustomersFn = useCallback(
    (currentLocal = 1, query = null) => {
      setLoading(true);
      dispatch(getCustomers(get(location, 'id'), currentLocal, limit, query))
        .then((result) => {
          const { customers, count } = result;
          setLoading(false);
          setData(customers);
          setCurrent(currentLocal);
          setTotal(count);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [dispatch, limit, location]
  );
  const updateData = (customerObj, index = null) => {
    if (isNull(index)) {
      customerObj = { ...customerObj, location: get(location, 'id') };

      dispatch(createCustomer(customerObj))
        .then(() => {
          message.success(ADD_CUSTOMER_SUCCESS);
          setIsDrawerVisible(false);
          getCustomersFn();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setData({ id: index, ...customerObj });
      dispatch(updateCustomer(get(details, 'id'), customerObj))
        .then(() => {
          message.success(UPDATE_CUSTOMER_SUCCESS);
          setIsDrawerVisible(false);
          setDetails(null);
          setIsEdit(false);
          getCustomersFn();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
    setIsEdit(false);
    setDetails(null);
  };
  const toggleOrderDrawer = (detailsLocal = null) => {
    setIsOrderDrawerVisible(!isOrderDrawerVisible);
    setIsEdit(false);
    setDetails(detailsLocal);
  };
  const editService = (record) => {
    setIsEdit(true);
    setDetails(record);
    setIsDrawerVisible(true);
  };
  let throttleHandleChange = (query = null) => {
    getCustomersFn(1, query);
  };
  throttleHandleChange = debounce(throttleHandleChange.bind(this), 500);
  const onSearch = (SearchText) => {
    throttleHandleChange(SearchText);
    setSearchText(SearchText);
  };
  useEffect(() => {
    getCustomersFn();
  }, [getCustomersFn]);
  const currency = get(location, 'currency.sign');
  const renderCustomerOrders = (orders) => {
    let previousOrder = [...orders];
    previousOrder = reverse(previousOrder);
    return (
      <Timeline>
        {map(previousOrder, (order) => {
          if (get(order, 'status') === 'Incomplete') {
            return null;
          }
          return (
            <Timeline.Item color="green" key="order_id">
              <p>
                <b>
                  {moment(get(order, 'service_date')).format(
                    'MMM Do YYYY h:mm a'
                  )}{' '}
                  ({get(order, 'order_id')})
                </b>
              </p>
              <p>
                Service type :{' '}
                {Helpers.getServiceTypeTag(get(order, 'service_type'), order)}{' '}
              </p>
              <p>Status : {Helpers.getOrderStatusTag(get(order, 'status'))}</p>
              <p>
                Payment Status :
                {Helpers.getOrderPaidStatus(get(order, 'paid_status'))}{' '}
              </p>
              <p>
                Amount : {currency} {get(order, 'total_amount')}
              </p>
              <p>
                order date :{' '}
                {moment(get(order, 'created_at')).format('MMM Do YYYY h:mm a')}
              </p>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };
  const BusinessColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'customer_name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'left',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      align: 'left',
      key: 'phone_number'
    },
    {
      title: 'Orders Placed',
      align: 'center',
      key: 'total_order_placed',
      render: (record) => {
        return get(
          countBy(get(record, 'orders', []), (o) => o.status !== 'Incomplete'),
          'true'
        );
      }
    },
    {
      title: `Total Orders Amount (In ${currency})`,
      align: 'center',
      key: 'total_order_amount',
      render: (record) => {
        return Helpers.getCustomerTotalOrderAmount(get(record, 'orders'));
      }
    },
    {
      title: 'Last Order Date',
      key: 'last_order_date',
      align: 'center',
      render: (record) => {
        const lastOrder = last(get(record, 'orders', []));
        return lastOrder
          ? moment(get(lastOrder, 'created_at')).format('MMM Do YYYY')
          : '--';
      }
    },
    {
      title: '',
      key: 'edit',
      render: (record) => {
        return (
          <>
            <Tooltip placement="top" title="Edit customer">
              <FontAwesomeIcon
                className="table-action-icon"
                icon={faPen}
                onClick={() => {
                  editService(record);
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="View orders">
              <FontAwesomeIcon
                className="table-action-icon"
                icon={faEye}
                onClick={() => {
                  toggleOrderDrawer(record);
                }}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];
  return (
    <>
      <Spin spinning={loading}>
        <div className="main-container">
          <Row>
            <Col span={14}>
              <h4 className="text-uppercase">Customers</h4>
            </Col>
            <Col
              span={5}
              style={{
                textAlign: 'right'
              }}
            >
              <Input
                type="text"
                placeholder="Search"
                value={searchText}
                style={{ minWidth: 20 }}
                suffix={
                  searchText ? (
                    <Icon
                      onClick={() => {
                        onSearch('');
                      }}
                      type="close-circle"
                      style={{ fontSize: 15 }}
                    />
                  ) : (
                    <Icon
                      onClick={() => {
                        onSearch('');
                      }}
                      type="search"
                      style={{ fontSize: 15 }}
                    />
                  )
                }
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
            </Col>
            <Col
              span={5}
              style={{
                textAlign: 'right'
              }}
            >
              <Button
                type="primary"
                onClick={toggleDrawer}
                className="btn-secondary btn-margin"
              >
                <Icon className="btn-icon" type="plus" /> Add Customer
              </Button>
            </Col>
          </Row>
          <Table
            scroll={{ x: 800 }}
            columns={BusinessColumn}
            size="small"
            dataSource={data}
            pagination={{
              defaultPageSize: limit,
              current,
              total,
              onChange: (val) => {
                getCustomers(val);
              }
            }}
          />
        </div>
        <Drawer
          title="Orders"
          width={window.innerWidth < 500 ? window.innerWidth * 0.7 : 350}
          destroyOnClose
          onClose={() => {
            toggleOrderDrawer();
          }}
          visible={isOrderDrawerVisible}
        >
          {renderCustomerOrders(get(details, 'orders', []))}
        </Drawer>
        <Drawer
          title={isEdit ? 'Update customer ' : 'Add customer'}
          width={window.innerWidth < 500 ? window.innerWidth * 0.7 : 350}
          destroyOnClose
          onClose={toggleDrawer}
          visible={isDrawerVisible}
        >
          <AddCustomerForm
            details={details}
            isEdit={isEdit}
            updatedata={updateData}
          />
        </Drawer>
      </Spin>
    </>
  );
};
export default CustomerScreen;
