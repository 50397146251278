import React, { Component } from 'react';
import {
  Row,
  Spin,
  Popconfirm,
  Collapse,
  PageHeader,
  Col,
  Table,
  message
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, assign, map, head } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import DynamicHoursForm from '../../components/Business/DynamicHoursForm';
import requireAuth from '../../hocs/requireAuth';
import {
  getServiceHours,
  createServiceHour,
  deleteServiceHour
} from '../../redux/actions/serviceHourAction';
import { updateLocation } from '../../redux/actions/locationAction';
import {
  ADD_TIME_SLOT_SUCCESS,
  DELETE_TIME_SLOT_SUCCESS,
  OPERATION_HOURS_UPDATE
} from '../../constants/messages';
import OperationHoursForm from '../../components/Business/OperationHoursForm';

const { Panel } = Collapse;

class ServiceHoursScreen extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      location: null,
      isEdit: true
    };
  }

  componentWillMount() {
    this.setState({ location: get(this.props, 'location') });
  }

  componentDidMount() {
    this.getServiceHours();
  }
  updateOperationHours = (serviceObj, index = null) => {
    const { location } = this.state;
    this.loader(true);
    this.props
      .updateLocation(serviceObj, location)
      .then(() => {
        this.loader(false);
        message.success(OPERATION_HOURS_UPDATE);
      })
      .catch((err) => {
        this.loader(false);
      });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ location: get(nextProps, 'location') });
  }

  loader = (loading) => this.setState({ loading });

  getServiceHours = () => {
    this.loader(true);
    const { location } = this.props;
    this.props
      .getServiceHours(get(location, 'id'))
      .then((result) => {
        this.setState({ data: result });
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  updatedata = (hoursObj, day, type) => {
    const { location } = this.props;
    hoursObj = head(hoursObj);
    hoursObj = assign({}, hoursObj, {
      service_type: type,
      from: moment(get(hoursObj, 'from')).format('HH:mm:ss'),
      to: moment(get(hoursObj, 'to')).format('HH:mm:ss'),
      day,
      location: get(location, 'id')
    });

    this.loader(true);

    this.props
      .createServiceHour(hoursObj)
      .then(() => {
        message.success(ADD_TIME_SLOT_SUCCESS);
        this.getServiceHours();
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  removeTimeSlot = (hourId) => {
    this.props
      .deleteServiceHour(hourId)
      .then(() => {
        message.success(DELETE_TIME_SLOT_SUCCESS);
        this.getServiceHours();
      })
      .catch(() => {});
  };

  render() {
    const { data, loading, location, isEdit } = this.state;

    const BusinessColumn = [
      {
        title: 'Day',
        key: 'day',
        render: (record) => {
          return get(record, 'label');
        }
      },
      {
        title: 'Time',
        key: 'time',
        render: (record) => {
          return (
            <>
              {map(record.timings, (obj, index) => {
                return (
                  <Row
                    type="flex"
                    justify="center"
                    key={index}
                    style={{ marginBottom: 20 }}
                  >
                    <Col xs={12} xl={12}>
                      <span style={{ color: '#1890ff', cursor: 'pointer' }}>
                        {moment(get(obj, 'from'), 'HH:mm:ss').format('h:mm a')} -{' '}
                        {moment(get(obj, 'to'), 'HH:mm:ss').format('h:mm a')}{' '}
                      </span>
                    </Col>
                    <Col xs={2} xl={2}>
                      <Popconfirm
                        title="Are you sure delete this Slot?"
                        onConfirm={() => {
                          this.removeTimeSlot(obj.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="table-action-icon"
                        />

                        {/* <Icon type="delete" theme="twoTone" /> */}
                      </Popconfirm>
                    </Col>
                  </Row>
                );
              })}

              <DynamicHoursForm
                updatedata={this.updatedata}
                type={get(record, 'type')}
                details={record}
              />
            </>
          );
        }
      }
    ];

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive"
              title={<h4 className="text-uppercase">Service Hours</h4>}
            >
              <OperationHoursForm
                location={location}
                isEdit={isEdit}
                updatedata={this.updateOperationHours}
              />
              <Collapse defaultActiveKey={[]}>
                <Panel header="Dine in Timings" key="1" onChange={(val) => {}}>
                  <Table
                    pagination={false}
                    scroll={{ x: 800 }}
                    columns={BusinessColumn}
                    dataSource={get(data, 'dine_timings')}
                  />
                </Panel>
                <Panel header="Delivery Timings" key="2" onChange={(val) => {}}>
                  <Table
                    pagination={false}
                    scroll={{ x: 800 }}
                    columns={BusinessColumn}
                    dataSource={get(data, 'delivery_timings')}
                  />
                </Panel>
              </Collapse>
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getServiceHours,
    createServiceHour,
    deleteServiceHour,
    updateLocation
  })(ServiceHoursScreen)
);
