import React, { Component } from 'react';
import {
  Spin,
  Row,
  Modal,
  Drawer,
  Icon,
  Button,
  Input,
  Col,
  Tooltip,
  Table,
  Popconfirm,
  message,
  Select
} from 'antd';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import SortableTable from '../../components/SortableTable';
import AddCategoryForm from '../../components/Menu/AddCategoryForm';
import {
  ADD_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  CATEGORY_SORT_SUCCESS
} from '../../constants/messages';
import { visibleStatus } from '../../constants/keys';
import Helpers from '../../utilities/helpers';
import requireAuth from '../../hocs/requireAuth';

import {
  getCategoryItems,
  sortCategory,
  getCategories,
  createCategory,
  updateCategory
} from '../../redux/actions/categoryAction';

const { Option } = Select;

class CategoryScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      isDrawerVisible: false,
      isItemModalVisible: false,
      isEdit: false,
      details: null,
      itemData: [],
      searchText: '',
      filterData: [],
      data: [],
      parentCategories: [],
      searchStatus: [get(head(visibleStatus), 'value')]
    };
  }

  componentDidMount() {
    this.getInitial();
  }

  getInitial = () => {
    this.getParentCategories();
    this.getCategories();
  };

  loader = (loading) => this.setState({ loading });

  getCategoryItems = (itemId) => {
    this.props
      .getCategoryItems(itemId)
      .then((result) => {
        this.setState({ itemData: result, isItemModalVisible: true });
      })
      .catch(() => {});
  };

  getParentCategories = () => {
    const { location } = this.props;

    this.loader(true);

    this.props
      .getCategories(get(location, 'id'), true)
      .then((result) => {
        this.loader(false);
        this.setState({ parentCategories: result });
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getCategories = () => {
    const { location } = this.props;
    const { searchText, searchStatus } = this.state;
    this.loader(true);

    this.props
      .getCategories(get(location, 'id'))
      .then((result) => {
        this.loader(false);
        this.setState({ data: result });
        this.onSearch(searchText, searchStatus);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  updatedata = (categoryObj, id = null) => {
    this.loader(true);

    if (isNull(id)) {
      const { location } = this.props;
      categoryObj = { ...categoryObj, location: get(location, 'id') };

      this.props
        .createCategory(categoryObj)
        .then(() => {
          message.success(ADD_CATEGORY_SUCCESS);
          this.setState({ isDrawerVisible: false });
          this.getInitial();
        })
        .catch(() => {
          this.loader(false);
        });
    } else {
      const { location } = this.props;
      categoryObj = { ...categoryObj, location: get(location, 'id') };

      this.props
        .updateCategory(id, categoryObj)
        .then(() => {
          message.success(UPDATE_CATEGORY_SUCCESS);
          this.setState({ isDrawerVisible: false, details: null });
          this.getInitial();
        })
        .catch(() => {
          this.loader(false);
        });
    }
  };

  deleteCategory = (deleteStatus, id) => {
    this.updatedata({ delete: deleteStatus }, id);
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      details: null
    });
  };

  editService = (record) => {
    this.setState({ isEdit: true, details: record, isDrawerVisible: true });
  };

  updateDataSource = (data) => {
    this.setState({ data });
  };

  sortCategory = (oldIndex, newIndex) => {
    const { filterData } = this.state;
    const { location } = this.props;
    const category = filterData[oldIndex];
    const updateObj = {
      oldIndex: get(filterData[oldIndex], 'index'),
      newIndex: get(filterData[newIndex], 'index'),
      category: get(category, 'id'),
      location: get(location, 'id')
    };

    this.loader(true);

    this.props
      .sortCategory(updateObj)
      .then(() => {
        this.loader(false);
        message.success(CATEGORY_SORT_SUCCESS);
        this.getInitial();
      })
      .catch(() => {
        this.loader(false);
      });
  };

  onSearch = (searchText, searchStatus) => {
    const { data } = this.state;
    const filterData = filter(data, (o) => {
      let result1 = true;
      result1 = includes(o.name.toLowerCase(), searchText.toLowerCase());
      let result2 = true;
      if (searchStatus.length) {
        result2 = searchStatus.includes(get(o, 'status'));
      }
      return result1 && result2;
    });
    this.setState({ filterData, searchText, searchStatus });
  };

  render() {
    const {
      loading,
      isDrawerVisible,
      isItemModalVisible,
      isEdit,
      filterData,
      searchText,
      details,
      itemData,
      parentCategories,
      searchStatus
    } = this.state;

    const columns = [
      // {
      //   title: 'Image',
      //   dataIndex: 'image',
      //   key: 'name',
      //   className: 'drag-visible',
      //   render: (text) => {
      //     return (
      //       <img
      //         alt=""
      //         src={Helpers.getImageUrl(text)}
      //         style={{ width: 70, height: 50 }}
      //       />
      //     );
      //   }
      // },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        className: 'drag-visible',
        render: (text, record) => {
          return `${text} (Items - ${get(record, 'menu_items', []).length})`;
        }
      },

      {
        title: 'Enabled for order',
        dataIndex: 'status',
        key: 'status',
        render: (status, record, index) => {
          return Helpers.getEnabledForOrderStatus(status);
        }
      },

      // {
      //   title: 'Available for Order ?',
      //   dataIndex: 'status',
      //   align: 'center',
      //   key: 'status',
      //   render: (text, record) => {
      //     return (
      //       <Switch
      //         onChange={(val) => {
      //           this.updateCategoryStatus(val, record.id);
      //         }}
      //         defaultChecked={text}
      //       />
      //     );
      //   }
      // },

      {
        title: '',
        key: 'actions',
        render: (record) => {
          return (
            <>
              <Tooltip placement="top" title="Edit category">
                <FontAwesomeIcon
                  icon={faPen}
                  className="table-action-icon"
                  onClick={() => {
                    this.editService(record);
                  }}
                />

                {/* <Icon
                  onClick={() => {
                    this.editService(record);
                  }}
                  type="edit"
                  style={{ fontSize: 20, margin: 10 }}
                  theme="twoTone"
                /> */}
              </Tooltip>
              <Tooltip placement="top" title="View Items">
                <FontAwesomeIcon
                  className="table-action-icon"
                  icon={faEye}
                  onClick={() => {
                    this.setState({
                      itemData: get(record, 'menu_items'),
                      isItemModalVisible: true
                    });
                  }}
                />

                {/* <Icon
                  onClick={() => {
                    this.setState({
                      itemData: get(record, 'menu_items'),
                      isItemModalVisible: true
                    });
                  }}
                  type="eye"
                  style={{ fontSize: 20, margin: 10 }}
                  theme="twoTone"
                /> */}
              </Tooltip>

              <Tooltip placement="top" title="Delete Category">
                <Popconfirm
                  title="Are you sure you want to delete this category"
                  onConfirm={() => {
                    this.deleteCategory(true, record.id);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <FontAwesomeIcon icon={faTrash} className="table-action-icon" />
                </Popconfirm>
              </Tooltip>
            </>
          );
        }
      }
    ];

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <Row>
              <Col span={6}>
                <h4 className="text-uppercase">Category</h4>
              </Col>
              <Col xl={2} />
              <Col xl={6}>
                <Select
                  mode="multiple"
                  style={{ width: '90%' }}
                  value={searchStatus}
                  onChange={(val) => {
                    this.onSearch(searchText, val);
                  }}
                  placeholder="Enabled for order"
                >
                  {map(visibleStatus, (obj) => {
                    return (
                      <Option
                        value={get(obj, 'value')}
                        label={get(obj, 'label')}
                        key={get(obj, 'value')}
                      >
                        {get(obj, 'label')}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col
                span={6}
                style={{
                  textAlign: 'right'
                }}
              >
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  style={{ minWidth: 20 }}
                  suffix={
                    searchText ? (
                      <Icon
                        onClick={() => {
                          this.onSearch('', searchStatus);
                        }}
                        type="close-circle"
                        style={{ fontSize: 15 }}
                      />
                    ) : (
                      <Icon
                        onClick={() => {
                          this.onSearch('', searchStatus);
                        }}
                        type="search"
                        style={{ fontSize: 15 }}
                      />
                    )
                  }
                  onChange={(e) => {
                    this.onSearch(e.target.value, searchStatus);
                  }}
                />
              </Col>
              <Col
                span={4}
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  type="primary"
                  onClick={this.toggleDrawer}
                  className="btn-secondary btn-margin"
                >
                  <Icon className="btn-icon" type="plus" /> Add Category
                </Button>
              </Col>
            </Row>
            <SortableTable
              updateDataSource={this.updateDataSource}
              columns={columns}
              dataSource={filterData}
              sortData={this.sortCategory}
            />
          </div>

          <Drawer
            title={isEdit ? 'Update Category ' : 'Add Category'}
            destroyOnClose
            onClose={this.toggleDrawer}
            visible={isDrawerVisible}
            width={window.innerWidth < 500 ? window.innerWidth * 0.7 : 400}
          >
            <AddCategoryForm
              details={details}
              isEdit={isEdit}
              parentCategories={parentCategories}
              updatedata={this.updatedata}
            />
          </Drawer>

          <Modal
            title="Items"
            destroyOnClose
            onCancel={() => {
              this.setState({ isItemModalVisible: false, itemData: [] });
            }}
            visible={isItemModalVisible}
            footer={false}
          >
            <Table
              showHeader={false}
              pagination={false}
              columns={[
                {
                  title: 'Item',
                  key: 'items',
                  dataIndex: 'name'
                }
              ]}
              dataSource={itemData}
            />
          </Modal>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getCategories,
    createCategory,
    updateCategory,
    sortCategory,
    getCategoryItems
  })(CategoryScreen)
);
