import React from 'react';
import { Form, Input, Icon, Button, Row, Col } from 'antd';
import { map } from 'lodash';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import take from 'lodash/take';

let id = 0;

export default class ChargesOnWeight extends React.Component {
  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('weight_keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      weight_keys: keys.filter((key) => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('weight_keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      weight_keys: nextKeys
    });
  };

  componentDidMount() {
    const { charges } = this.props;
    if (charges) {
      id = isArray(charges) ? charges.length - 1 : 0;
      if (id < 0) {
        id = 0;
      }
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { charges } = this.props;
    let intialKeys = charges ? map(charges, (val, i) => i) : [];
    intialKeys = charges ? take(intialKeys, charges.length - 1) : [];
    console.log({ intialKeys });
    getFieldDecorator('weight_keys', {
      initialValue: intialKeys
    });

    const keys = getFieldValue('weight_keys');
    console.log('id====>', id, 'keys===>', keys);
    const { length } = keys;

    const formItems = keys.map((k, index) => (
      <div>
        <Row>
          <Col xl={10} xs={10} className="dynamic-form-col-padding">
            <Form.Item required={false} key={k}>
              {getFieldDecorator(`charges[${k}].weight`, {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue:
                  charges && charges[k] ? get(charges[k], 'weight') : null,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input weight'
                  }
                ]
              })(<Input addonAfter="Kg" placeholder="Weight" type="number" />)}
            </Form.Item>
          </Col>

          <Col xl={10} xs={10} className="dynamic-form-col-padding">
            <Form.Item required={false} key={k}>
              {getFieldDecorator(`charges[${k}].price`, {
                validateTrigger: ['onChange', 'onBlur'],
                initialValue:
                  charges && charges[k] ? get(charges[k], 'price') : null,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input Price'
                  }
                ]
              })(
                <Input
                  addonBefore={this.props.sign}
                  placeholder="40"
                  type="number"
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={2} xs={2} className="dynamic-form-col-padding">
            <Form.Item colon={false} required={false} key={k}>
              {keys.length > 1 ? (
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => this.remove(k)}
                />
              ) : null}
            </Form.Item>
          </Col>
        </Row>
        {index === length - 1 && (
          <Row>
            <Col xl={10} xs={10} className="dynamic-form-col-padding">
              <Form.Item label="Weight above that" required={false} key={k}>
                {getFieldDecorator(
                  `charges[${k + 1}].weight`,
                  {}
                )(<Input placeholder="Weight Above" type="hidden" />)}
              </Form.Item>
            </Col>

            <Col xl={10} xs={10} className="dynamic-form-col-padding">
              <Form.Item required={false} key={k}>
                {getFieldDecorator(`charges[${k + 1}].price`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  initialValue:
                    charges && charges[k] ? get(charges[k + 1], 'price') : null,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input Price'
                    }
                  ]
                })(
                  <Input
                    addonBefore={this.props.sign}
                    placeholder="40"
                    type="number"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
      </div>
    ));

    return (
      <div>
        {length > 0 && (
          <Row>
            <Col xl={10} xs={10} className="dynamic-form-col-padding">
              Weight upto (in Kg)
            </Col>
            <Col xl={10} xs={10} className="dynamic-form-col-padding">
              Charge Amount
            </Col>
          </Row>
        )}
        {formItems}
        <Form.Item>
          <Button type="link" onClick={this.add}>
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>
      </div>
    );
  }
}
