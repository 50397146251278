export const BASE_URL = process.env.REACT_APP_API_HOST;
// export const BASE_URL = 'https://api.dev.grubly.xyz';
export const BUCKET_URL = 'https://grubly-assets.s3.amazonaws.com/';
export const FRONT_STORE_URL = process.env.REACT_APP_FRONT_STORE_URL;
export const LOGIN = '/auth/local';
export const USER = '/users/{user_id}';
export const LOCATION = '/locations/{id}';
export const UPDATE_LOCATION = '/locations/{location_id}';
export const LOCATION_STATS = '/locations/{id}/stats';
export const SERVICEABLE_ZIP_CODE = '/serviceable-zip-codes';
export const VALIDATE_GATEWAY_DETAILS =
  '/integrations/validate-gateway-details?location={location_id}';

export const CURRENCY = '/currencies';
export const COUNTRY = '/countries';
export const STATE = '/states?country={country_id}';
export const TIME_ZONE = '/time-zones';

export const CHARGES_AND_TAXES = '/charges-and-taxes';
export const CHARGES_AND_TAXES_COUNT = '/charges-and-taxes/count';
export const GET_UPDATE_CHARGES_AND_TAXES = '/charges-and-taxes/{id}';

export const RESERVATION = '/reservations';
export const RESERVATION_COUNT = '/reservations/count';
export const UPDATE_RESERVATION = '/reservations/{id}';

export const SERVICE_HOURS = 'service-hours';
export const DELETE_SERVICE_HOURS = 'service-hours/{id}';

export const CATEGORY = '/menu-categories';
export const CATEGORY_COUNT = '/menu-categories/count';
export const UPDATE_CATEGORY = '/menu-categories/{id}';
export const SORT_CATEGORY = '/menu-categories/sort';
export const CATEGORY_ITEMS = '/menu-categories?CATEGORY={category_id}';

export const ITEM = '/menu-items';
export const SINGLE_ITEM = '/menu-items/{id}';
export const ITEM_COUNT = '/menu-items/count';
export const UPDATE_ITEM = '/menu-items/{id}';
export const SORT_ITEM = '/menu-items/sort';

export const CUSTOMER = '/customers';
export const CUSTOMER_COUNT = '/customers/count';
export const UPDATE_CUSTOMER = '/customers/{id}';

export const ADDON = '/addons';
export const UPDATE_ADDON = '/addons/{id}';
export const SINGLE_ADDON = '/addons/{id}';
export const GET_ADDON = '/addons/{id}';
export const SORT_ADDON = '/addons/sort';
export const UPDATE_ADDON_ITEM = '/addon-items/{id}';

export const CREATE_FCM = '/fcms';

export const ORDERS = '/orders';
export const UPDATE_ORDERS = '/orders/{order_id}';
export const ORDER_COUNT = 'orders/count?location={location_id}';

export const IMPORT_SEED_FILE = `${BASE_URL}/locations/{location}/import-data`;
export const GET_MEDIA_PRESIGNED_URL =
  '/locations/presigned-url?content_type={content_type}';
export const CHARGES = '/charges?location={id}';
export const CREATE_CHARGES = '/charges';
export const CUSTOMER_FEEDBACKS = '/customer-feedbacks';
export const SEND_ORDER_FEEDBACK = '/customer-feedbacks/orders/{order_id}';
export const EXPORT_ORDERS = '/orders/export';
export const ORDERS_BULK_UPDATE = '/orders';
