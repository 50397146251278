export const DASHBOARD = '/dashboard';
export const CONTACT_DETAILS = '/contact-details';
export const BUSINESS_ADDRESS = '/business-address';
export const SERVICE_HOURS = '/service-hours';
export const ONLINE_PRESENCE = '/online-presence';
export const SERVICE_TYPE = '/service-type';
export const RESERVATION_SETTINGS = '/reservation-settings';
export const PAYMENT = '/payment';
export const SERVICEABLE_LOCATION = '/serviceable-locations';
export const CHARGES_AND_TAXES = '/charges-taxes';
export const RESERVATION = '/reservations';
export const CUSTOMER = '/customers';
export const LOGIN = '/login';
export const ORDERS = '/orders';
export const CATEGORY = '/item-categories';
export const ITEMS = '/items';
export const ADD_ON = '/add-ons';
export const IMPORT = '/import';
export const CHANGE_PASSWORD = '/change-password';
export const CHARGES = '/charges';
export const CUSTOMER_FEEDBACK = '/customer-feedbacks';
export const BUSINESSINFO = '/business-info';
export const MARKETING = '/marketing';
export const STORE_MESSGAES = '/store-messages';
