import React from 'react';
import { Form, Icon, Input, Button, Checkbox } from 'antd';

class LoginForm extends React.Component {
  handleSubmit = (e) => {
    const { onSubmit } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('identifier', {
            rules: [{ required: true, message: 'Please input your username!' }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              size="large"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(
            'remember',
            {}
          )(
            <Checkbox defaultChecked className="link-gray">
              Remember me
            </Checkbox>
          )}
          <a className="login-form-forgot link-gray" href="/">
            Forgot password?
          </a>
          <Button type="primary" htmlType="submit" className="btn-primary">
            Sign In
          </Button>
          <div className="text-center" />
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'login_form' })(LoginForm);
