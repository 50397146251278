import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import * as ROUTES from './constants/routes';
import BusinessRoutes from './routes/BusinessRoutes';
import ReservationRoutes from './routes/ReservationRoutes';
import CustomerRoutes from './routes/CustomerRoutes';
import MenuRoutes from './routes/MenuRoutes';
import DashboardScreen from './screens/DashboardScreen';
import LoginScreen from './screens/LoginScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import SideBarMenu from './components/layouts/SideBarMenu';
import Header from './components/layouts/Header';
import { messagingObject } from './firebase';
import { createFcm } from './redux/actions/authActions';
import { getLocation } from './redux/actions/locationAction';
import ExpiredScreen from './screens/Expired';

const { Content, Sider } = Layout;

class AppRouter extends Component {
  constructor() {
    super();

    this.state = {
      showSideBar: false,
      isLoggedIn: true,
      loading: false,
      // selectedMenu: head(MenuItems),
      collapsed: false
    };
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      const { location } = this.props;
      this.refreshLocationData(get(location, 'id'));
      this.initiateFcm(this.props);
      // this.setFreshChatData(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    // alert('enter');

    if (nextProps.isLoggedIn !== this.props.isLoggedIn && nextProps.isLoggedIn) {
      this.initiateFcm(nextProps);
    }
    this.setFreshChatData(nextProps);
  }

  setFreshChatData = (props) => {
    const { isLoggedIn, user } = props;

    if (isLoggedIn && window.FreshworksWidget) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: get(user, 'username'),
        email: get(user, 'email')
      });
    }
  };

  refreshLocationData = (location) => {
    this.props.getLocation(location).then(() => {});
  };

  initiateFcm = async (props) => {
    const { isLoggedIn, fcm, user } = props;
    if (isLoggedIn) {
      try {
        const newFcm = await messagingObject.getToken();

        if (fcm !== newFcm) {
          this.props.createFcm({
            user_id: get(user, 'id'),
            fcm_token: newFcm,
            user_type: 'BusinessAdmin'
          });
        }
      } catch (e) {}
    }
  };

  loader = (loading) => this.setState({ loading });

  toggleSideBar = () => {
    const { showSideBar } = this.state;
    this.setState({ showSideBar: !showSideBar });
  };

  toggleHeader = () => {
    const { isLoggedIn } = this.state;
    this.setState({
      isLoggedIn: !isLoggedIn,
      showSideBar: false
      // selectedMenu: head(MenuItems)
    });
  };

  onSelectMenu = (selectedMenu, showSideBar) => {
    this.setState({
      // selectedMenu,
      showSideBar
    });
  };

  render() {
    const { isLoggedIn, location } = this.props;
    const { loading } = this.state;

    const expiryDate = location?.expiry_date ? moment(location.expiry_date) : null;

    const isExpired = expiryDate && moment().isAfter(expiryDate);

    return (
      <Router>
        <Spin spinning={loading}>
          <Layout style={{ minHeight: '100vh' }} theme="light">
            {isLoggedIn && (
              <Sider
                width={250}
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                style={{
                  position: 'fixed'
                }}
              >
                <div className="logo">
                  {/* <img src="/logo.png" /> */}
                  grubly
                </div>
                <SideBarMenu />
              </Sider>
            )}
            <Layout
              style={{
                marginLeft: 250
              }}
            >
              {isLoggedIn && (
                <Header
                  style={{ background: '#fff', padding: 0 }}
                  loader={this.loader}
                />
              )}
              <Content style={{ margin: 0 }}>
                {expiryDate && (
                  <Route path="*" exact component={() => <ExpiredScreen />} />
                )}

                {!isExpired && (
                  <>
                    <Route
                      path={ROUTES.DASHBOARD}
                      exact
                      component={() => <DashboardScreen />}
                    />

                    <BusinessRoutes />
                    <ReservationRoutes />
                    <CustomerRoutes />
                    <MenuRoutes />
                  </>
                )}

                <Route
                  path={ROUTES.LOGIN}
                  exact
                  component={(props) => {
                    return (
                      <LoginScreen
                        props={props}
                        refreshLocationData={this.refreshLocationData}
                      />
                    );
                  }}
                />

                <Route
                  path={ROUTES.CHANGE_PASSWORD}
                  exact
                  component={(props) => {
                    return <ChangePasswordScreen />;
                  }}
                />

                <Route
                  path="/"
                  exact
                  component={(props) => {
                    return <LoginScreen exact props={props} />;
                  }}
                />
              </Content>
            </Layout>
          </Layout>
        </Spin>
      </Router>
    );

    // return (
    //   <Router>
    //     <div className="mainContainer main-section">
    //       <Layout>
    //         <Header />

    //         <div className="main-content-wrapper ">
    //           {isLoggedIn && <SideBar />}

    //           <div className="content-wrapper">
    //             <Route
    //               path={ROUTES.DASHBOARD}
    //               exact
    //               component={() => <DashboardScreen />}
    //             />
    //             <BusinessRoutes />
    //             <ReservationRoutes />
    //             <CustomerRoutes />
    //             <MenuRoutes />
    //             <Route
    //               path={ROUTES.LOGIN}
    //               exact
    //               component={(props) => {
    //                 return <LoginScreen props={props} />;
    //               }}
    //             />

    //             <Route
    //               path="/"
    //               exact
    //               component={(props) => {
    //                 return <LoginScreen exact props={props} />;
    //               }}
    //             />
    //           </div>
    //           <Footer />
    //         </div>
    //       </Layout>
    //     </div>
    //   </Router>
    // );
  }
}

const mapStateToProps = (state) => ({
  user: get(state, 'auth.user'),
  isLoggedIn: state.auth.isLoggedIn,
  fcm: state.auth.fcm,
  location: get(state, 'location.current_location'),
  allLocation: get(state, 'location.all_location')
});

export default connect(mapStateToProps, { createFcm, getLocation })(AppRouter);
