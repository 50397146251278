import React, { Component } from 'react';
import { PageHeader, Col, Row, Spin, Icon, Card, Table } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import * as ROUTES from '../constants/routes';
import requireAuth from '../hocs/requireAuth';
import { getLocation, getLocationStats } from '../redux/actions/locationAction';
import ColumChart from '../components/Dashboard/ColumChart';
import Helpers from '../utilities/helpers';

class DashboardScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      stats: null,
      fromDate: moment()
        .subtract(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  componentDidMount() {
    const { fromDate, toDate } = this.state;
    this.getLocationStats(fromDate, toDate);
  }

  getLocationStats = (fromDate, toDate) => {
    const { location } = this.props;
    if (get(location, 'id')) {
      this.loader(true);

      this.props
        .getLocationStats(get(location, 'id'), fromDate, toDate)
        .then((stats) => {
          this.setState({ stats, fromDate, toDate });
          this.loader(false);
        })
        .catch((error) => {
          this.loader(false);
        });
    }
  };

  renderCard = (value = 0, icon, title = '') => {
    return (
      <Card bordered={false} className="primary-shadow" style={{ width: 300 }}>
        <Row>
          <Col xl={8} xs={12}>
            <Icon type={icon} theme="twoTone" style={{ fontSize: 50 }} />
          </Col>

          <Col xl={16} xs={12}>
            <div
              style={{
                fontSize: 40,
                fontWeight: 600,
                textAlign: 'right',
                opacity: 0.5
              }}
            >
              {value}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12} xs={24}>
            <div
              style={{
                fontSize: 25,
                color: 'black',
                fontWeight: 300,
                textAlign: 'right',
                opacity: 0.5
              }}
            >
              {title}
            </div>
          </Col>
        </Row>
      </Card>
    );
  };

  loader = (loading) => this.setState({ loading });

  getLocationDetails = () => {
    const { user } = this.props;
    this.loader(true);
    this.props
      .getLocation(user)
      .then(() => {
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  render() {
    const { loading } = this.state;
    const currency = get(this.props.location, 'currency.sign');
    const BusinessColumn = [
      {
        title: 'Service Type',
        dataIndex: 'service_type',
        align: 'center',
        key: 'service_type',
        render: (text) => {
          return Helpers.getServiceTypeTag(text);
        }
      },
      {
        title: 'Order ID',
        dataIndex: 'order_id',
        key: 'order_id'
      },
      {
        title: 'Service Date',
        dataIndex: 'service_date',
        align: 'center',
        key: 'service_date',
        render: (text) => {
          return moment(text).format('MMMM Do YYYY, h:mm:ss ');
        }
      },
      {
        title: 'Order Amount',
        dataIndex: 'total_amount',
        key: 'order_total',
        render: (text) => {
          return `${currency} ${text}`;
        }
      }
    ];

    const reservationColumn = [
      {
        title: 'Date',
        dataIndex: 'date',
        align: 'center',
        key: 'date',
        render: (text) => {
          return moment(text).format('MMMM Do YYYY');
        }
      },
      {
        title: 'Time',
        dataIndex: 'time',
        align: 'center',
        key: 'time',
        render: (text) => {
          return moment(text, 'HH:mm:ss').format('h:mm a');
        }
      },
      {
        title: 'Number of people',
        dataIndex: 'people',
        align: 'center',
        key: 'people'
      },
      {
        title: 'Reservation Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
          return Helpers.getReservationStatusTag(text);
        }
      }
    ];

    const { stats } = this.state;

    return (
      <PageHeader
        className="site-page-header-responsive"
        title={<h4 className="text-uppercase">Summary</h4>}
      >
        <Spin spinning={loading}>
          <Row type="flex" justify="end">
            <Col xl={24} xs={24} style={{ textAlign: 'end' }}></Col>
          </Row>
          <Row>
            <Col
              xl={24}
              xs={24}
              style={{
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 10
              }}
            >
              <h3 className="graph-heading">Weekly Revenue</h3>
              <ColumChart stats={stats} />
            </Col>
          </Row>

          <Row type="flex">
            <Col xl={12} xs={24} style={{ paddingRight: 10 }}>
              <Card
                title="Today's Orders"
                extra={<Link to={`${ROUTES.ORDERS}`}>All Orders</Link>}
              >
                <Table
                  columns={BusinessColumn}
                  dataSource={get(stats, 'orders', [])}
                />
              </Card>
            </Col>

            <Col xl={12} xs={24} style={{ paddingLeft: 10 }}>
              <Card
                title="Today's Reservations"
                extra={<Link to={`${ROUTES.RESERVATION}`}>All Reservations</Link>}
              >
                <Table
                  columns={reservationColumn}
                  dataSource={get(stats, 'reservations', [])}
                />
              </Card>
            </Col>
          </Row>
        </Spin>
      </PageHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: get(state, 'auth.isLoggedIn'),
  user: get(state, 'auth.user'),
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    getLocation,
    getLocationStats
  })(DashboardScreen)
);
