import React, { Component } from 'react';
import { Layout, Menu, Avatar, Dropdown, Select, Button } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Gravatar from 'react-gravatar';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { FRONT_STORE_URL } from '../../constants/apiEndpoints';

import * as ROUTES from '../../constants/routes';
import { logout } from '../../redux/actions/authActions';
import { changeLocation } from '../../redux/actions/locationAction';

const { Option } = Select;

const { Header } = Layout;

class Navbar extends Component {
  logout = () => {
    const { history } = this.props;
    this.props.logout();
    history.push(ROUTES.LOGIN);
  };

  onSelectLocation = async (value) => {
    this.props.loader(true);
    await this.props.changeLocation(value, this.props.allLocations).then(() => {
      window.location.reload(false);
    });
  };

  render() {
    const menu = (
      <Menu className="profile-dropdown-sec">
        <Menu.Item>
          <Link to={ROUTES.CHANGE_PASSWORD}>Change Password</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.logout();
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    );
    const { allLocations, currentLocation, user } = this.props;

    return (
      <Header className="header-section secondary-shadow">
        <div>
          <Menu
            className="menu-section"
            mode="horizontal"
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1" className="profile-item-menu">
              <Dropdown overlay={menu} placement="bottomRight">
                <div className="user-img">
                  {/* <Avatar
                    src={ProfileImage}
                    width="60"
                    className="icon-sec"
                    alt="logo"
                 /> */}
                  <Avatar
                    icon={<Gravatar email={get(user, 'username')} size={20} />}
                  />

                  {get(user, 'username')}
                </div>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
        <div style={{ flex: 1 }}>
          <Select
            style={{ minWidth: '120px' }}
            size="large"
            value={get(currentLocation, 'id')}
            onChange={this.onSelectLocation}
          >
            {map(allLocations, (location, index) => {
              return (
                <Option value={get(location, 'id')} key={`${get(location, 'id')}`}>
                  {get(location, 'name')}
                </Option>
              );
            })}
          </Select>
          {get(currentLocation, 'slug') ? (
            <Button
              size="large"
              onClick={() => {
                window.open(`${FRONT_STORE_URL}/${get(currentLocation, 'slug')}`);
              }}
              type="primary"
              style={{ marginLeft: 10 }}
            >
              Go to Store
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ color: 'white', marginLeft: 10 }}
              />
            </Button>
          ) : null}
        </div>
      </Header>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: get(state, 'auth.user'),
  allLocations: get(state, 'location.all_location'),
  currentLocation: get(state, 'location.current_location')
});

export default connect(mapStateToProps, {
  logout,
  changeLocation
})(withRouter(Navbar));
