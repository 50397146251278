import React from 'react';
import { Form, Button, Input } from 'antd';
import get from 'lodash/get';

class BusinessInfoForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { credentials } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values, credentials.id);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { credentials, isEdit } = this.props;

    return (
      <Form
        layout="vertical"
        labelCol={{
          xs: { span: 3 },
          xl: { span: 3 },
          sm: { span: 3 }
        }}
        wrapperCol={{
          xs: { span: 8 },
          xl: { span: 8 },
          sm: { span: 8 }
        }}
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
      >
        <Form.Item label="GST">
          {getFieldDecorator('gst', {
            initialValue: isEdit ? get(credentials, 'gst') : false
          })(<Input />)}
        </Form.Item>
        <Form.Item label="PAN">
          {getFieldDecorator('pan', {
            initialValue: isEdit ? get(credentials, 'pan') : false
          })(<Input />)}
        </Form.Item>
        <Form.Item label="CIN">
          {getFieldDecorator('cin', {
            initialValue: isEdit ? get(credentials, 'cin') : false
          })(<Input />)}
        </Form.Item>
        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'business_info_Form' })(BusinessInfoForm);
