import React from 'react';
import { Form, Button } from 'antd';
import get from 'lodash/get';
import TextArea from 'antd/lib/input/TextArea';

class OperationHoursForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { location } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values, location.id);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { location, isEdit } = this.props;

    return (
      <Form
        style={{ width: '600px' }}
        layout="vertical"
        labelCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
      >
        <Form.Item
          label="Operation Hours"
          extra="This information will be displayed in your store operation hours area."
        >
          {getFieldDecorator('operation_hours', {
            initialValue: isEdit ? get(location, 'operation_hours') : false
          })(
            <TextArea
              style={{ height: '5rem' }}
              placeholder={`Mon to Fri : 9:00 AM to 1 PM, 5 PM to 10:00 PM\nSat and Sun : 11:00 AM to 7:00 PM`}
            />
          )}
        </Form.Item>
        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'operation_hours_Form' })(OperationHoursForm);
