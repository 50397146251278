import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';

import AddPaymentForm from '../../components/Business/AddPaymentForm';
import { UPDATE_PAYMENTS_SUCCESS } from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import {
  updateLocation,
  getCurrency,
  validateGatewayDetails
} from '../../redux/actions/locationAction';

class PaymentScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isEdit: true,
      loading: false,
      details: null,
      currency: [],
      onlineSwitch: false
    };
  }

  // UNSAFE_componentWillReceiveProps() {
  //   this.setState({ details: get(this.props, 'location') });
  // }

  componentWillMount() {
    this.setState({ details: get(this.props, 'location') });
    this.getCurrency();
    this.validateGatewayDetails();
  }

  validateGatewayDetails = () => {
    const location = get(this.props, 'location');
    this.loader(true);
    this.props
      .validateGatewayDetails(get(location, 'id'))
      .then((onlineSwitch) => {
        this.setState({ onlineSwitch });
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  getCurrency = () => {
    this.loader(true);
    this.props
      .getCurrency()
      .then((currency) => {
        this.setState({ currency });
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  loader = (loading) => this.setState({ loading });

  updatedata = (paymentObj, index = null) => {
    const { details } = this.state;
    this.loader(true);

    this.props
      .updateLocation(paymentObj, details)
      .then(() => {
        this.loader(false);
        message.success(UPDATE_PAYMENTS_SUCCESS);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      details: null
    });
  };

  render() {
    const { onlineSwitch, loading, isEdit, details, currency } = this.state;

    return (
      <Spin spinning={loading}>
        <div className="main-container">
          <PageHeader
            className="site-page-header-responsive title title-md mb--20"
            title={<h4 className="text-uppercase">Payment </h4>}
            // subTitle="Add a new bussiness"
          >
            <AddPaymentForm
              currency={currency}
              details={details}
              isEdit={isEdit}
              onlineSwitch={onlineSwitch}
              updatedata={this.updatedata}
            />
          </PageHeader>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, {
    updateLocation,
    getCurrency,
    validateGatewayDetails
  })(PaymentScreen)
);
