import React from 'react';
import { Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import ReservationScreen from '../screens/Reservation/ReservationScreen';

export default () => {
  return (
    <>
      <Route path={ROUTES.RESERVATION} exact component={ReservationScreen} />
    </>
  );
};
