import React from 'react';
import { Spin, Form, Button, Input, Upload, message, Icon, Select } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { getMediaUrl } from '../../redux/actions/mediaAction';
import Helpers from '../../utilities/helpers';
const { Option } = Select;

class StoreMessagesForm extends React.Component {
  constructor() {
    super();
    this.state = {
      imageUrl: null,
      image: null,
      loading: false
    };
  }
  componentDidMount() {
    this.setImageUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setImageUrl(nextProps);
  }

  setImageUrl = (props) => {
    const { details } = props;

    let imageUrl = null;
    let image = null;
    if (get(details, 'cta_image')) {
      imageUrl = Helpers.getImageUrl(get(details, 'cta_image'));
      image = get(details, 'cta_image');
    }
    this.setState({ imageUrl, image });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { image } = this.state;
        if (image) {
          values.cta_image = image;
        } else {
          values.cta_image = null;
        }
        this.props.updatedata(values);
      }
    });
  };

  getMediaUrl = (info) => {
    this.loader(true);

    this.props
      .getMediaUrl(info)
      .then((result) => {
        this.loader(false);

        this.setState({
          imageUrl: get(result, 'imageUrl'),
          image: get(result, 'key')
        });
        // return false;
      })
      .catch(() => {
        this.loader(false);
      });
  };

  loader = (loading) => this.setState({ loading });

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    return true;
  };

  render() {
    const { details, categories } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { imageUrl, loading } = this.state;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Spin spinning={loading}>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Form.Item
            label="CTA Image"
            extra="Recommended image size is 400 x 400 px"
          >
            {getFieldDecorator('cta_image', {
              initialValue: get(details, 'cta_image')
                ? get(details, 'cta_image')
                : null
            })(
              <Upload
                name="avatar"
                // onRemove={() => {}}
                listType="picture-card"
                beforeUpload={this.beforeUpload}
                className="avatar-uploader"
                showUploadList={false}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
                customRequest={this.getMediaUrl}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            )}
          </Form.Item>
          <Form.Item label="CTA Category">
            {getFieldDecorator('cta_menu_category', {
              initialValue: get(details, 'cta_menu_category.id', null)
                ? get(details, 'cta_menu_category.id')
                : null
            })(
              <Select size="large" placeholder="Please select" showSearch allowClear>
                {map(categories, (category) => {
                  if (get(category, 'children', []).length > 0) {
                    return null;
                  }

                  if (get(category, 'menu_items', []).length === 0) {
                    return null;
                  }

                  return (
                    <Option
                      key={get(category, 'name')}
                      value={get(category, 'id')}
                      label={get(category, 'name')}
                    >
                      {get(category, 'name')}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Terms and Conditions">
            {getFieldDecorator('terms_and_conditions', {
              initialValue: get(details, 'terms_and_conditions')
                ? get(details, 'terms_and_conditions')
                : ''
            })(<Input.TextArea placeholder="Terms and conditions" size="large" />)}
          </Form.Item>

          <Form.Item label="Store Front Message">
            {getFieldDecorator('front_store_message', {
              initialValue: get(details, 'front_store_message')
                ? get(details, 'front_store_message')
                : ''
            })(<Input.TextArea placeholder="Font store message" size="large" />)}
          </Form.Item>

          <Form.Item label="Thank you Message">
            {getFieldDecorator('thank_you_page_message', {
              initialValue: get(details, 'thank_you_page_message')
                ? get(details, 'thank_you_page_message')
                : ''
            })(<Input.TextArea placeholder="Thank you message" size="large" />)}
          </Form.Item>

          <Form.Item colon={false}>
            <Button type="primary" htmlType="submit" className="btn-secondary">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

const wrappedForm = Form.create({ name: 'store_messages' })(StoreMessagesForm);
export default connect(mapStateToProps, { getMediaUrl })(wrappedForm);
