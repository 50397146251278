import React from 'react';
import { Form, notification, Input, Button } from 'antd';
import get from 'lodash/get';
import Helpers from '../../utilities/helpers';
import { URL } from '../../constants/regex';

class AddOnlinePresenceForm extends React.Component {
  getErrorNotification = () => {
    return notification.error({
      message: `Url Required`,
      duration: 4,
      description: 'At least 1 url required to create online presense',
      placement: 'topRight'
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (
          !values.facebook_url &&
          !values.instagram_url &&
          !values.website_url
        ) {
          this.getErrorNotification();
        } else {
          this.props.updatedata(values);
        }
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { socialMediaDetails } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (
          !values.facebook_url &&
          !values.instagram_url &&
          !values.website_url
        ) {
          this.getErrorNotification();
        } else {
          this.props.updatedata(values, socialMediaDetails.id);
        }
      }
    });
  };

  validateUrl = (rule, value, cb) => {
    if (!value) {
      cb();
    } else if (Helpers.compareRegex(value, URL)) {
      cb();
    } else {
      cb('Url that you enter is invalid!');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { socialMediaDetails, isEdit } = this.props;

    return (
      <Form
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 4 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 12 }
        }}
      >
        <Form.Item label="Website">
          {getFieldDecorator('website_url', {
            initialValue: isEdit ? get(socialMediaDetails, 'website_url') : '',
            rules: [{ validator: this.validateUrl }]
          })(<Input placeholder="" size="large" />)}
        </Form.Item>
        <Form.Item label="Instagram">
          {getFieldDecorator('instagram_url', {
            initialValue: isEdit
              ? get(socialMediaDetails, 'instagram_url')
              : '',
            rules: [{ validator: this.validateUrl }]
          })(<Input placeholder="" size="large" />)}
        </Form.Item>

        <Form.Item label="Facebook">
          {getFieldDecorator('facebook_url', {
            initialValue: isEdit ? get(socialMediaDetails, 'facebook_url') : '',
            rules: [{ validator: this.validateUrl }]
          })(<Input placeholder="" size="large" />)}
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'online_presence' })(AddOnlinePresenceForm);
