/**
 * Constants
 */
import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getCustomers = (location, page = 1, limit = 10, query = null) => (
  dispatch,
  getState,
  { api }
) => {
  const start = (page - 1) * limit;
  let paramObj = {
    _limit: limit,
    _start: start,
    location,
    _sort: 'created_at:DESC'
  };

  if (query) {
    paramObj.query = query;
  }
  const url = Helpers.getUrl(APIS.CUSTOMER, paramObj);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCustomerCount = (
  location,
  page = 1,
  limit = 10,
  considerParams = true
) => (dispatch, getState, { api }) => {
  const url = Helpers.getUrl(APIS.CUSTOMER_COUNT, {
    location
  });
  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createCustomer = (customerObj) => (dispatch, getState, { api }) => {
  return api
    .post(APIS.CUSTOMER, customerObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateCustomer = (id, customerObj) => (dispatch, getState, { api }) => {
  const url = APIS.UPDATE_CUSTOMER.replace('{id}', id);

  return api
    .put(url, customerObj)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};
