import React from 'react';
import {
  Form,
  Select,
  Input,
  Row,
  Col,
  Icon,
  Button,
  Typography,
  Checkbox
} from 'antd';

import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;
const { Text } = Typography;

class AddAddForm extends React.Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      max_selection_limit_visible: false
    };
  }

  componentDidMount() {
    const { details, isEdit } = this.props;
    if (isEdit) {
      const id = details.addon_items.length - 1;
      console.log('details', details);
      const max_selection_limit_visible = get(details, 'multiple_selection');
      this.setState({ id, max_selection_limit_visible });
    }
  }

  remove = (k) => {
    const { form } = this.props;

    const keys = form.getFieldValue('keys');
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    let { id } = this.state;
    const keys = form.getFieldValue('keys');
    id += 1;

    const nextKeys = keys.concat(id);

    form.setFieldsValue({
      keys: nextKeys
    });
    this.setState({ id });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        remove(values.addon_items, (obj) => isEmpty(obj) || isEmpty(obj.name));

        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { details } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { max_selection_limit_visible } = this.state;

        remove(values.addon_items, (obj) => isEmpty(obj) || isEmpty(obj.name));
        if (!max_selection_limit_visible) {
          values.max_selection_limit = null;
        }

        this.props.updatedata(values, details.id);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { details, isEdit, itemData } = this.props;
    console.log(this.props);

    const { max_selection_limit_visible } = this.state;
    console.log('this.state=====>', this.state);
    getFieldDecorator('keys', {
      initialValue: isEdit ? map(details.addon_items, (val, i) => i) : [0]
    });

    const keys = getFieldValue('keys');

    return (
      <Form onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}>
        <Row>
          <Col xs={24} xl={24}>
            <Form.Item label="Add-on Type">
              {getFieldDecorator('name', {
                initialValue: isEdit ? get(details, 'name') : '',
                rules: [
                  { required: true, message: 'Please input Addon name field!' }
                ]
              })(
                <Input placeholder="Topping, Preparation Style" size="large" />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={{ alignSelf: 'center' }} xs={24} xl={24}>
            <Form.Item key="Mulitple selection">
              {getFieldDecorator(`multiple_selection`, {
                initialValue: get(details, `multiple_selection`, false)
              })(
                <Checkbox
                  defaultChecked={get(details, `multiple_selection`, false)}
                  onClick={(e) => {
                    this.setState({
                      max_selection_limit_visible: e.target.checked
                    });
                  }}
                >
                  Can use select multiple addon items from below?
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        {max_selection_limit_visible && (
          <Row>
            <Col style={{ alignSelf: 'center' }} xs={24} xl={24}>
              <Form.Item
                key="Max selection limit"
                label="Maximum addons allowed"
              >
                {getFieldDecorator(`max_selection_limit`, {
                  initialValue:
                    get(details, `max_selection_limit`) > 0
                      ? get(details, `max_selection_limit`)
                      : null
                })(<Input type="number" min={1} />)}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col style={{ alignSelf: 'center' }} xs={24} xl={24}>
            <Form.Item key="Available">
              {getFieldDecorator(`status`, {
                initialValue: get(details, `status`, false)
              })(
                <Checkbox defaultChecked={get(details, `status`, false)}>
                  Is this add-on type available at the moment?
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* <Switch
          onChange={(status) => {
            this.updatedata({ status }, record.id);
          }}
          defaultChecked={text}
        /> */}

        <Row type="flex" style={{ marginTop: 15 }}>
          <Col xs={10} xl={16} style={{ paddingRight: 10 }}>
            <Text strong>Name</Text>
          </Col>
          <Col xs={10} xl={4} style={{ paddingRight: 10 }}>
            <Text strong>Price</Text>
          </Col>
          <Col style={{ alignSelf: 'center' }} xs={2} xl={2}>
            <Text strong>Active</Text>
          </Col>
          <Col style={{ alignSelf: 'center' }} xs={2} xl={2} />
        </Row>
        {keys.map((k) => (
          <Row type="flex">
            <Col xs={10} xl={16} style={{ paddingRight: 10 }}>
              <Form.Item key={`item${k}`}>
                {getFieldDecorator(`addon_items.${k}.name`, {
                  initialValue: isEdit
                    ? get(details, `addon_items.${k}.name`)
                    : '',
                  rules: [
                    {
                      required: true,
                      message: 'Please input add on item'
                    }
                  ]
                })(<Input placeholder="Addon Item" size="large" />)}
              </Form.Item>
              <Form.Item key={`id ${k}`}>
                {getFieldDecorator(`addon_items[${k}]['id']`, {
                  initialValue: get(details, `addon_items.${k}.id`)
                    ? get(details, `addon_items.${k}.id`)
                    : null
                })(<Input type="hidden" />)}
              </Form.Item>
            </Col>

            <Col xs={10} xl={4} style={{ paddingRight: 10 }}>
              <Form.Item key={`price${k}`}>
                {getFieldDecorator(`addon_items.${k}.price`, {
                  initialValue: isEdit
                    ? get(details, `addon_items.${k}.price`)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: 'Please input add on price'
                    }
                  ]
                })(
                  <Input type="number" placeholder="Addon Price" size="large" />
                )}
              </Form.Item>
            </Col>

            <Col style={{ alignSelf: 'center' }} xs={2} xl={2}>
              <Form.Item key={`Active${k}`}>
                {getFieldDecorator(`addon_items.${k}.status`, {
                  initialValue: isEdit
                    ? get(details, `addon_items.${k}.status`)
                    : false
                })(
                  <Checkbox
                    defaultChecked={
                      isEdit ? get(details, `addon_items.${k}.status`) : false
                    }
                  />
                )}
              </Form.Item>
            </Col>

            <Col style={{ alignSelf: 'center' }} xs={2} xl={2}>
              {keys.length > 1 ? <FontAwesomeIcon icon={faTrash} /> : null}
            </Col>
          </Row>
        ))}

        <Row type="flex" justify="start" style={{ marginBottom: 15 }}>
          <Col xs={24} xl={24}>
            <Form.Item>
              <Button type="dashed" onClick={this.add}>
                <Icon type="plus" /> Add-On
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={24}>
            <Form.Item label="This add-on is available in...">
              {getFieldDecorator('menu_items', {
                initialValue: isEdit
                  ? map(get(details, 'menu_items'), (item) => get(item, 'id'))
                  : []
              })(
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Please select"
                  style={{ width: '100%' }}
                >
                  {map(itemData, (obj) => (
                    <Option value={obj.id} key={obj.id}>
                      {obj.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} xl={12}>
            <Button type="primary" htmlType="submit" className="btn-secondary">
              Save Changes
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'addon_Form' })(AddAddForm);
