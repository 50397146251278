import React from 'react';
import { Form, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

class MarketingForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleUpdate = (e) => {
    e.preventDefault();
  };

  render() {

    const { isEdit } = this.props;

    return (
      <Form
        layout="vertical"
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
      >
        <Form.Item label="Store Front Message">
          <TextArea />
        </Form.Item>
        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'marketing_Form' })(MarketingForm);
