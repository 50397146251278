import React from 'react';
import { Form, Input, Icon, Button, Row, Col } from 'antd';
import { map } from 'lodash';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

let id = 0;

export default class ChargeOnOrderAmount extends React.Component {
  constructor() {
    super();
    this.state = {
      k: null
    };
  }

  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('amount_keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      amount_keys: keys.filter((key) => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('amount_keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      amount_keys: nextKeys
    });
  };

  componentDidMount() {
    const { charges } = this.props;

    if (charges) {
      id = isArray(charges) ? charges.length : 0;
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { charges } = nextProps;
  //   if (charges) {
  //     console.log('====>componentWillReceiveProps,', charges, id);
  //     id = isArray(charges) ? charges.length : 0;
  //     this.setState({ k: null });
  //   }
  //   console.log('====>componentWillReceiveProps,', charges, id);
  // }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { charges } = this.props;
    getFieldDecorator('amount_keys', {
      initialValue: charges ? map(charges, (val, i) => i) : []
    });

    const keys = getFieldValue('amount_keys');
    const { length } = keys;
    const formItems = keys.map((k) => (
      <Row>
        <Col xl={10} xs={10} className="dynamic-form-col-padding">
          <Form.Item required={false} key={k}>
            {getFieldDecorator(`charges[${k}].order_amount`, {
              initialValue:
                charges && charges[k] ? get(charges[k], 'order_amount') : null,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input order amount'
                }
              ]
            })(<Input addonAfter="Kg" placeholder="20" type="number" />)}
          </Form.Item>
        </Col>

        <Col xl={10} xs={10} className="dynamic-form-col-padding">
          <Form.Item required={false} key={k}>
            {getFieldDecorator(`charges[${k}].price`, {
              initialValue: charges ? get(charges[k], 'price') : null,
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: 'Please input Price'
                }
              ]
            })(
              <Input
                addonBefore={this.props.sign}
                placeholder="40"
                type="number"
              />
            )}
          </Form.Item>
        </Col>
        <Col xl={2} xs={2} className="dynamic-form-col-padding">
          <Form.Item colon={false} required={false} key={k}>
            {keys.length > 1 ? (
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => this.remove(k)}
              />
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    ));

    return (
      <div>
        {length > 0 ? (
          <Row>
            <Col xl={10} xs={10} className="dynamic-form-col-padding">
              Amount Upto:
            </Col>
            <Col xl={10} xs={10} className="dynamic-form-col-padding">
              Charge:
            </Col>
          </Row>
        ) : null}
        {formItems}
        <Form.Item>
          <Button type="link" onClick={this.add}>
            <Icon type="plus" /> Add Tier
          </Button>
        </Form.Item>
      </div>
    );
  }
}
