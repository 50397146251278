import React from 'react';
import get from 'lodash/get';
import { Spin, Form, DatePicker, Button } from 'antd';
import { connect } from 'react-redux';
import { exportOrders } from '../../redux/actions/orderAction';
const { RangePicker } = DatePicker;

class ExportOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.exportOrders({
          location: get(this.props, 'location.id'),
          from_date: get(values, 'dates.0').format('YYYY-MM-DD 00:00:00'),
          to_date: get(values, 'dates.1').format('YYYY-MM-DD 23:59:59')
        });
      }
    });
  };

  exportOrders = (payload) => {
    this.loader(true);
    this.props
      .exportOrders(payload)
      .then(() => {
        this.props.toggleExportModal();
        this.loader(false);
      })
      .catch(() => {
        this.loader(false);
      });
  };

  loader = (loading) => this.setState({ loading });

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          labelCol={{
            xs: { span: 8 },
            xl: { span: 8 },
            sm: { span: 8 }
          }}
          wrapperCol={{
            xs: { span: 16 },
            xl: { span: 16 },
            sm: { span: 16 }
          }}
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="Date">
            {getFieldDecorator('dates', {
              rules: [
                {
                  required: true,
                  message: 'Please input Category name field!'
                }
              ]
            })(<RangePicker format={'DD/MM/YYYY'} onChange={() => {}} />)}
          </Form.Item>

          <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit" className="btn-secondary">
              Export
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

const wrappedForm = Form.create({ name: 'export_order' })(ExportOrder);
export default connect(mapStateToProps, { exportOrders })(wrappedForm);
