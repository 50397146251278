import React from 'react';
import Chart from 'react-apexcharts';
import get from 'lodash/get';
import Helpers from '../../utilities/helpers';

export default class ColumChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { stats } = this.props;
    const { days, revenue } = Helpers.getFormatedGraphData(
      get(stats, 'order_stats')
    );

    return (
      <Chart
        options={{
          chart: {
            id: 'apexchart-example',
            toolbar: {
              show: false
            }
          },
          xaxis: {
            categories: days
          }
        }}
        series={[
          {
            name: 'Revenue',
            data: revenue
          }
        ]}
        type="bar"
        width="100%"
        height={300}
      />
    );
  }
}
