import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import * as TYPES from '../../constants/actionTypes';
import * as APIS from '../../constants/apiEndpoints';
import Helpers from '../../utilities/helpers';

export const getLocation = (location) => (dispatch, getState, { api }) => {
  const url = APIS.LOCATION.replace('{id}', location);

  // ids taken from locations table
  const restrictions = [
    {
      id: 10,
      expiryDate: '2021-07-09T07:08:55.971Z'
    }, //grubly cafe
    { id: 3, expiryDate: '2022-04-18T23:59:59.001Z' }, // Bellezoe
    { id: 4, expiryDate: '2021-09-30T23:59:59.001Z' }, // Rangeen
    { id: 6, expiryDate: '2021-08-14T23:59:59.001Z' }, // Muse
    { id: 8, expiryDate: '2021-08-14T23:59:59.001Z' }, // Poetry
    { id: 7, expiryDate: '2021-07-14T23:59:59.001Z' }, // 650
    { id: 9, expiryDate: '2022-01-15T23:59:59.001Z' } // Roastery Cultur
  ];

  return api.get(url).then((result) => {
    const expiry = restrictions?.find((item) => item.id === result.id);

    dispatch({
      type: TYPES.REFRESH_LOCATION,
      data: {
        location: {
          ...result,
          expiry_date: expiry?.expiryDate
        }
      }
    });
    return result;
  });
};

export const validateGatewayDetails = (locationId) => (
  dispatch,
  getState,
  { api }
) => {
  const url = APIS.VALIDATE_GATEWAY_DETAILS.replace('{location_id}', locationId);

  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateServiceableLocation = (location, payload) => (
  dispatch,
  getState,
  { api }
) => {
  const url = Helpers.getUrl(APIS.SERVICEABLE_ZIP_CODE, {
    location
  });

  return api
    .put(url, payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLocationStats = (location, from, to) => (
  dispatch,
  getState,
  { api }
) => {
  let url = APIS.LOCATION_STATS.replace('{id}', location);
  url = Helpers.getUrl(url, {
    from,
    to
  });
  return api
    .get(url)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateLocation = (data, location) => (dispatch, getState, { api }) => {
  const url = APIS.UPDATE_LOCATION.replace('{location_id}', get(location, 'id'));

  const allLocations = get(getState(), 'location.all_location');
  const index = findIndex(allLocations, ['id', get(location, 'id')]);

  return api.put(url, data).then((result) => {
    allLocations[index] = result;
    dispatch({
      type: TYPES.UPDATE_CURRENT_LOCATION,
      data: {
        current: result,
        allLocations
      }
    });
  });
};

export const getCurrency = (user) => (dispatch, getState, { api }) => {
  return api.get(APIS.CURRENCY).then((result) => {
    return result;
  });
};

export const getCountry = () => (dispatch, getState, { api }) => {
  return api.get(APIS.COUNTRY).then((result) => {
    return result;
  });
};

export const getStates = (country) => (dispatch, getState, { api }) => {
  const url = APIS.STATE.replace('{country_id}', country);

  return api.get(url).then((result) => {
    return result;
  });
};

export const getTimeZone = () => (dispatch, getState, { api }) => {
  return api.get(APIS.TIME_ZONE).then((result) => {
    return result;
  });
};

export const changeLocation = (locationId, allLocations) => async (
  dispatch,
  getState,
  { api }
) => {
  const newLocation = find(allLocations, ['id', locationId]);

  dispatch({
    type: TYPES.UPDATE_CURRENT_LOCATION,
    data: {
      current: newLocation,
      allLocations
    }
  });
  return true;
};
