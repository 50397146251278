import React from 'react';
import { Form, Input, Select, Button, Col, Row } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import Helpers from '../../utilities/helpers';
import { ZIP_CODE } from '../../constants/regex';

const { TextArea } = Input;
const { Option } = Select;

class AddBusinessAddressForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { businessAddressDetail } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values, businessAddressDetail.id);
      }
    });
  };

  validateZipCode = (rule, value, cb) => {
    if (Helpers.compareRegex(value, ZIP_CODE)) {
      cb();
    } else {
      cb('Pincode that you enter is invalid!');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      businessAddressDetail,
      timeZone,
      isEdit,
      countries,
      states,
      getState
    } = this.props;

    return (
      <Form
        style={{ width: '800px' }}
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        layout="vertical"
        labelCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          xl: { span: 24 },
          sm: { span: 24 }
        }}
      >
        <Row>
          <Col md={24}>
            <Form.Item label="Address Line 1">
              {getFieldDecorator('address_line_1', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'address_line_1')
                  : '',
                rules: [
                  {
                    required: true,
                    message: 'Please input your address line 1!'
                  }
                ]
              })(<TextArea placeholder="Address 1" size="large" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Form.Item label="Address Line 2">
              {getFieldDecorator('address_line_2', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'address_line_2')
                  : ''
              })(<TextArea placeholder="Address 2" size="large" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Item label="Nearest Landmark">
              {getFieldDecorator('landmark', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'landmark')
                  : null,
                rules: [
                  { required: true, message: 'Please input your landmark' }
                ]
              })(<Input placeholder="Landmark" size="large" />)}
            </Form.Item>
          </Col>
          <Col md={6} style={{ paddingLeft: '10px' }}>
            <Form.Item label="City">
              {getFieldDecorator('city', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'city')
                  : null,
                rules: [{ required: true, message: 'Please input you city!' }]
              })(<Input placeholder="City" size="large" />)}
            </Form.Item>
          </Col>
          <Col md={6} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Pincode">
              {getFieldDecorator('zip', {
                initialValue: isEdit ? get(businessAddressDetail, 'zip') : null,
                rules: [
                  { required: true, message: 'Please select a Pincode. ' },
                  { validator: this.validateZipCode }
                ]
              })(<Input placeholder="Pincode" size="large" />)}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item label="Country">
              {getFieldDecorator('country', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'state.country')
                  : null,
                rules: [{ required: true, message: 'Please select a country' }]
              })(
                <Select
                  placeholder="Select one"
                  showSearch
                  size="large"
                  onChange={(value) => {
                    getState(value);
                  }}
                >
                  {map(countries, (countryObj, i) => {
                    return (
                      <Option key={i} value={get(countryObj, 'id')}>{`${get(
                        countryObj,
                        'name'
                      )}`}</Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={8} style={{ paddingLeft: '10px' }}>
            <Form.Item label="State">
              {getFieldDecorator('state', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'state.id')
                  : null,
                rules: [{ required: true, message: 'Please select a state' }]
              })(
                <Select
                  placeholder="Select one"
                  showSearch
                  size="large"
                  onChange={() => {}}
                >
                  {map(states, (stateObj, i) => {
                    return (
                      <Option key={i} value={get(stateObj, 'id')}>{`${get(
                        stateObj,
                        'name'
                      )}`}</Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={8} style={{ paddingLeft: '10px' }}>
            <Form.Item label="Time Zone">
              {getFieldDecorator('time_zone', {
                initialValue: isEdit
                  ? get(businessAddressDetail, 'time_zone.id')
                  : null,
                rules: [
                  { required: true, message: 'Please select a Time Zone' }
                ]
              })(
                <Select placeholder="Select one" showSearch size="large">
                  {map(timeZone, (timeObj, i) => {
                    return (
                      <Option key={i} value={get(timeObj, 'id')}>{`${get(
                        timeObj,
                        'name'
                      )}`}</Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'business_address_Form' })(
  AddBusinessAddressForm
);
