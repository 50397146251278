import React from 'react';
import { Timeline } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';

export default class OrderLogs extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { orderDetails } = this.props;

    return (
      <Timeline>
        <Timeline.Item color="green" key="order_created">
          <p>
            <b>Order is Placed</b>
          </p>
          <p>
            {moment(get(orderDetails, 'created_at')).format('MMMM Do YYYY, h:mm a ')}
          </p>
          <p>By : {get(orderDetails, 'customer.name')}</p>
        </Timeline.Item>
        {map(get(orderDetails, 'order_logs'), (orderLog, index) => {
          return (
            <Timeline.Item color="green" key={`log_${index}`}>
              <p>
                <b>{get(orderLog, 'log')}</b>
              </p>
              <p>
                {moment(get(orderLog, 'created_at')).format('MMMM Do YYYY, h:mm a ')}
              </p>

              <p>
                By :{' '}
                {get(orderLog, 'user.username')
                  ? get(orderLog, 'user.username')
                  : get(orderDetails, 'customer.name')}
              </p>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  }
}
