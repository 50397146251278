import React, { Component } from 'react';
import { Spin, PageHeader, message } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import AddServiceTypeForm from '../../components/Business/AddServiceTypeForm';
import { UPDATE_SERVICE_TYPE_SUCCESS } from '../../constants/messages';
import requireAuth from '../../hocs/requireAuth';
import { updateLocation } from '../../redux/actions/locationAction';

class ServiceTypeScreen extends Component {
  constructor() {
    super();
    this.state = {
      isDrawerVisible: false,
      isEdit: true,
      loading: false,
      serviceDetails: null
    };
  }

  componentWillMount() {
    this.setState({ serviceDetails: get(this.props, 'location') });
  }

  loader = (loading) => this.setState({ loading });

  updatedata = (serviceObj, index = null) => {
    const { serviceDetails } = this.state;
    this.loader(true);
    this.props
      .updateLocation(serviceObj, serviceDetails)
      .then(() => {
        this.loader(false);
        message.success(UPDATE_SERVICE_TYPE_SUCCESS);
      })
      .catch((err) => {
        this.loader(false);
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ serviceDetails: get(nextProps, 'location') });
  }

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({
      isDrawerVisible: !isDrawerVisible,
      isEdit: false,
      serviceDetails: null
    });
  };

  render() {
    const { loading, isEdit, serviceDetails } = this.state;

    return (
      <>
        <Spin spinning={loading}>
          <div className="main-container">
            <PageHeader
              className="site-page-header-responsive title title-md mb--20"
              title={<h4 className="text-uppercase">Service Types</h4>}
            >
              <AddServiceTypeForm
                serviceDetails={serviceDetails}
                isEdit={isEdit}
                updatedata={this.updatedata}
              />
            </PageHeader>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default requireAuth(
  connect(mapStateToProps, { updateLocation })(ServiceTypeScreen)
);
