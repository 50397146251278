import React, { Component } from 'react';
import { Alert, Col, PageHeader, Row } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';

function IsExpired({ expiryDate }) {
  return (
    <Alert
      message={
        <h2>
          <span>
            Your account has expired
            {expiryDate ? ` on ${moment(expiryDate).format('LL')}. ` : '. '}
          </span>
          <span>
            Please contact us at{' '}
            <a href="mailto:support@getgrubly.com">support@getgrubly.com</a> for more
            details.
          </span>
        </h2>
      }
      type="error"
    />
  );
}

function IsGoingToExpire({ expiryDate }) {
  return (
    <Alert
      message={
        <h2>
          <span>
            Your account will expire
            {expiryDate ? ` on ${moment(expiryDate).format('LL')}. ` : 'soon. '}
          </span>
          <span>Please renew your subscription for uninterrupted service.</span>
        </h2>
      }
      type="error"
    />
  );
}

function ValidTill({ expiryDate }) {
  return (
    <Alert
      message={
        <div>
          <span>
            Your subscription is valid till
            {expiryDate && ` ${moment(expiryDate).format('LL')}.`}
          </span>
        </div>
      }
      type="info"
      closable
    />
  );
}

class ExpiredScreen extends Component {
  render() {
    const { location } = this.props;

    const expiryDate = location?.expiry_date;

    const isExpired = expiryDate && moment().isAfter(expiryDate);
    const diffInDays = expiryDate && moment(expiryDate).diff(moment(), 'days');
    console.log({
      now: moment().toISOString(),
      then: moment(expiryDate),
      is: moment().isAfter(expiryDate),
      was: moment().isAfter(moment(expiryDate))
    });
    if (!isExpired && !diffInDays) {
      return null;
    }

    return (
      <PageHeader>
        <Row>
          <Col>
            {isExpired && <IsExpired expiryDate={expiryDate} />}
            {diffInDays > 0 && diffInDays <= 30 && (
              <IsGoingToExpire expiryDate={expiryDate} />
            )}
            {diffInDays > 30 && <ValidTill expiryDate={expiryDate} />}
          </Col>
        </Row>
      </PageHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  location: get(state, 'location.current_location')
});

export default connect(mapStateToProps, {})(ExpiredScreen);
