import React from 'react';
import firebase from 'firebase';
import { notification } from 'antd';

import get from 'lodash/get';

const config = {
  apiKey: 'AIzaSyDJpzccb7hjlirOcA5m-mrhPrZcUIhm4NY',
  authDomain: 'grubly-raftlabs.firebaseapp.com',
  databaseURL: 'https://grubly-raftlabs.firebaseio.com',
  projectId: 'grubly-raftlabs',
  storageBucket: 'grubly-raftlabs.appspot.com',
  messagingSenderId: '27323243180',
  appId: '1:27323243180:web:029e8f7416128f22d6dd09',
  measurementId: 'G-3L3MRKXST9'
};

firebase.initializeApp(config);

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const createFCMToken = () => {
  // alert(firebase.messaging.isSupported());
  if (firebase.messaging.isSupported()) {
    return new Promise((resolve) => {
      if (messaging) {
        return messaging
          .requestPermission()
          .then(async () => {
            return messaging.getToken();
          })
          .then((token) => {
            resolve(token);
          })
          .catch((err) => {});
      }
      return true;
    });
  }
  return null;
};

if (messaging) {
  messaging.onMessage((payload) => {
    notification.info({
      message: payload.notification.title,
      description: payload.notification.body,

      icon: get(payload.notification, 'icon') ? (
        <img
          alt=""
          src={get(payload.notification, 'icon')}
          style={{ width: 50, height: 50 }}
        />
      ) : null
    });

    const audioEl = document.getElementsByClassName('audio-element')[0];

    audioEl.play();
  });
}
export const messagingObject = messaging;
