import React from 'react';
import { Form, Input, Select, Button, Checkbox } from 'antd';
import get from 'lodash/get';

const { Option } = Select;

class AddChargesAndTaxesForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values);
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const { details } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updatedata(values, details.id);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { details, isEdit } = this.props;

    return (
      <Form
        onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}
        layout="vertical"
        labelCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          xl: { span: 24 }
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 24 },
          xl: { span: 24 }
        }}
      >
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: isEdit ? get(details, 'name') : '',
            rules: [{ required: true, message: 'Please input the name!' }]
          })(<Input size="large" placeholder="name" />)}
        </Form.Item>
        <Form.Item label="Value">
          {getFieldDecorator('amount', {
            initialValue: isEdit ? get(details, 'amount') : '',
            rules: [{ required: true, message: 'Please input the Value!' }]
          })(<Input type="number" placeholder="value" size="large" />)}
        </Form.Item>
        <Form.Item label="Unit">
          {getFieldDecorator('unit', {
            initialValue: isEdit ? get(details, 'unit') : 'Percent'
          })(
            <Select showSearch size="large" onChange={() => {}}>
              <Option value="Percent">Percent</Option>
              <Option value="Flat">Flat</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('applicable', {
            initialValue: isEdit ? get(details, 'applicable') : false
          })(
            <Checkbox defaultChecked={isEdit ? get(details, 'applicable') : false}>
              Applicable on Order ?
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item hidden label="Type">
          {getFieldDecorator('type', {
            initialValue: isEdit ? get(details, 'type') : 'Tax'
          })(
            <Select
              defaultActiveFirstOption
              showSearch
              size="large"
              onChange={() => {}}
            >
              <Option value="Tax">Tax</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit" className="btn-secondary">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'charges_taxes_Form' })(AddChargesAndTaxesForm);
