export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];
export const ROLE = 'BusinessAdmin';

export const colours = [
  'blue',
  'green',
  'cyan',
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'geekblue',
  'purple'
];

export const mobilePrefix = ['91', '1'];

export const visibleStatus = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
];

export const updateOrderStatusKeys = [
  { label: 'Accept', value: 'Preparing' },
  { label: 'Ready', value: 'Ready' },
  { label: 'Delivered', value: 'Delivered' }
  // { label: 'Canceled', value: 'Canceled' }
];
